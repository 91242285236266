<template>
  <div class="maincen">
    <div class="catalog">
      <div class="line"></div>
      <div class="catalogTit left">数字化教材</div>
      <div class="creatediv right" @click="createClass">
        <img class="createicon" src="../../assets/img/create.png" alt="" />
        新增教材
      </div>
    </div>
    <div class="coursediv content">
      <p class="noText" v-if="courseData.length == 0">暂无教材</p>
      <ul class="clearfix">
        <li
          class="left courseLi"
          v-for="(item, index) in courseData"
          :key="index"
        >
          <p class="ziyuantitle oneline">{{ item.name }}</p>
          <img class="courseCover" :src="item.publicityImagesUrl" alt="" />
          <div class="clearfix operationdiv">
            <div class="left commonButton editbutton" @click="editclick(item)">
              <img
                class="commonicon"
                src="../../assets/img/edit.png"
                alt=""
              />编辑
            </div>
            <div class="left commonButton" @click="open(item)">
              <img
                class="commonicon"
                src="../../assets/img/delete.png"
                alt=""
              />删除
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="block">
      <el-pagination
        :current-page="1"
        @current-change="handleCurrentChangeClass"
        :page-size="pageBean.pageSize"
        background
        layout="prev, pager, next,total"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible.sync="dialogVisibleCommont"
      class="classDialog commonDialog"
    >
      <el-form
        ref="addSchoolListref"
        :rules="addbannerlListRules"
        :model="addbannerlList"
        label-width="0"
      >
        <p class="addtext">
          {{ edit ? '编辑数字化信息教材' : '添加数字化信息教材' }}
        </p>
        <div class="majordiv">
          <span class="majorName">教材名称:</span>
          <input
            class="majorInput"
            type="text"
            v-model="addbannerlList.name"
            placeholder="请输入教材名称"
          />
        </div>
        <el-form-item>
          <el-upload
            class="upload-demo"
            :action="getUrl"
            name="file"
            :limit="1"
            accept=".jpg, .png, .JPG, .jpeg, .JPEG, .PNG, .gif"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :file-list="fileListedit"
            :headers="headerUrl"
            :on-success="handleAvatarSuccess"
            :data="imgUploadData"
            list-type="picture"
          >
            <el-button size="small" type="primary">上传教材宣传图片</el-button>
            <div class="uploadtext">
              <p class="tiptext">点击选择一个文件到这里上传</p>
              <p class="tiptext">支持png、jpg、JPEG...等格式，体积在2M以下</p>
            </div>
          </el-upload>
        </el-form-item>

        <el-form-item>
          <el-upload
            class="upload-demo"
            :action="getUrl"
            name="file"
            :limit="1"
            accept=".pdf"
            :on-preview="handlePreviewFile"
            :on-remove="handleRemoveFile"
            :file-list="fileListeditFile"
            :headers="headerUrl"
            :on-success="handleAvatarSuccessfile"
            :data="imgUploadData"
          >
            <el-button size="small" type="primary">上传教材文件</el-button>
            <div class="uploadtext">
              <p class="tiptext">点击选择一个文件到这里上传</p>
              <!-- <p class="tiptext">支持png、jpg、JPEG...等格式，体积在2M以下</p> -->
            </div>
          </el-upload>
        </el-form-item>
        <div class="majordiv">
          <span class="majorName">所属专业:</span>
          <el-select
            v-model="addbannerlList.professionalId"
            placeholder="请选择所属专业"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in majorData"
              :key="index"
            ></el-option>
          </el-select>
        </div>
        <div class="majordiv">
          <span class="majorName">书号:</span>
          <input
            class="majorInput"
            type="text"
            v-model="addbannerlList.publishedBookNumber"
            placeholder="请输入书号"
          />
        </div>
        <div class="majordiv">
          <span class="majorName">作者:</span>
          <input
            class="majorInput"
            type="text"
            v-model="addbannerlList.author"
            placeholder="请输入作者"
          />
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button v-dbClick class="saveButton" @click="saveOrUpdate"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../assets/api/axios'
import { baseUrl } from '@/config'
import {
  numTeachList,
  numSaveList,
  numupdateList,
  numdelete,
  numInfo,
} from '@/api/center/numteach.js'
import { queryAll } from '@/api/common/index.js'
import { findName } from '@/utils/index.js'
export default {
  data() {
    return {
      edit: false,
      addbannerlList: {
        professionalId: '',
        name: '',
        author: '',
        publishedBookNumber: '',
        textbookImagesUrl: '',
        textbookImagesFileName: '',
        textbookImagesFileSize: '',
        publicityImagesUrl: '',
        publicityImagesFileName: '',
        publicityImagesFileSize: '',
      },
      fileList: [],
      getUrl: `${baseUrl}goldcourse/common/aliyun/oss/uploadFiles`,
      fileListedit: [],
      fileListeditFile: [],
      headerUrl: { Authorization: window.sessionStorage.getItem('token') },
      imgUploadData: { serviceName: 'web' },
      addbannerlListRules: {
        typeFlag: [
          { required: true, message: '请输入内容', trigger: 'change' },
        ],
      },
      dialogVisibleCommont: false,
      pageBean: {
        pageNum: 1,
        pageSize: 8,
      },
      total: 0,
      publishList: [],
      majorData: [],
      publishdialogVisible: false,
      ruleForm: {},
      courseData: [],
      editId: '',
      rules: {
        pubBookname: [
          { required: true, message: '请输入图书名称', trigger: 'blur' },
          {
            min: 1,
            max: 50,
            message: '长度在 3 到 50 个字符',
            trigger: 'blur',
          },
        ],
        pubBooknum: [
          { required: true, message: '请输入图书书号', trigger: 'blur' },
          {
            min: 1,
            max: 50,
            message: '长度在 3 到 50 个字符',
            trigger: 'blur',
          },
        ],
        pubSet: [
          { required: true, message: '请输入册数', trigger: 'blur' },
          {
            min: 1,
            max: 50,
            message: '长度在 3 到 50 个字符',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  created() {
    this.loadPublishList()
    this.getmajorList()
  },
  methods: {
    saveOrUpdate() {
      if (this.addbannerlList.publicityImagesUrl == '') {
        this.$message({
          type: 'error',
          message: '文件还未上传成功!',
        })
        return false
      }
      this.addbannerlList.professionalName = findName(
        this.majorData,
        this.addbannerlList.professionalId
      )
      if (this.edit) {
        numupdateList(this.addbannerlList).then((res) => {
          if (res.status == 0) {
            this.$message.success('修改成功')
            this.dialogVisibleCommont = false
            this.loadPublishList()
          }
        })
      } else {
        numSaveList(this.addbannerlList).then((res) => {
          if (res.status == 0) {
            this.$message.success('添加成功')
            this.dialogVisibleCommont = false
            this.loadPublishList()
          }
        })
      }
    },
    getmajorList() {
      let param = {
        specialtyType: 0,
        objectType: 1,
      }
      queryAll(param).then((res) => {
        this.majorData = res.data
      })
    },
    handleRemove(file, fileList) {
      this.fileListedit = []
      this.addbannerlList.publicityImagesUrl = ''
      this.addbannerlList.publicityImagesFileName = ''
      this.addbannerlList.publicityImagesFileSize = ''
    },
    handleRemoveFile(file, fileList) {
      this.fileListeditFile = []
      this.addbannerlList.textbookImagesUrl = ''
      this.addbannerlList.textbookImagesFileName = ''
      this.addbannerlList.textbookImagesFileSize = ''
    },
    handlePreview(file) {},
    handlePreviewFile(file) {},
    handleAvatarSuccess(res, file, fileList) {
      this.fileListedit = fileList
      this.addbannerlList.publicityImagesUrl = res.data.url
      this.addbannerlList.publicityImagesFileName = res.data.fileName
      this.addbannerlList.publicityImagesFileSize = res.data.size
    },
    handleAvatarSuccessfile(res, file, fileList) {
      this.fileListeditFile = fileList
      this.addbannerlList.textbookImagesUrl = res.data.url
      this.addbannerlList.textbookImagesFileName = res.data.fileName
      this.addbannerlList.textbookImagesFileSize = res.data.size
    },
    handleSizeChange(newSize) {},
    handleCurrentChangeClass(val) {
      this.pageBean.pageNum = val
      this.loadPublishList()
    },
    createClass() {
      for (let key in this.addbannerlList) {
        this.addbannerlList[key] = ''
      }
      this.fileListedit = []
      this.fileListeditFile = []
      this.dialogVisibleCommont = true
    },
    loadPublishList() {
      numTeachList(this.pageBean).then((res) => {
        this.courseData = res.data
        this.total = res.page.total
      })
    },
    editclick(row) {
      this.dialogVisibleCommont = true
      this.edit = true
      this.editId = row.id
      this.fileListedit = []
      this.fileListeditFile = []
      this.getvideoInfo()
    },
    getvideoInfo() {
      numInfo(this.editId).then((res) => {
        this.addbannerlList = res.data
        this.fileListedit.push({ url: res.data.publicityImagesUrl })
        this.fileListeditFile.push({ url: res.data.textbookImagesUrl })
      })
    },
    handleClose(done) {
      this.publishdialogVisible = false
    },
    // 表单验证
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('sssss===', this.ruleForm)
          api
            .post('/front/personal/addPublish', this.ruleForm)
            .then((res) => {
              if (res.code === 1000) {
                this.$message.success('申请成功')
                this.publishdialogVisible = false
                this.loadPublishList()
                this.ruleForm = {}
              }
            })
            .catch((err) => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    //删除
    open(row) {
      this.$confirm('此操作将删除当前数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          numdelete([row.id]).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.loadPublishList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },
}
</script>
<style lang="scss">
.classDialog .el-dialog {
  width: 480px;
}
.classDialog .el-form-item {
  margin-bottom: 0px;
  margin-top: 9px;
}
.classDialog .el-input__inner {
  border: none;
}
.classDialog .el-select {
  width: 77.5%;
}
.classDialog .ziyuanselect {
  width: 70%;
}
.classDialog .el-dialog__body {
  padding: 30px 40px;
}
.banjitable {
  margin-top: 20px;
}
.banjitable .el-button--text {
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #0b3450;
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
}
.banjitable .el-table__header th,
.banjitable .el-table__header tr {
  background: #fbfbfb !important;
}
.banjitable .el-table__header thead {
  border: 1px solid #e6e6e6;
}
.banjitable .el-table__header {
  border-collapse: inherit !important;
}
.classdiv .el-table td,
.el-table th.is-leaf {
  border-bottom: 1px dashed #ebeef5;
}
.delectebutton span {
  color: #ff7500;
}
</style>
<style lang="scss" scoped>
.noText {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.addtext {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}
.banUpload {
  margin-top: 10px;
}
.uploadtext {
  display: inline-block;
  width: 257px;
  vertical-align: top;
  margin-left: 5px;
  margin-top: 2px;
}
.tiptext {
  font-size: 12px;
  line-height: 18px;
  text-align: left;
}
.majorText {
  resize: none;
  border: none;
  outline: none;
  height: 110px;
  margin-top: 10px;
  width: 337px;
}
.majorName {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-right: 5px;
}
.majorInput {
  border: none;
  outline: none;
  width: 290px;
}
.textareadiv {
  width: 360px;
  padding: 14px 20px;
  height: 140px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  margin-top: 13px;
}
.majordiv {
  width: 360px;
  padding: 0 20px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  line-height: 42px;
  margin-top: 9px;
}
.creatediv {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  cursor: pointer;
}
.ziyuantitle {
  height: 26px;
  width: 126px;
  background: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  left: 0;
  bottom: 16px;
  text-align: center;
  line-height: 26px;
}
.oneline{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.courseLi {
  width: 126px;
  height: 190px;
  margin-right: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}
.commonicon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 2px;
}
.editbutton {
  margin-right: 20px;
}
.courseLi:nth-child(4n) {
  margin-right: 0;
}
.courseCover {
  width: 126px;
  height: 174px;
  margin-bottom: 5px;
}
.createicon {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
}

.Tit {
  overflow: hidden;
  width: 100%;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  .status {
    width: 50px;
    height: 22px;
    background: rgba(255, 156, 0, 1);
    font-size: 12px;
    color: rgba(254, 254, 254, 1);
    line-height: 22px;
    text-align: center;
    margin-right: 20px;
    float: left;
    margin-top: 13px;
  }
  .restatus {
    background: rgb(5, 85, 14);
  }
  .name {
    float: left;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
  }
  .time {
    float: right;
    margin-right: 40px;
    font-size: 14px;
    color: rgba(153, 153, 153, 1);
  }
}
.details {
  padding-left: 70px;
  .detItem {
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    line-height: 36px;
  }
}
.subbut {
  width: 560px;
  height: 36px;
  background: rgba(181, 14, 5, 1);
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 36px;
  margin: 20px 0 20px;
}
.matbox {
  margin: 0 30px;
  .matTip {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    // margin-bottom: 20px;
    margin: 0 0 20px 30px;
  }
}
.content {
  height: 500px;
  ul {
    li {
      margin-right: 10px !important;
    }
    li:nth-child(6n) {
      margin-right: 0 !important;
    }
  }
}
</style>
