import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/home/home.vue'
import Index from '../components/home/index.vue'
// import Index from '../components/index.vue'
import Contact from '../components/contactus.vue'
// 立体教材
import Teachingmat from '../components/teachingmaterial/teachingmat.vue'
import Matdetails from '../components/teachingmaterial/matdetails.vue'
import Chaptertest from '../components/teachingmaterial/chaptertest.vue'
import viewTest from '../components/teachingmaterial/viewTest.vue'
// 资源库
import Resource from '../components/resourceBank/resource.vue';
import Major from '../components/resourceBank/resMajor.vue';
import Course from '../components/resourceBank/resCourse.vue';
import Material from '../components/resourceBank/resMaterial.vue';
import TeaMaterial from '../components/resourceBank/resTeaMaterial.vue';
import MajorDetail from '../components/resourceBank/majorDetail/majorDetail.vue';
import MicCourse from '../components/resourceBank/majorDetail/micCourse.vue';
import MajorMaterial from '../components/resourceBank/majorDetail/majorMaterial.vue';
// 个人中心
import Center from '../components/center/center.vue'
import Mycourse from '../components/center/mycourse.vue'
import collect from '../components/center/collect.vue'
import Self from '../components/center/self.vue'
import Study from '../components/center/study.vue'
import Collection from '../components/center/collection.vue'
import Publish from '../components/center/publish.vue'
import questionBank from '../components/center/questionBank.vue'
import numteaching from '../components/center/numteaching.vue'
import student from '../components/center/student.vue'
//课程
import CourseClass from '../components/course/course.vue'
import Base from '../components/course/base.vue'
import Chapter from '../components/course/chapter.vue'
import section from '../components/course/section.vue'
import examTest from '../components/course/examTest.vue'
import courseAssessment from '../components/course/courseAssessment.vue'
//班级
import course from '../components/grades/course.vue'
// 书目下载
import Catalog from '../components/catalog/catalog.vue'
// 教师认证
import Authentication from '../components/authentication/authentication.vue'
import onLine from '../components/onLine/onLine.vue'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/index',
    children: [
        // { path: '/index', component: Index },
        { path: '/contact', component: Contact },
        { path: '/teachingmat', component: Teachingmat },
        { path: '/teachingmat/Matdetails', component: Matdetails },
        { path: '/teachingmat/chaptertest', component: Chaptertest },
        { path: '/viewTest', component: viewTest },
        { path: '/catalog', component: Catalog },
        { path: '/authentication', component: Authentication },
        {
            path: '/resource/major/detail', component: MajorDetail, redirect: '/major/detail/micCourse', children: [
                { path: '/major/detail/micCourse', component: MicCourse },
                { path: '/major/detail/material', component: MajorMaterial },
            ]
        },
        {
            path: '/center', component: Center,
            redirect: '/center/self',
            children: [
                { path: '/center/mycourse', component: Mycourse },
                { path: '/center/collect', component: collect },
                { path: '/center/self', component: Self },
                { path: '/center/study', component: Study },
                { path: '/center/collection', component: Collection },
                { path: '/center/publish', component: Publish },
                { path: '/center/questionBank', component: questionBank },
                { path: '/center/numteaching', component: numteaching },
                { path: '/center/student', component: student },
            ]
        },
        {
            path: '/course', component: CourseClass,
            redirect: '/course/base',
            children: [
                { path: '/course/base', component: Base },
                { path: '/course/chapter', component: Chapter },
                { path: '/course/section', component: section },
                { path: '/course/examTest', component: examTest },
                { path: '/course/courseAssessment', component: courseAssessment },
            ]
        },
        {
            path: '/grades', component: course,
            redirect: '/grades/course',
            children: [
                { path: '/grades/course', component: course },
            ]
        },
        { path: '/resource/teaMaterial', component: TeaMaterial },
        {
            path: '/resource', component: Resource, children: [
                { path: '/resource/major', name: "resMajor", component: Major },
                { path: '/resource/course', component: Course },
                { path: '/resource/material', component: Material },
            ]
        },
        { path: '/Index', component: Index },
        { path: '/onLine', component: onLine },

    ]
},
{
    path: '/about',
    name: 'About',
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/About.vue')
}
]

const router = new VueRouter({
    routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router
