<template>
  <div>
    <div class="bg">
      <div class="main">
        <div class="maincontent">
          <el-row :gutter="10">
            <el-col
              :span="6"
              style="
                width: 240px;
                padding: 0px;
                height: 712px;
                background-color: #f8f8f8;
              "
            >
              <div class="grid-content bg-purple">
                <div class="touxiang">
                  <div class="imgContent">
                    <img v-if="resPicUrl" :src="resPicUrl" alt />
                    <img v-else src="../../assets/touxiang1.jpg" alt="" />
                  </div>
                  <div class="teaNickname">{{ resnickname }}</div>
                </div>
                <router-link
                  v-if="showItem"
                  class="navTrain"
                  to="/center/study"
                  tag="div"
                  ><i class="iconfont icon-zhuanye2"></i>专业库建设</router-link
                >
                <router-link
                  v-if="showItem"
                  class="navTrain"
                  to="/center/collection"
                  tag="div"
                  ><i class="iconfont icon-kecheng1" style="font-size: 20px"></i
                  >我教的课程</router-link
                >
                <router-link
                  v-if="showItem"
                  class="navTrain"
                  to="/center/publish"
                  tag="div"
                  ><i class="iconfont icon-sucai1" style="font-size: 18px"></i
                  >素材资源</router-link
                >
                <router-link
                  v-if="showItem"
                  class="navTrain"
                  to="/center/numteaching"
                  tag="div"
                  ><i class="iconfont icon-jiaocai1" style="font-size: 22px"></i
                  >数字化教材</router-link
                >
                <router-link
                  v-if="showItem"
                  class="navTrain"
                  to="/center/questionBank"
                  tag="div"
                  ><i
                    class="iconfont icon-tikuguanli"
                    style="font-size: 20px"
                  ></i
                  >题库管理</router-link
                >
                <router-link class="navTrain" to="/center/mycourse" tag="div"
                  ><i
                    class="iconfont icon-wodexuexi"
                    style="font-size: 20px"
                  ></i
                  >我的学习</router-link
                >
                <router-link class="navTrain" to="/center/collect" tag="div"
                  ><i
                    class="iconfont icon-wodexuexi"
                    style="font-size: 20px"
                  ></i
                  >我的收藏</router-link
                >
                <router-link class="navTrain" to="/center/self" tag="div"
                  ><i
                    class="iconfont icon-zhanghushezhigerenziliao666666"
                    style="font-size: 18px"
                  ></i
                  >个人资料</router-link
                >
              </div>
            </el-col>
            <el-col :span="18">
              <div
                class="grid-content bg-purple"
                style="background-color: #fff"
              >
                <div class="listNav">
                  <div class="main">
                    <i class="iconfont icon-shouye"></i>
                    <span>您的位置：</span>
                    <span>首页</span>
                    <span> > </span>
                    <span>个人管理中心</span>
                  </div>
                </div>
                <router-view></router-view>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
export default {
  data() {
    return {
      nickname: window.sessionStorage.getItem('nickname'),
      showItem: true,
    }
  },
  computed: {
    ...mapGetters(['resPicUrl', 'resnickname']),
  },
  created() {
    const idenType = localStorage.getItem('userIdentityType')
    if (idenType == 2) {
      this.showItem = false
    }
  },
}
</script>
<style lang="scss" scoped>
.listNav {
  span {
    font-size: 14px;
    color: rgba(102, 102, 102, 1);
    line-height: 42px;
    margin-right: 5px;
  }
  :nth-child(6) {
    color: #b50e05;
  }
}
.bg {
  background: #fff;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 5px;
}
.maincontent {
}
.touxiang {
  overflow: hidden;
  height: 178px;
  text-align: center;
  background: #f8f8f8;
  .imgContent {
    margin: 0 auto;
    height: 76px;
    width: 76px;
    border: 1px solid #e6e6e6;
    margin-top: 40px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .teaNickname {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #666666;
    line-height: 14px;
  }
}
.separate {
  height: 10px;
  background-color: rgba(248, 248, 248, 1);
}
.navTrain {
  // width: 100%;
  font-size: 14px;
  height: 45px;
  background: #f8f8f8;
  line-height: 46px;
  color: rgba(102, 102, 102, 1);
  padding-left: 30px;
  border-bottom: 1px solid rgba(230, 230, 230, 0.3);
  i {
    margin-right: 12px;
    line-height: 46px;
  }
  &:hover {
    font-size: 14px;
    background: #0b3450;
    color: rgba(255, 255, 255, 1);
  }
}
.router-link-active {
  font-size: 14px;
  background: #0b3450;
  color: rgba(255, 255, 255, 1);
}
.maincontent .el-row {
  margin: 0 !important;
}
.maincontent .main {
  margin-left: 25px;
  width: 810px;
}
</style>
