<template>
  <div class="maincen">
    <div class="catalog">
      <div class="line"></div>
      <div class="catalogTit left">学员管理</div>
      <div class="adddiv right" @click="gotoBack">返回上一页>></div>
    </div>
    <div class="clearfix codediv">
      <div class="lefterweima left">
        <img class="ercode" :src="wxcodeimg" alt="" />
        <p class="downbutton" @click="downloadUrlFile(wxcodeimg)">下载</p>
      </div>
      <div class="righterweima left">
        <p class="codeText">分享二维码给我的学生，创建班级进行在线教学</p>
        <!-- <div>
          <input v-model="inputVal" class="codeInput left" type="text" />
          <div @click="fuzhi1" class="copyButton left">复制链接</div>
        </div> -->
      </div>
    </div>
    <p class="banList">班级学员列表</p>
    <div class="center content">
      <el-table class="banjitable" :data="tableData" style="width: 100%">
        <el-table-column prop="userName" label="用户名"> </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="gradeName" label="所属班级"> </el-table-column>
        <el-table-column prop="createTime" label="进班时间"> </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              @click="deleteClick(scope.row)"
              class="delectebutton"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        :current-page="1"
        @current-change="handleCurrentChangeClass"
        :page-size="param.pageSize"
        background
        layout="prev, pager, next,total"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { StudentList, deleteStudent } from '@/api/center/index.js'
import { banInfo } from '@/api/center/teach.js'
export default {
  data() {
    return {
      total: 0,
      param: {
        pageNum: 1,
        pageSize: 8,
        gradeId: '',
      },
      tableData: [],
      id: '',
      wxcodeimg: '',
    }
  },
  watch: {},
  computed: {},
  methods: {
    getStudentList() {
      StudentList(this.param).then((res) => {
        this.tableData = res.data
        this.total = res.page.total
      })
    },
    getbanInfo() {
      banInfo(this.id).then((res) => {
        this.wxcodeimg = res.data.qrcodeUrl
      })
    },
    fuzhi1() {
      var input = document.createElement('input') // 直接构建input
      input.value = this.inputVal // 设置内容 需要复制的内容
      document.body.appendChild(input) // 添加临时实例
      input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      document.body.removeChild(input) // 删除临时实例
      this.$message({
        // 这里提示语
        message: '链接已复制成功',
        type: 'warning',
      })
    },
    gotoBack() {
      this.$router.go(-1)
    },
    //删除
    deleteClick(row) {
      this.$confirm('此操作将删除当前数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteStudent([row.id]).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getStudentList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleSizeChange(newSize) {},
    handleCurrentChangeClass(val) {
      this.param.pageNum = val
      this.getStudentList()
    },
    /**
     * 获取页面文件名
     * @param url 文件url
     */
    downloadUrlFile(url) {
      url = url.replace(/\\/g, '/')
      const xhr = new XMLHttpRequest()
      xhr.open('GET', url, true)
      xhr.responseType = 'blob'
      //xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
      xhr.onload = () => {
        if (xhr.status === 200) {
          // 获取文件blob数据并保存
          var fileName = this.getFileName(url)
          this.saveAs(xhr.response, fileName)
        }
      }

      xhr.send()
    },

    /**
     * URL方式保存文件到本地
     * @param data 文件的blob数据
     * @param name 文件名
     */
    saveAs(data, name) {
      var urlObject = window.URL || window.webkitURL || window
      var export_blob = new Blob([data])
      var save_link = document.createElementNS(
        'http://www.w3.org/1999/xhtml',
        'a'
      )
      save_link.href = urlObject.createObjectURL(export_blob)
      save_link.download = name
      save_link.click()
    },

    /**
     * 根据文件url获取文件名
     * @param url 文件url
     */
    getFileName(url) {
      var num = url.lastIndexOf('/') + 1
      var fileName = url.substring(num)
      //把参数和文件名分割开
      fileName = decodeURI(fileName.split('?')[0])
      return fileName
    },
  },
  created() {
    this.id = this.$route.query.id
    this.param.gradeId = this.id
    this.getStudentList()
    this.getbanInfo()
  },
}
</script>
<style lang="scss">
.downbutton {
  cursor: pointer;
  margin-top: 10px;
}
.banList {
  margin-top: 29px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #0b3450;
}
.copyButton {
  width: 94px;
  height: 32px;
  background: #13a5ec;
  border-radius: 2px;
  text-align: center;
  line-height: 32px;
  color: #fff;
  cursor: pointer;
}
.codeInput {
  width: 350px;
  height: 28px;
  border: 1px solid #e6e6e6;
  margin-right: 5px;
  padding-left: 10px;
}
.righterweima {
}
.codeText {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #13a5ec;
  margin-top: 10px;
  margin-bottom: 10px;
}
.lefterweima {
  height: 120px;
  width: 120px;
  border: 1px solid #e6e6e6;
  margin-right: 20px;
}
.codediv {
  margin-top: 10px;
}
.ercode {
  width: 110px;
  height: 110px;
  padding: 5px;
}
.majorDialog .el-dialog {
  width: 922px;
  height: 700px;
  padding: 0 50px;
}
.majorDialog .el-form-item__label {
  text-align: left;
}
.majorDialog .el-dialog__body {
  padding-left: 0;
  padding: 20px 0;
}
.majorDialog .el-dialog__footer {
  padding: 0;
}
.majorDialog .el-dialog__footer {
  text-align: center;
}
.saveButton {
  width: 400px;
  height: 40px;
  background: #0b3450 !important;
  color: #fff !important;
}
.banjitable {
  margin-top: 20px;
}
.banjitable .el-button--text {
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #0b3450;
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
}
.banjitable .el-table__header th,
.banjitable .el-table__header tr {
  background: #fbfbfb !important;
}
.banjitable .el-table__header thead {
  border: 1px solid #e6e6e6;
}
.banjitable .el-table__header {
  border-collapse: inherit !important;
}
.classdiv .el-table td,
.el-table th.is-leaf {
  border-bottom: 1px dashed #ebeef5;
}
.delectebutton span {
  color: #ff7500;
}
</style>
<style lang="scss" scoped>
.majorName {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-right: 5px;
}
.majorInput {
  border: none;
  outline: none;
  width: 290px;
}
.majordiv {
  width: 360px;
  padding: 0 20px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  line-height: 42px;
  margin-top: 13px;
}
.addtext {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}
.adddiv {
  cursor: pointer;
  line-height: 18px;
}
.imgicon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.catalog {
  overflow: hidden;
  padding: 20px 0 10px;
  border-bottom: 1px solid #e6e6e6;
  .line {
    width: 8px;
    height: 18px;
    background: #0b3450;
    float: left;
    margin-right: 8px;
  }
  .catalogTit {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
  }
}
.metTit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  margin: 10px 0;
}
.metuser {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: rgba(102, 102, 102, 1);
}
.imgBox {
  width: 100%;
  height: 181px;
  img {
    width: 100%;
    height: 100%;
  }
}
.contents {
  overflow: hidden;
  .contentsTit {
    margin-bottom: 20px;
    float: left;
    font-size: 14px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
  }
}
.convideo {
  height: 35px;
  overflow: hidden;
  div:nth-child(1) {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    float: left;
  }
  div:nth-child(2) {
    float: right;
  }
}
</style>
