import service from '@/utils/request.js'
export function courseUpdate(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/onlinelearningresource/update',
    data
  });
}
export function tipDelete(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/onlinelearningresourcedetails/delete',
    data
  });
}
export function courseDelete(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/gradecourserelation/delete',
    data
  });
}

export function tikuDelete(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/coursequiz/delete',
    data
  });
}
export function courseBanSave(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/gradecourserelation/save',
    data
  });
}

export function tikuSave(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/coursequiz/save',
    data
  });
}

export function tipList(data) {
  return service.request({
    method: 'get',
    url: '/goldcourse/web/onlinelearningresourcedetails/list',
    params: data
  });
}

export function getQuestionBankList(data) {
  return service.request({
    method: 'get',
    url: '/goldcourse/web/onlinelearningresourcedetails/getQuestionBankList',
    params: data
  });
}


export function queryRollList(data) {
  return service.request({
    method: 'get',
    url: '/goldcourse/web/onlinelearningresource/queryRollList',
    params: data
  });
}
export function courseList(data) {
  return service.request({
    method: 'get',
    url: '/goldcourse/web/gradecourserelation/list',
    params: data
  });
}
export function showAddList(data) {
  return service.request({
    method: 'get',
    url: '/goldcourse/web/onlinelearningresource/getGradeCourseList',
    params: data
  });
}

export function tipInfo(id) {
  return service.request({
    method: 'get',
    url: `/goldcourse/web/onlinelearningresourcedetails/info/${id}`,
  });
}

export function grademangerInfo(id) {
  return service.request({
    method: 'get',
    url: `/goldcourse/web/grademangerinfo/info/${id}`,
  });
}

export function gradecoursewareinfo(id) {
  return service.request({
    method: 'get',
    url: `/goldcourse/web/gradecoursewareinfo/info/${id}`,
  });
}

export function tipSave(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/onlinelearningresourcedetails/save',
    data
  });
}

export function tipUpdate(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/onlinelearningresourcedetails/update',
    data
  });
}
