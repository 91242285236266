<template>
  <div class="bg">
    <div class="title-view">
      <div class="leftLine"></div>
      <div class="text">专业建设</div>
    </div>
    <div class="line"></div>
    <div class="major" v-for="item in majorList" :key='item.fid'>
      <div class="item" >
        <img class="coverLogo" src="../../assets/img/cover-logo.png">
        <div class="content">
          <div class="fristLine">
            <div class="title">护理专业</div>
            <div class="detail" @click="goDetail(item.fid)">了解详情>></div>
          </div>
          <div class="intro">工作是整个医疗卫生工作的重要组成部分，但它又是有其自身的相对独立性和特殊性，护理人员的道德水平如何，关系到能否协调医生、护士、病人三者的关系，直接影响着医疗质量。护理工作的质量直接关系到患者的医疗安全、治疗效果和身体康复；护士的职业素质、服务态度、言谈举止也直接影响着患者的心理感受和医患关系的和谐、融洽。</div>
        </div>
      </div>
      <div class="line"></div>
    </div>
    <el-pagination
      background
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :total="1000">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data(){
    return{
      majorList:[{
        fid:1,
        name:'sname',
        age:'sss',
        logo:''
      },{
        fid:2,
        name:'sname',
        age:'sss',
        logo:''
      },{
        fid:3,
        name:'sname',
        age:'sss',
        logo:''
      },{
        fid:4,
        name:'sname',
        age:'sss',
        logo:''
      }]
    }
  },
  methods: {
    goDetail(fid){
      console.log("shushushsus===",fid);
      this.$router.push('/resource/major/detail')
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    }
  },
  created(){
    console.log(window.screen.availHeight);
  }
}
</script>

<style lang="scss" scoped>
.bg{
  margin-bottom: 10px;
}
.title-view{
  display: flex;
  .leftLine{
    width: 5px;
    height: 16px;
    background-color: #0F87FE;
  }
  .text{
    margin-left: 5px;
    line-height: 16px;
    font-size: 16px;
    color: black;
    margin-bottom: 15px;
  }
}
.line{
  width: 100%;
  height: 1px;
  background-color: #E8E8E8;
}
.el-pagination{
  margin-top: 40px;
}
.major{
  width: 100%;
  .item{
    height: 160px;
    display: flex;
    .coverLogo{
      margin-top: 30px;
      width: 162px;
      height: 100px;
    }
    .content{
      width: 100%;
      height: 100%;
      margin-left: 24px;
      .fristLine{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #666666;
        font-size: 13px;
        .title{
          font-size: 18px;
          color: #0F87FE;
        }
      }
      .intro{
        margin-top: 20px;
        font-size: 13px;
        color: #666666;
        height: 100%;
        line-height: 24px;
      }
    }
  }
}
</style>