<template>
  <div>素材资源库</div>
</template>

<script>
export default {

}
</script>

<style>

</style>