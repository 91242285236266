<template>
  <div>
    <div>
      <div>
        <ul class="resourceul">
          <li
            class="resourceli left"
            :class="{ titleActive: titleActive == index }"
            :key="index"
            v-for="(item, index) in arrText"
            @click="changIndex(index)"
          >
            <img
              class="selectactiveimg"
              src="../../assets/img/selectActive.png"
              alt=""
              v-show="titleActive == index"
            />
            <i class="iconfont" :class="item.icon" style="font-size: 20px"></i>
            {{ item.text }}
          </li>
        </ul>
      </div>
      <div class="bgColor">
        <div class="contontitem">
          <div class="clearfix">
            <div class="contontLeft left">
              <p class="majorTitle">资源专业分类</p>
              <ul class="majorul">
                <li
                  class="majorli"
                  :class="{ majorActive: majorActive == index }"
                  @click="selectMajor(item, index)"
                  v-for="(item, index) in majorData"
                  :key="index"
                >
                  {{ item.name }}
                  <div class="leftarrow"></div>
                </li>
              </ul>
            </div>
            <div class="left contontRight">
              <div v-show="showType == 'A'" class="resources">
                <p class="noText" v-if="couseArr.length == 0">暂无数据</p>
                <ul class="clearfix bookMajorul">
                  <Item
                    :key="index"
                    v-for="(item, index) in couseArr"
                    :item="item"
                    :index="index"
                  ></Item>
                </ul>
                <div class="block">
                  <el-pagination
                    :current-page="1"
                    @current-change="handleCurrentChangeClass"
                    :page-size="bookParam.pageSize"
                    background
                    layout="prev, pager, next,total"
                    :total="totalA"
                  >
                  </el-pagination>
                </div>
              </div>
              <div v-show="showType == 'B'">
                <div class="clearfix ziyuantype">
                  <p class="left">资源类型：</p>
                  <ul class="left clearfix">
                    <li
                      class="ziyuanli left"
                      v-for="(item, index) in resourceType"
                      :key="index"
                      :class="{ ziyuanactive: ziyuanFenindex == index }"
                      @click="ziyuanFenclick(item, index)"
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </div>
                <div class="clearfix ziyuantype">
                  <p class="left">资源分类：</p>
                  <ul class="left clearfix">
                    <li
                      class="ziyuanli left"
                      v-for="(item, index) in resourceFen"
                      :key="index"
                      :class="{ ziyuanactive: ziyuanindex == index }"
                      @click="ziyuanclick(item, index)"
                    >
                      {{ item.name }}
                    </li>
                  </ul>
                </div>
                <div class="sucaidiv">
                  <p class="noText" v-if="sucaiData.length == 0">暂无数据</p>
                  <ul class="sucaiul clearfix">
                    <li
                      class="sucaili left"
                      v-for="(item, index) in sucaiData"
                      :key="index"
                      @click="gotoDetail(item)"
                    >
                      <img :src="item.courseCoverUrl" alt="" />
                      <p class="sucaiTitle oneline">{{ item.name }}</p>
                      <p class="commonText">
                        文件大小:{{
                          toSize(item.coursePublicityImagesFileSize)
                        }}
                      </p>
                      <p class="commonText">
                        文件格式:{{
                          getExtension(item.coursePublicityImagesFileName)
                        }}
                      </p>
                      <p class="commonText">上传人:{{ item.createByName }}</p>
                    </li>
                  </ul>
                </div>
                <div class="block">
                  <el-pagination
                    :current-page="1"
                    @current-change="handleCurrentChangeSu"
                    :page-size="sucaiParam.pageSize"
                    background
                    layout="prev, pager, next,total"
                    :total="totalB"
                  >
                  </el-pagination>
                </div>
              </div>
              <div class="Nummeterial" v-show="showType == 'C'">
                <div class="sucai-resources">
                  <p class="noText" v-if="numData.length == 0">暂无数据</p>
                  <ul class="sucaiul clearfix">
                    <li
                      class="sucaili materialLi left"
                      v-for="(item, index) in numData"
                      :key="index"
                      @click="viewBook(item.textbookImagesUrl)"
                    >
                      <img :src="item.publicityImagesUrl" alt="" />
                      <p class="sucaiTitle">{{ item.name }}</p>
                    </li>
                  </ul>
                </div>
                <div class="block">
                  <el-pagination
                    :current-page="1"
                    @current-change="handleCurrentChangeNum"
                    :page-size="numParam.pageSize"
                    background
                    layout="prev, pager, next,total"
                    :total="totalC"
                  >
                  </el-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Item from '@/components/Item/index.vue'
import { queryAll } from '@/api/common/index.js'
import { resourceList, numList } from '@/api/resource/index.js'
import { toSize } from '@/utils/index.js'
export default {
  data() {
    return {
      selectedActive: this.$route.path,
      titleActive: 0,
      totalA: 0,
      totalB: 0,
      totalC: 0,
      bookParam: {
        pageNum: 1,
        pageSize: 15,
        courseType: 1,
        objectType: 1,
        professionalId: '',
      },
      numData: [],
      majorActive: false,
      numParam: {
        pageNum: 1,
        pageSize: 9,
        professionalId: '',
      },
      sucaiParam: {
        pageNum: 1,
        pageSize: 10,
        courseType: 4,
        objectType: 1,
        resourceTypeId: '',
        classifyId: '',
        professionalId: '',
      },
      showType: 'A',
      ziyuanindex: 0,
      ziyuanFenindex: 0,
      sucaiData: [],
      resourceType: [],
      resourceFen: [],
      couseArr: [],
      majorData: [],
      arrText: [
        {
          icon: 'icon-kecheng',
          text: '课程资源',
          imgactive: require('../../assets/img/upload.png'),
        },
        {
          icon: 'icon-sucai',
          text: '素材资源',
          imgactive: require('../../assets/img/sucaiiconactive.png'),
        },
        {
          icon: 'icon-kechengbiao',
          text: '数字化教材',
          imgactive: require('../../assets/img/shuziiconactive.png'),
        },
      ],
      defSty: {
        height: `${809}px`,
      },
    }
  },
  components: {
    Item,
  },
  watch: {
    $route(to, from) {
      this.selectedActive = to.path
    },
  },
  created() {
    if (localStorage.getItem('schoolId') != null&&localStorage.getItem('schoolId') != 0 && localStorage.getItem('schoolId') != "undefined" && localStorage.getItem('schoolId') != "null") {
      this.sucaiParam.schoolId = localStorage.getItem('schoolId')
      this.numParam.schoolId = localStorage.getItem('schoolId')
      this.bookParam.schoolId = localStorage.getItem('schoolId')
    }
    this.getMajorList()
    this.getDataList()
    this.getSucaiList()
    this.getresourceTypeList()
    this.getresourceFenList()
    this.getNumList()
  },
  methods: {
    toSize,
    viewBook(url) {
      window.open(url)
    },
    handleCurrentChangeClass(val) {
      this.bookParam.pageNum = val
      this.getDataList()
    },
    handleCurrentChangeSu(val) {
      this.sucaiParam.pageNum = val
      this.getSucaiList()
    },
    handleCurrentChangeNum(val) {
      this.numParam.pageNum = val
      this.getNumList()
    },
    getNumList() {
      numList(this.numParam)
        .then((res) => {
          if (res.status == 0) {
            this.numData = res.data
            this.totalC = res.page.total
          }
        })
        .catch(() => {})
    },
    getExtension(name) {
      return name.substring(name.lastIndexOf('.') + 1)
    },
    getSucaiList() {
      resourceList(this.sucaiParam)
        .then((res) => {
          if (res.status == 0) {
            this.sucaiData = res.data
            this.totalB = res.page.total
          }
        })
        .catch(() => {})
    },
    getDataList() {
      resourceList(this.bookParam)
        .then((res) => {
          if (res.status == 0) {
            this.couseArr = res.data
            this.totalA = res.page.total
          }
        })
        .catch(() => {})
    },
    getresourceFenList() {
      let param = {
        specialtyType: 3,
        objectType: 2,
      }
      queryAll(param)
        .then((res) => {
          if (res.status == 0) {
            this.resourceFen = res.data
            this.resourceFen.unshift({
              name: '全部',
              id: '',
            })
          }
        })
        .catch(() => {})
    },
    getresourceTypeList() {
      let param = {
        specialtyType: 2,
        objectType: 2,
      }
      queryAll(param)
        .then((res) => {
          if (res.status == 0) {
            this.resourceType = res.data
            this.resourceType.unshift({
              name: '全部',
              id: '',
            })
          }
        })
        .catch(() => {})
    },
    getMajorList() {
      let param = {
        specialtyType: 0,
        objectType: 1,
      }
      queryAll(param)
        .then((res) => {
          if (res.status == 0) {
            this.majorData = res.data
            this.majorData.unshift({
              name: '全部',
              id: '',
            })
          }
        })
        .catch(() => {})
    },
    gotoDetail(item) {
      this.$router.push({
        path: '/resource/teaMaterial',
        query: {
          id: item.id,
        },
      })
    },
    ziyuanclick(item, index) {
      this.ziyuanindex = index
      this.sucaiParam.classifyId = item.id
      this.getSucaiList(this.sucaiParam)
    },
    selectMajor(item, index) {
      this.majorActive = index
      if (this.showType == 'A') {
        this.bookParam.professionalId = item.id
        this.getDataList(this.bookParam)
      } else if (this.showType == 'B') {
        this.sucaiParam.professionalId = item.id
        this.getSucaiList(this.sucaiParam)
      } else {
        this.numParam.professionalId = item.id
        this.getNumList(this.numParam)
      }
    },
    ziyuanFenclick(item, index) {
      this.ziyuanFenindex = index
      this.sucaiParam.resourceTypeId = item.id
      this.getSucaiList()
    },
    changIndex(index) {
      this.titleActive = index
      if (index == 0) {
        this.showType = 'A'
        this.majorActive = 0
        this.bookParam.professionalId = ''
        this.getDataList(this.bookParam)
        return false
      }
      if (index == 1) {
        this.showType = 'B'
        this.majorActive = 0
        this.sucaiParam.professionalId = ''
        this.getSucaiList(this.sucaiParam)
        return false
      }
      if (index == 2) {
        this.showType = 'C'
        this.majorActive = 0
        this.numParam.professionalId = ''
        this.getNumList(this.numParam)
        return false
      }
    },
    handleSizeChange(newSize) {},
    handleCurrentChange(newPage) {
      this.bookParam.pageNum = newPage
      this.getDataList(this.bookParam)
    },
  },
}
</script>

<style lang="scss" scoped>
.noText {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.commonText {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
}
.sucaiTitle {
  height: 16px;
  overflow: hidden;
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  margin-top: 14px;
  margin-bottom: 4px;
}
.sucaidiv {
  width: 1080px;
}
.sucaiul {
  li {
    width: 190px;
    height: 184px;
    margin-right: 1px;
    margin-bottom: 1px;
    background: #fff;
    padding: 12px;
    cursor: pointer;
    img {
      width: 192px;
      height: 88px;
    }
  }
  // background: #fff;
}
.sucaiul .materialLi {
  height: 207px !important;
  width: 130px !important;
  padding: 19px 27px 0 27px;
  img {
    width: 130px;
    height: 174px;
  }
  .sucaiTitle {
    margin-top: 5px;
  }
}
.ziyuanli {
  margin-right: 20px;
  cursor: pointer;
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  padding: 0 9px;
}
.ziyuanactive {
  height: 22px;
  background: #0b3450;
  border-radius: 2px;
  color: #ffffff;
}
.ziyuantype {
  height: 22px;
  line-height: 22px;
  margin-bottom: 10px;
}
.pageblock {
  margin-bottom: 20px;
  margin-top: 20px;
}
.contontRight {
  .resources,
  .sucaidiv,
  .sucai-resources {
    // height: 630px;
  }
  /deep/ .linecourseli {
    margin-top: 0 !important;
  }
  /deep/ .itemdiv:nth-child(5n) {
    margin-right: 0;
  }
}
.contontRight {
  width: 1080px;
}
.bgColor {
  background: #f4f4f4;
  padding-top: 20px;
}
.leftarrow {
  width: 6px;
  height: 6px;
  border-top: 2px solid #867f7a;
  border-right: 2px solid #867f7a;
  transform: rotate(45deg);
  margin-right: -50px;
  position: absolute;
  right: 51px;
  top: 18px;
}
.majorul {
  height: 452px;
  overflow: auto;
}
.majorli {
  margin: 0 27px 0 21px;
  height: 44px;
  line-height: 44px;
  border-bottom: 1px solid #f4f4f4;
  position: relative;
}
.majorTitle {
  width: 166px;
  height: 58px;
  background: #0b3450;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  padding-left: 20px;
  line-height: 58px;
}
.contontLeft {
  margin-right: 15px;
  width: 186px;
  height: 510px;
  background: #fff;
}
.contontitem {
  width: 1290px;
  margin: 0 auto;
  padding: 0 180px 0 0px;
}
.selectactiveimg {
  position: absolute;
  bottom: -7px;
  z-index: 2;
  left: 0;
}
.resourceli {
  width: 131px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-right: 1px solid #ebebeb;
  cursor: pointer;
  position: relative;
  img {
    display: inline-block;
    vertical-align: middle;
    margin-top: -3px;
  }
}
.titleActive {
  color: #0b3450;
}
.resourceli:last-child {
  border-right: 0 !important;
}
.resourceul {
  width: 395px;
  height: 48px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 20px;
}
.banner {
  width: 100%;
  height: auto;
}
.main {
  background-color: white;
  margin-top: 10px;
}
.tac {
  padding-top: 30px;
}
.title {
  line-height: 64px;
  font-size: 24px;
  color: white;
  background-color: #0f87fe;
  padding-left: 20px;
}
.el-menu-item {
  height: 40px;
  line-height: 40px;
}
.mune-mask {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: #e8e8e8 0.8px solid;
}
.majorActive {
  color: #ffbc1c;
}
.bookMajorul {
  min-height: 450px;
}

.sucaiul {
  min-height: 450px;
  padding-top: 10px;
}

.oneline{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
