<template>
  <div class="couseParent">
    <div class="listNav main">
      <div class="main">
        <i class="iconfont icon-shouye"></i>
        <span>您的位置：</span>
        <span>首页</span>
        <span> > </span>
        <span>个人管理中心</span>
        <span> > </span>
        <span>我教的课程</span>
        <span> > </span>
        <span>课程编辑</span>
      </div>
      <div class="courseDetail clearfix">
        <img class="left" :src="addbannerlList.courseCoverUrl" alt="" />
        <div class="left coursediv">
          <p class="courseTitle">{{ addbannerlList.name }}</p>
          <p
            :class="['content', expande ? 'expande' : 'close']"
            ref="content"
            class="courseText"
          >
            {{ showText }}
            <span v-if="expande">...</span>
          </p>
        </div>
      </div>
      <div class="coursenav clearfix">
        <div class="leftnav left">
          <ul>
            <li
              :class="{ activecourse: courseIndex == index }"
              @click="gotoLink(item, index)"
              v-for="(item, index) in nvaArr"
              :key="index"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="contontrouter left"><router-view></router-view></div>
      </div>
    </div>
  </div>
</template>

<script>
import { videoInfo } from '@/api/center/teach.js'
export default {
  data() {
    return {
      nvaArr: [
        { name: '课程基础信息', link: '/course/base' },
        { name: '章节目录设置', link: '/course/chapter' },
        { name: '考试测试', link: '/course/examTest' },
        { name: '课程考核', link: '/course/courseAssessment' },
      ],
      courseIndex: 0,
      addbannerlList: {},
      expande: false,
      showText: '',
    }
  },
  watch: {
    $route(to, from) {
      console.log(to)
      if (to.path == '/course/base') {
        this.courseIndex = 0
      }
      if (to.path == '/course/chapter') {
        this.courseIndex = 1
      }
      if (to.path == '/course/examTest') {
        this.courseIndex = 2
      }
      if (to.path == '/course/courseAssessment') {
        this.courseIndex = 3
      }
    },
  },
  created() {
    this.courseIndex = sessionStorage.getItem('index')
      ? sessionStorage.getItem('index')
      : 0
    this.id = sessionStorage.getItem('courseId')
    this.getvideoInfo(this.id)
  },
  mounted() {},
  beforeDestroy() {
    sessionStorage.removeItem('index')
  },
  methods: {
    getvideoInfo(id) {
      videoInfo(id).then((res) => {
        this.addbannerlList = res.data
        this.$nextTick(() => {
          let lineHeight = 20
          console.log(this.$refs.content.offsetHeight)
          console.log(this.$refs.content.offsetHeight >= lineHeight * 3)
          if (this.$refs.content.offsetHeight >= lineHeight * 3) {
            console.log(5555)
            this.expande = true
            this.showText = res.data.content.substring(0, 220)
          } else {
            this.expande = false
            this.showText = res.data.content
          }
        })
      })
    },
    gotoLink(item, index) {
      this.courseIndex = index
      sessionStorage.setItem('index', index)
      this.$router.push({
        path: item.link,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.expande {
  overflow: auto;
  height: auto;
}

.contontrouter {
  width: 860px;
}
.couseParent {
  background: #f8f8f8;
}
.leftnav {
  width: 200px;
  height: 180px;
  margin-right: 20px;
  li {
    height: 44px;
    background: #fff;
    line-height: 44px;
    font-size: 13px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    color: #666666;
    cursor: pointer;
    text-align: center;
    border-bottom: 1px solid #efefef;
  }
}
.navtab {
  line-height: 30px;
}
.courseDetail {
  img {
    width: 154px;
    height: 86px;
    margin-right: 12px;
    margin-bottom: 20px;
  }
  .courseTitle {
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin-bottom: 7px;
  }
  .courseText {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    line-height: 20px; //高度必须设置为行高的3倍。
    overflow: hidden;
    position: relative;
    color: #333;
    box-sizing: border-box;
    word-wrap: break-word;
  }
  .coursediv {
    width: 907px;
  }
}
.activecourse {
  background: #0b3450 !important;
  color: #fff !important;
}
</style>
