<template>
  <div class="mainbg">
    <div class="listNav">
      <div class="main">
        <i class="iconfont icon-shouye"></i>
        <span>您的位置：</span>
        <span>首页</span>
        <span> > </span>
        <span>个人管理中心</span>
        <span> > </span>
        <span>我教的课程</span>
        <span> > </span>
        <span>授课详情</span>
      </div>
    </div>
    <div class="classtop">
      <div class="left">
        <img :src="grade.imagesUrl" />
        <div>
          <p>{{ grade.name }}</p>
          <span
            >班级人数：<i>{{ grade.number }}</i
            >人</span
          >
        </div>
      </div>
      <div class="right">
        <button type="button" class="joinStudent" @click="joinStudent">
          邀请学生加入
        </button>
      </div>
    </div>
    <div class="claconent">
      <template>
        <el-tabs v-model="activeName" @tab-click="handleClick" type="card">
          <el-tab-pane label="课程管理" name="first">
            <div class="coursebox">
              <div class="title">
                <h4>课程管理</h4>
              </div>
              <div class="content">
                <ul>
                  <li v-for="(course, index) in coureses" :key="index">
                    <a href="#">
                      <img :src="course.courseCoverUrl" />
                      <p>{{ course.name }}</p>
                    </a>
                    <template>
                      <el-button type="text" @click="open(course)"
                        ><span
                          ><i class="iconfont icon-shanchu"></i>删除</span
                        ></el-button
                      >
                    </template>
                  </li>
                  <li @click="dialogFormVisible = true">
                    <a href="javascript:;">
                      <i class="iconfont icon-jia1"></i>
                      <span>点击添加课程</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="block">
                <el-pagination
                  :current-page="1"
                  @current-change="handleCurrentChangeCourse"
                  :page-size="courseParam.pageSize"
                  background
                  layout="prev, pager, next,total"
                  :total="totalCourse"
                >
                </el-pagination>
              </div>
              <el-dialog title="选择添加课程" :visible.sync="dialogFormVisible">
                <el-form :model="form">
                  <div class="content">
                    <ul>
                      <li v-for="(course, index) in showAddData" :key="index">
                        <a href="javascript:;">
                          <img :src="course.courseCoverUrl" />
                          <p>{{ course.name }}</p>
                          <span
                            class="iconfont icon-xuanze"
                            :class="{ xuanze: course.gradeCourseType == 2 }"
                            @click="select(index, course)"
                          ></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogFormVisible = false"
                    >取 消</el-button
                  >
                  <el-button type="primary" @click="confirmDialog"
                    >确 定</el-button
                  >
                </div>
                <div class="block">
                  <el-pagination
                    :current-page="1"
                    @current-change="handleCurrentChangeAdd"
                    :page-size="showAddParam.pageSize"
                    background
                    layout="prev, pager, next,total"
                    :total="totalAdd"
                  >
                  </el-pagination>
                </div>
              </el-dialog>
            </div>
          </el-tab-pane>
          <el-tab-pane label="课件管理" name="second">
            <div class="coursebox warebox">
              <div class="title">
                <h4>课件管理</h4>
                <div class="clearfix">
                  <div class="adddiv right" @click="addChapter">
                    <img
                      class="imgicon"
                      src="../../assets/img/add.png"
                      alt=""
                    />
                    新增
                  </div>
                </div>
              </div>
              <div class="content">
                <template>
                  <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="name" label="课件名称" width="220">
                    </el-table-column>
                    <el-table-column label="文件格式" width="220">
                      <template slot-scope="scope">
                        {{ getExtension(scope.row.imagesFileName) }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="imagesFileSize"
                      label="文件大小"
                      width="220"
                    >
                    </el-table-column>
                    <el-table-column prop="createTime" label="日期" width="150">
                    </el-table-column>
                    <el-table-column label="操作" width="210">
                      <template slot-scope="scope">
                        <el-button
                          size="mini"
                          type="text"
                          @click="
                            handleDown(
                              scope.row.imagesUrl,
                              scope.row.imagesFileName
                            )
                          "
                          class="download"
                          >下载</el-button
                        >
                        <el-button
                          size="mini"
                          type="text"
                          @click="handleDelete(scope.row)"
                          class="download"
                          >修改</el-button
                        >
                        <el-button
                          type="text"
                          size="mini"
                          @click="deleteClick(scope.row)"
                          class="check"
                          >删除</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </div>
              <div class="block">
                <el-pagination
                  :current-page="1"
                  @current-change="handleCurrentChangeAdd"
                  :page-size="showAddParam.pageSize"
                  background
                  layout="prev, pager, next,total"
                  :total="totalAdd"
                >
                </el-pagination>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="课程统计" name="third">
            <div class="coursebox">
              <div class="title">
                <h4>课程统计</h4>
              </div>
              <div class="content">功能开发中...</div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="课程成绩" name="fourth">
            <div class="coursebox">
              <div class="title">
                <h4>课程成绩</h4>
              </div>
              <div class="content">
                <el-table
                  class="banjitable"
                  :data="tableDataCourse"
                  style="width: 100%"
                  :cell-style="setCellColor"
                >
                  <el-table-column prop="name" label="姓名"> </el-table-column>
                  <el-table-column prop="courseName" label="课程名称">
                  </el-table-column>
                  <el-table-column prop="examName" label="考试名称">
                  </el-table-column>
                  <el-table-column prop="totalScore" label="测试总成绩">
                  </el-table-column>
                  <el-table-column prop="createTime" label="成绩提交时间">
                  </el-table-column>
                  <el-table-column prop="totalValue" label="得分">
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </template>
    </div>
    <el-dialog
      :visible.sync="dialogVisibleCommontBan"
      @close="close"
      class="classDialog commonDialog"
    >
      <p class="addtext">{{ edit ? '编辑课件' : '添加课件' }}</p>
      <div class="majordiv">
        <span class="majorName">添加课件:</span>
        <input
          class="majorInput"
          v-model="classData.name"
          type="text"
          placeholder="请输入课件名称"
        />
      </div>
      <el-upload
        class="upload-demo banUpload"
        :action="getUrl"
        name="file"
        :limit="1"
        accept=".pdf,.pptx,.ppt,.doc,docx"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :file-list="fileList"
        :on-success="handleAvatarSuccess"
        :data="imgUploadData"
      >
        <el-button size="small" type="primary">上传文件</el-button>
        <div class="uploadtext">
          <p class="tiptext">点击选择一个文件到这里上传</p>
          <p class="tiptext">支持pdf、ppt、word...等格式，体积在2M以下</p>
        </div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button class="saveButton" @click="saveClass">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  CoursewareList,
  addCourseware,
  deleteCourseware,
  updateSaveList,
} from '@/api/center/index.js'
import {
  courseList,
  showAddList,
  courseBanSave,
  grademangerInfo,
  gradecoursewareinfo,
  courseDelete,
} from '@/api/course/index.js'
import { banInfo } from '@/api/center/teach.js'
import { baseUrl } from '@/config'
export default {
  data() {
    return {
      totalAdd: 0,
      totalCourse: 0,
      activeName: 'first',
      dialogFormVisible: false,
      param: {
        pageNum: 1,
        pageSize: 8,
        gradeId: '',
      },
      getUrl: `${baseUrl}goldcourse/common/aliyun/oss/uploadFiles`,
      imgUploadData: { serviceName: 'web' },
      fileList: [],
      grade: {},
      classData: {
        name: '',
        gradeId: '',
        imagesUrl: '',
        imagesFileName: '',
        imagesFileSize: '',
      },
      coureses: [],
      couresesIndex: [],
      tableData: [],
      tableDataCourse: [
        {
          name: '陈熙鹏',
          courseName: '计算机基础',
          createTime: '2021-01-29 10:19:21',
          examName: '基础测验',
          id: '558661170878286772',
          sectionId: '558661170878286778',
          totalScore: 100.0,
          totalValue: 60.0,
        },
        {
          name: '张旭初',
          courseName: '物流运筹规划',
          createTime: '2021-01-28 17:41:32',
          examName: '期末测试',
          id: '551763453400903273',
          sectionId: '558661093568875328',
          totalScore: 100.0,
          totalValue: 65.0,
        },
        {
          name: '王锦佑',
          courseName: '网络营销',
          createTime: '2021-01-13 14:04:09',
          examName: '《网店运营推广》中级理论-问答题815+58',
          id: '551130143293235292',
          sectionId: '551157390565762184',
          totalScore: 100.0,
          totalValue: 78.0,
        },
        {
          name: '宋承哲',
          courseName: '中餐服务与管理',
          createTime: '2021-01-29 09:41:12',
          examName: '中级理论-问答题815+58',
          id: '551705737630378697',
          sectionId: '551705737630378728',
          totalScore: 100.0,
          totalValue: 88.0,
        },
      ],
      form: {
        name: '',
        region: '',
      },
      formLabelWidth: '120px',
      isXuanze: false,
      id: '',
      dialogVisibleCommontBan: false,
      edit: false,
      showAddData: [],
      showAddParam: { pageNum: 1, pageSize: 8, objectType: 1, courseType: 1 },
      courseParam: { pageNum: 1, pageSize: 8, gradeId: '' },
      courseId: [],
      addParam: { gradeId: '', courseIds: '' },
    }
  },
  created() {
    this.id = this.$route.query.id
    this.param.gradeId = this.id
    this.addParam.gradeId = this.id
    this.getCoursewareList()
    this.getcourseList()
    this.getshowAddList()
    this.getGrademanger()
  },
  methods: {
    joinStudent() {
      this.$router.push({
        path: '/center/student',
        query: {
          id: this.id,
        },
      })
    },
    setCellColor({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 3) {
        return 'color:#ff7500'
      }
    },
    //获取班级信息
    getGrademanger() {
      grademangerInfo(this.id).then((res) => {
        if (status == 0) {
          this.grade = res.data
        }
      })
    },
    //删除
    deleteClick(row) {
      this.$confirm('此操作将删除当前数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteCourseware([row.id]).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getCoursewareList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    getExtension(name) {
      return name.substring(name.lastIndexOf('.') + 1)
    },
    handlePreview(file) {},
    handleRemove(file, fileList) {
      this.fileList = []
      this.classData.imagesUrl = ''
      this.classData.imagesName = ''
      this.classData.imagesSize = ''
    },
    saveClass() {
      this.classData.gradeId = this.$route.query.id
      if (this.edit) {
        updateSaveList(this.classData).then((res) => {
          if (res.status == 0) {
            this.dialogVisibleCommontBan = false
            console.log(this.classData.gradeId)
            this.$message({
              type: 'success',
              message: '修改成功!',
            })
            this.getCoursewareList()
          }
        })
      } else {
        addCourseware(this.classData).then((res) => {
          if (res.status == 0) {
            this.dialogVisibleCommontBan = false
            this.$message({
              type: 'success',
              message: '添加成功!',
            })
            this.getCoursewareList()
          }
        })
      }
    },
    close() {
      this.fileList = []
    },
    //新增
    addChapter() {
      this.edit = false
      this.dialogVisibleCommontBan = true
    },
    //编辑
    handleDelete(row) {
      this.edit = true
      this.dialogVisibleCommontBan = true
      this.id = row.id
      gradecoursewareinfo(row.id).then((res) => {
        if (status == 0) {
          this.classData = res.data
          this.fileList.push({
            url: res.data.imagesUrl,
            name: res.data.imagesFileName,
          })
          console.log(this.fileList)
          this.classData.imagesFileName = res.data.imagesFileName
          this.classData.imagesUrl = res.data.imagesUrl
          this.classData.imagesFileSize = res.data.imagesFileSize
        }
      })
    },
    getCoursewareList() {
      CoursewareList(this.param).then((res) => {
        this.tableData = res.data

        this.total = res.page.total
      })
    },
    confirmDialog() {
      this.addParam.courseIds = this.courseId.join(',')
      courseBanSave(this.addParam).then((res) => {
        this.dialogFormVisible = false
        this.$message({
          type: 'success',
          message: '添加成功!',
        })
        this.getcourseList()
      })
    },
    getcourseList() {
      this.courseParam.gradeId = this.id
      courseList(this.courseParam).then((res) => {
        this.coureses = res.data
        this.totalCourse = res.page.total
      })
    },
    getshowAddList() {
      showAddList(this.showAddParam).then((res) => {
        this.showAddData = res.data
        for (let i in this.showAddData) {
          if (this.showAddData[i].gradeCourseType == 2) {
            this.courseId.push(this.showAddData[i].id)
          }
        }
        this.totalAdd = res.page.total
      })
    },
    handleAvatarSuccess(res, file, fileList) {
      this.fileList = fileList
      this.classData.imagesUrl = res.data.url
      this.classData.imagesFileName = res.data.fileName
      this.classData.imagesFileSize = res.data.size
    },
    handleClick(tab, event) {},
    handleCurrentChangeAdd(val) {
      this.showAddParam.pageNum = val
      this.getshowAddList()
    },
    handleCurrentChangeCourse(val) {
      this.courseParam.pageNum = val
      this.getcourseList()
    },
    //删除
    open(row) {
      this.$confirm('此操作将删除当前班级的课程资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          courseDelete([row.courseId]).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getcourseList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    //课件管理
    handleEdit(index, row) {
      console.log(index, row)
    },
    //下载
    handleDown(filePath, fileName) {
      downloadUrlFile(filePath)
      function downloadUrlFile(url) {
        url = url.replace(/\\/g, '/')
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        //xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
        xhr.onload = () => {
          if (xhr.status === 200) {
            // 获取文件blob数据并保存
            saveAs(xhr.response, fileName)
          }
        }
        xhr.send()
      }

      /**
       * URL方式保存文件到本地
       * @param data 文件的blob数据
       * @param name 文件名
       */
      function saveAs(data, name) {
        var urlObject = window.URL || window.webkitURL || window
        var export_blob = new Blob([data])
        var save_link = document.createElementNS(
          'http://www.w3.org/1999/xhtml',
          'a'
        )
        save_link.href = urlObject.createObjectURL(export_blob)
        save_link.download = name
        save_link.click()
      }
    },
    //选择取消
    select(index, row) {
      if (row.gradeCourseType == 2) {
        row.gradeCourseType = 1
        this.courseId.forEach((r, i) => {
          if (r == row.id) {
            this.courseId.splice(i, 1)
          }
        })
        console.log(this.courseId)
      } else {
        this.courseId.push(row.id)
        console.log(this.courseId)
        row.gradeCourseType = 2
      }
    },
  },
}
</script>
<style lang="scss">
.classDialog .el-dialog {
  width: 480px;
}
.classDialog .el-form-item {
  margin-bottom: 0px;
  margin-top: 9px;
}
.classDialog .el-input__inner {
  border: none;
}
.classDialog .el-select {
  width: 77.5%;
}
.classDialog .ziyuanselect {
  width: 70%;
}
.classDialog .el-dialog__body {
  padding: 30px 40px;
}
.mainbg {
  background-color: #f8f8f8;
}
.listNav .main i,
.listNav .main span {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  line-height: 30px;
}
.listNav .main i {
  font-size: 14px;
  margin-right: 6px;
  font-weight: 600;
}
.listNav .main span:last-of-type {
  color: #0b3450;
}
.claconent {
  width: 1020px;
  height: auto;
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #ffffff;
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    width: 100%;
    .el-tabs__item {
      width: 25% !important;
      text-align: center;
    }
    .el-tabs__item.is-active {
      background-color: #0b3450;
      color: #ffffff;
    }
  }
}
.coursebox {
  .el-dialog {
    width: 56% !important;
    .el-dialog__body {
      padding: 10px 20px;
    }
  }
}
.content {
  .el-table__header-wrapper {
    background-color: #f8f8f8 !important;
    border: 1px solid #e6e6e6;
    .el-table__header {
      margin-bottom: -1px;
      .has-gutter {
        font-size: 13px;
        font-weight: 500;
        color: #333333;
        .cell {
          line-height: 16px;
        }
      }
    }
  }
  .el-table__body-wrapper {
    .el-table__row {
      .cell {
        line-height: 18px !important;
        font-size: 13px;
        color: #666;
      }
    }
    .el-button--text {
      color: #0b3450;
    }
    .el-button--mini,
    .el-button--small {
      padding: 0;
    }
  }
  .el-table tr,
  .el-table th {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  .el-table td {
    border-bottom: 1px dashed #e6e6e6 !important;
  }
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background-color: #ffffff !important;
  color: #606266;
  min-width: 30px;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  color: #409eff !important;
}
.coursebox {
  .title {
    padding-bottom: 10px;
    border-bottom: 1px solid #e6e6e6;
    h4 {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 14px;
      padding-left: 6px;
      border-left: 8px solid #0b3450;
    }
  }
  .content {
    margin-top: 10px;
    height: 500px;
    ul {
      li {
        // position: relative;
        margin-right: 15px;
        float: left;
        margin-bottom: 15px;
        a {
          position: relative;
          display: block;
          img {
            width: 192px;
            height: 108px;
          }
          p {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 192px;
            height: 26px;
            background: rgba(0, 0, 0, 0.56);
            text-align: center;
            font-size: 13px;
            color: #ffffff;
            line-height: 26px;
            overflow: hidden;
          }
          span.icon-xuanze {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 26px !important;
            color: #ccc;
            line-height: 26px !important;
            text-align: center;
            display: inherit;
            margin-top: -1px !important;
          }
          span.xuanze {
            color: #4be31c !important;
          }
        }
        button {
          display: block;
          padding: 0 !important;
          span {
            display: block;
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #cccccc;
            margin-top: 8px;
            cursor: pointer;
            i {
              margin-right: 4px;
            }
          }
        }
      }
      li:nth-child(5n) {
        margin-right: 0px;
      }
      li:last-child {
        width: 190px;
        height: 106px;
        background-color: #fdfdfd;
        border: 1px solid #f1eee7;
        border-radius: 1px;
        margin-bottom: 20px;
        a {
          display: block;
          text-decoration: none;
          i {
            width: 32px;
            height: 32px;
            font-size: 32px;
            line-height: 106px;
            margin-left: 79px;
            color: #cccccc;
          }
          span {
            font-size: 12px;
            font-weight: 400;
            color: #ccc;
            line-height: 12px;
            text-align: center;
            display: inherit;
            margin-top: 10px;
            font-family: Source Han Sans CN;
          }
        }
      }
    }
    .check {
      color: #ff7500;
    }
    .download {
      color: #0b3450;
    }
  }
}
</style>
<style lang="scss" scoped>
.joinStudent {
  cursor: pointer;
}
.majordiv {
  width: 360px;
  padding: 0 20px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  line-height: 42px;
  margin-top: 9px;
}
.majorName {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-right: 5px;
}
.majorInput {
  border: none;
  outline: none;
  width: 290px;
}
.adddiv {
  cursor: pointer;
}
.classtop {
  width: 1080px;
  height: 92px;
  background: url(../../assets/img/classbg.png) no-repeat 0 0;
  margin: 0 auto 10px auto;
  .left {
    margin: 20px 0px 20px 20px;
    img {
      width: 100px;
      height: 56px;
      display: inline-block;
      margin-right: 20px;
    }
    div {
      display: inline-block;
      vertical-align: top;
      font-size: 0;
      p {
        font-size: 16px;
        font-weight: 500;
        color: #ffffff;
        line-height: 28px;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 18px;
        opacity: 0.6;
      }
    }
  }
  .right {
    float: right;
    margin: 30px;
    button {
      width: 100px;
      height: 35px;
      background: #3699ff;
      border-radius: 2px;
      color: #ffffff;
      border: 0;
    }
  }
}
</style>
