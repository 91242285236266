<template>
  <div>
    <div class="nav">
      <div class="main">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>书目下载</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="main">
      <div class="centest">
        <div class="conTit">
          <div></div>
          <div>书目下载</div>
        </div>
        <div class="select">
          <div
            v-for="item in branchList"
            :key="item.key"
            @click="handleTab(item.key)"
            :class="{active : active == item.key}"
            class="seachBtn"
          >{{item.typeName}}</div>
          <!-- <div class="seachBtn" :class="{active : active == '全部'}" @click="handleTabAll()">全部</div> -->
        </div>
        <div class="concatalog">
          <el-row class="titleBox" v-for="item in dataList" :key="item.catalogId">
            <el-col :span="18">
              <div class="grid-content bg-purple">
                <div class="title">{{item.catalogName}}</div>
                <img src="../../assets/img/catalogLogo.png" @click="downloadFile(item.fileName,item.filePath)" />
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content bg-purple-light titTime">{{item.addTime}}</div>
            </el-col>
          </el-row>
        </div>
        <div class="block">
          <el-pagination layout="prev, pager, next" @current-change="changePage" :total="total"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { api } from "../../assets/api/axios";
import downloadByUrl from 'downloadbyurl'

export default {
  data() {
    return {
      par:{
          "pageNum":1,
          "pageSize":5,
          "catType":""
      },
      dataList:[],
      total:0,
      branchList: [
        { typeName: '全部',key:'all' },
        { typeName: '公共基础系列',key:'0' },
        { typeName: '高职高专系列',key:'1' },
        { typeName: '本科系列',key:'2' }
      ],
      active: 'all'
    }
  },
  created(){
    this.loadcatalogList();
  },
  methods: {
    async loadcatalogList(){
      var res = await api.post('front/teamaterial/catalogDownload',this.par)
      this.dataList = res.data?res.data.list:[],
      this.total = res.data?res.data.total:0
    },
    downloadFile(fileName,filePath){
       if(filePath == null){
         this.$message.error('资源不存在，无法下载');
         return
       }

      downloadUrlFile(filePath)
function downloadUrlFile(url) {
      url= url.replace(/\\/g, '/');
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      //xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
      xhr.onload = () => {
        if (xhr.status === 200) {
          // 获取文件blob数据并保存
          var fileName = getFileName(url);
          saveAs(xhr.response, fileName);
        }
      };

      xhr.send();
    }

    /**
     * URL方式保存文件到本地
     * @param data 文件的blob数据
     * @param name 文件名
     */
    function saveAs(data, name) {
        var urlObject = window.URL || window.webkitURL || window;
        var export_blob = new Blob([data]);
        var save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
        save_link.href = urlObject.createObjectURL(export_blob);
        save_link.download = name;
        save_link.click();
    }

    /**
     * 根据文件url获取文件名
     * @param url 文件url
     */
    function getFileName(url)
    {
        var num = url.lastIndexOf('/')+1
        var fileName = url.substring(num)
        //把参数和文件名分割开
        fileName = decodeURI(fileName.split("?")[0]);
        return fileName;
    }
        // var aEle = document.createElement("a");// 创建a标签
        // aEle.download = fileName;// 设置下载文件的文件名
        // aEle.href = filePath;// content为后台返回的下载地址
        // aEle.click();// 设置点击事件
        //   aEle.setAttribute('download', fileName);
        //   aEle.setAttribute('href', filePath);
        //    document.body.appendChild(aEle);
        //     aEle.click();
        //     aEle.remove();
    },
    changePage(page){
      this.par.pageNum = page;
      this.loadcatalogList();
    },
    handleTab(typeName) {
      this.active = typeName
      this.par.pageNum = 1;
      this.par.catType = typeName;
      this.loadcatalogList();
    },
    handleTabAll() {
      this.active = 'all'
      // console.log(this.active);
    }
  }
}
</script>
<style lang="scss" scoped>
.centest {
  margin: 0 auto;
  width: 940px;
  height: 1068px;
  background: rgba(255, 255, 255, 1);
  padding: 30px 80px 0 80px;
  .conTit {
    overflow: hidden;
    border-bottom: 1px solid #e6e6e6ff;
    padding-bottom: 10px;
    :nth-child(1) {
      width: 4px;
      height: 20px;
      background: rgba(181, 14, 5, 1);
      float: left;
    }
    :nth-child(2) {
      font-size: 20px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      float: left;
      margin-left: 8px;
    }
  }
  .active {
    background: #b50e05ff;
    color: #fff !important;
  }
  .seachBtn {
    width: 120px;
    height: 32px;
    border: 1px solid rgba(229, 229, 229, 1);
    font-size: 14px;
    font-family: 'Source Han Sans CN';
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 32px;
    text-align: center;
    float: left;
    margin-right: 10px;
  }
  .select {
    margin: 10px 0 20px;
    overflow: hidden;
  }
  .concatalog {
    min-height: 600px;
    overflow: hidden;
    .titleBox {
      overflow: hidden;
      border-bottom: 1px dashed #d2d2d2ff;
      padding-bottom: 10px;
      margin-bottom: 20px;
      .title {
        font-size: 14px;
        color: rgba(51, 51, 51, 1);
        float: left;
        margin-right: 10px;
      }
      .titTime {
        font-size: 14px;
        color: rgba(153, 153, 153, 1);
        text-align: right;
      }
    }
  }
}
</style>
