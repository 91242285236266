<template>
  <div>
    <div>
      <div  class="main bgmain">
        <div class="listNav">
          <div class="main">
            <i class="iconfont icon-shouye"></i>
            <span>您的位置：</span>
            <span>首页</span>
            <span> > </span>
            <span>在线学习</span>
            <span> > </span>
            <span>在线学习详情</span>
          </div>
        </div>
        <div class="detMain clearfix">
          <div class="grid-content bg-purple left">
            <div class="courseCover">
              <img v-if="Info.coursePublicityImagesUrl == ''" :src="Info.courseCoverUrl" alt />
              <div class="videoDiv" v-if="Info.coursePublicityImagesUrl != ''">
                <video-player class="video-player xuanchuanvideo" ref="videoPlayerXuan" :playsinline="true"
                  style="object-fit: fill" :options="playerOptionsDiv" :x5-video-player-fullscreen="true"
                  @pause="onPlayerPause($event)" @play="onPlayerPlayDiv($event)"
                  @fullscreenchange="onFullscreenChange($event)" @click="fullScreen" id="video1"></video-player>
              </div>
              <!-- <div
                class="playDiv"
                @click="MicroCourse(sitem.detailsName, sitem.filePath)"
              >
                <img class="playIcon" src="../../assets/img/play.png" alt="" />
              </div> -->
            </div>
          </div>
          <div class="grid-content bg-purple left">
            <div class="title">{{ Info.name }}</div>
            <div class="info">
              <div class="left">发布人：{{ Info.createByName }}</div>
            </div>
            <div class="info">
              <div class="left">简介：{{ Info.content }}</div>
            </div>
            <div class="operation">
              <div class="left colldiv">
                <i @click="collect(1)" v-if="collectionFlag == 0" class="el-icon-star-on collectIcon"
                  style="color: #f60"></i>
                <i class="el-icon-star-off collectIcon" @click="collect(0)" v-if="collectionFlag == 1"></i>
              </div>
              <div @click="studyVideo" class="left sudtyButton">开始学习</div>
              <p class="studyNum left">
                已有<span>{{ Info.studyNumber }}</span>人学习
              </p>
            </div>
          </div>
        </div>
        <div class="detMain contont">
          <el-tabs v-model="activeName" @tab-click="handleClick" class="cou-infor">
            <el-tab-pane label="章节目录" name="seven">
              <div class="catalog">
                <div class="line"></div>
                <div class="catalogTit">章节目录</div>
              </div>
              <div class="catalog-infor">
                <div v-for="(item, index) in MicroCourseList" :key="index">
                  <div class="contents1">
                    <div class="contentsTitmulu">{{ item.name }}</div>
                  </div>
                  <div class="convideo" v-for="(sitem, index) in item.resourceDetailsLst" :key="index" @click="
                    MicroCourse(
                      sitem.detailsName,
                      sitem.knobbleVideoUrl,
                      item,
                      sitem
                    )
                  ">
                    <!-- <img class="iconimg" src="../../assets/img/video.png" alt="" /> -->
                    <img class="iconimg" :src="geticon(sitem.knobbleVideoUrl)" alt="" />
                    <div class="">{{ sitem.detailsName }}</div>
                    <el-button type="text" class="rightf" icon="el-icon-download" 
                      @click.stop="download(sitem)">下载</el-button>
                    <!-- <div class="timecolor">
                      时长:{{ time(sitem.knobbleVideoFileDuration) }}
                    </div> -->
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="课程详情" name="first">
              <div class="catalog">
                <div class="line"></div>
                <div class="catalogTit">课程详情</div>
              </div>
              <div class="catalogText catalog-infor" v-html="Info.content"></div>
            </el-tab-pane>
            <el-tab-pane label="课程评价" name="three">
              <div class="catalog">
                <div class="line"></div>
                <div class="catalogTit left">课程评价</div>
                <div class="btn right pingjia" @click="pingjia()">
                  <img src="../../assets/img/pingjia.png" alt="" />
                  我要评价
                </div>
              </div>
              <div class="catalogItem catalog-infor">
                <ul>
                  <li :key="index" class="pingjiali clearfix" v-for="(item, index) in commentData">
                    <div class="pingjiaLeft left">
                      <img v-if="item.imagesUrl" class="userAvatar" :src="item.imagesUrl" alt="" />
                      <img class="userAvatar" v-else src="../../assets/touxiang1.jpg" alt="" />
                      <p>{{ item.userName }}</p>
                    </div>
                    <div class="pingjiaRight left">
                      <el-rate v-model="item.score" disabled text-color="#ff9900" score-template="{value}">
                      </el-rate>
                      <p class="pingContont">
                        {{ item.commentContent }}
                      </p>
                      <p class="pingtime">{{ item.createTime }}</p>
                    </div>
                  </li>
                </ul>
                <div class="block">
                  <el-pagination :current-page="1" @current-change="handleCurrentChangeClass"
                    :page-size="pingjiaParam.pageSize" background layout="prev, pager, next,total" :total="total">
                  </el-pagination>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="测试" name="second">
              <div class="catalog">
                <div class="line"></div>
                <div class="catalogTit">测试</div>
              </div>
              <div v-for="(item, index) in testData" :key="index" class="catalog-infor">
                <div class="contents">
                  <div class="contentsTit">
                    <p class="subjectTitle">{{ item.name }}</p>
                    <div class="commonText">
                      <span>共{{ item.questionNumber }}道题</span>
                      <span class="VerticaLine">|</span>
                      <span>总分值{{ item.totalScore }}分</span>
                      <span class="VerticaLine">|</span>
                      <span>已有{{ item.submitTheNumber }}人做过</span>
                      <span class="VerticaLine">|</span>
                      <span>得分:</span>
                      <span class="getScore">{{ item.totalValue }}分</span>
                    </div>
                  </div>
                  <div class="btn" @click="test(item.id, item.name)" v-if="item.startType == 1">
                    <img src="../../assets/img/testicon.png" alt="" />
                    开始考试
                  </div>
                  <div class="btn" @click="viewDetai(item.id, item.name)" v-if="item.startType == 2">
                    <img src="../../assets/img/viewDetail.png" alt="" />
                    查看详情
                  </div>
                  <div class="btn" @click="test(item.id, item.name)" v-if="item.startType == 2">
                    <img src="../../assets/img/repeat.png" alt="" />
                    重新考试
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="章节目录" name="seven">
              <div class="catalog">
                <div class="line"></div>
                <div class="catalogTit">章节目录</div>
              </div>
              <div class="catalog-infor">
                <div v-for="(item, index) in MicroCourseList" :key="index">
                  <div class="contents1">
                    <div class="contentsTitmulu">{{ item.name }}</div>
                  </div>
                  <div class="convideo" v-for="(sitem, index) in item.resourceDetailsLst" :key="index" @click="
                    MicroCourse(
                      sitem.detailsName,
                      sitem.knobbleVideoUrl,
                      item,
                      sitem
                    )
                  ">
                    <img class="iconimg" :src="geticon(sitem.knobbleVideoUrl)" alt="" />
                    <div class="">{{ sitem.detailsName }}</div>
                    <el-button type="text" class="rightf" icon="el-icon-download" 
                      @click.stop="download(sitem)">下载</el-button>
                  </div>
                </div>
              </div>
            </el-tab-pane> -->
            <el-tab-pane v-if="false" label="知识归纳" name="last">
              <div class="catalog">
                <div class="line"></div>
                <div class="catalogTit">章节目录</div>
              </div>
              <div class="catalog-infor">
                <div v-for="(item, index) in materialData" :key="index">
                  <div class="contents1">
                    <div class="contentsTitmulu">{{ item.name }}</div>
                  </div>
                  <div class="convideo" v-for="(sitem, index) in item.resourceDetailsLst" :key="index">
                    <img class="iconimg" :src="geticon(sitem.knobbleVideoUrl)" alt="" />
                    <div class="">{{ sitem.detailsName }}</div>
                    <div class="timecolor viewmaterial" @click="viewmaterial(sitem.knobbleVideoUrl)">
                      {{ strToFileExtention(sitem.knobbleVideoFileName) }}
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
          <!-- //// -->
          <!-- 微课播放器 -->
          <el-dialog :title="microTitle" :visible.sync="dialogVisible" width="50%">
            <div class="player">
              <video-player class="video-player vjs-custom-skin" ref="videoPlayer" :playsinline="true"
                style="object-fit: fill" :options="playerOptions" :x5-video-player-fullscreen="true"
                @pause="onPlayerPauseTip($event)" @play="onPlayerPlay($event)" @playing="onPlayerPlaying($event)"
                @fullscreenchange="onFullscreenChange($event)" @timeupdate="onPlayerTimeupdate($event)"
                @click="fullScreen" id="video1"></video-player>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
          </el-dialog>
          <!-- /微课播放器 -->
          <el-dialog title="对资源做出相应的评价" :visible.sync="dialogVisibleCommont" class="commontDialog">
            <div class="ratediv">
              <el-rate v-model="addbannerlList.score" show-text :colors="colors" :texts="[
                '需要优化！',
                '进步空间很大！',
                '体验一般！',
                '比较满意！',
                '收获满满！',
              ]">
              </el-rate>
            </div>
            <textarea class="textarea" placeholder="请对课程做出您的评价" name="" id="" cols="30" rows="10"
              v-model="addbannerlList.commentContent"></textarea>
            <span slot="footer" class="dialog-footer">
              <el-button class="confirmButton" @click="postaddComment">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
    </div>
    <fileview ref="fileview"></fileview>
  </div>
</template>
<script>
import { api } from '../../assets/api/axios'
import { downloadByUrl } from "@/utils/index";
import fileview from './fileview.vue';
import { mapMutations, mapState, mapGetters } from 'vuex'
import {
  courseDetail,
  findCourseTestPaperLst,
  evaluateList,
  addComment,
  listSection,
  saveTime,
} from '@/api/detail/index.js'
export default {
  components:{
    fileview
  },
  data() {
    return {
      isDownLoad:false,
      downLoadUrl:'',
      activeName: 'seven',
      dialogVisibleCommont: false,
      radio: 3,
      matitem: [{ id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }],
      demodialogVisible: false,
      matdialogVisible: false,
      textarea: '',
      dialogVisible: false,
      playerOptionsDiv: {
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '4:3', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4',
            src: 'http://wutonghua-oss.oss-cn-qingdao.aliyuncs.com/jiaofa/onlinechapter/03墙下带形基础.mp4',
          },
        ],
        poster: '',
        width: '',
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          // timeDivider: true,
          // durationDisplay: true,
          // remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      playerOptions: {
        //playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '4:3', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4',
            src: 'http://wutonghua-oss.oss-cn-qingdao.aliyuncs.com/jiaofa/onlinechapter/03墙下带形基础.mp4',
          },
        ],
        poster: '',
        width: '',
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          // timeDivider: true,
          // durationDisplay: true,
          // remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      ruleForm: {
        teamaterialId: '', //教材ID
        phone: '',
        schName: '',
        department: '',
        post: '',
        email: '',
        teaCourse: '',
        senDarea: '',
        jobNum: '',
      },
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
      },
      Info: {},
      fid: '',
      typeList: [],
      total: 0,
      pageBean: {
        pageNum: 1,
        pageSize: 5,
        typeId: '',
        teamatId: '',
      },
      resPackage: [],
      message: '',
      showmsg: false,
      showItem: true,
      MicroCourseList: [],
      microTitle: '',
      enableApply: '',
      sampleStatus: '',
      resbtu: false,
      res: true,
      catalogText: '',
      id: '',
      fileUrl:'http://118.190.88.169:8012/onlinePreview',
      pingjiaParam: {
        resourceId: '',
        pageNum: 1,
        pageSize: 8,
      },
      commentData: [],
      testData: [],
      addbannerlList: { themeId: '', commentContent: '', score: 0 },
      playEndParam: {
        sectionId: '',
        courseId: '',
        courseSectionId: '',
        videoTotalRecordTime: '',
      },
      isPlay: false,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      collectionFlag: 1,
      materialData: [],
      fileArray: [
        'pdf',
        'jpg',
        'jpeg',
        'png',
        'gif',
        'JPG',
        'JPEG',
        'PBG',
        'GIF',
        'mp4',
      ],
    }
  },
  watch: {
    dialogVisible: function (val) {
      if (val == false) {
        this.$refs.videoPlayer.player.pause()
      }
    },
  },
  created() {
    this.id = this.$route.query.id
    this.playEndParam.courseId = this.id
    this.getcourseDetail()
    this.getlistSection(2)
    this.getfindCourseTestPaperLst()
  },
  computed: {
    ...mapGetters([
      'resPhone',
      'resFid',
      'resStatus',
      'resSchName',
      'resName',
      'resjobNum',
      'restypeFlag',
      'resschType',
    ]),
  },
  mounted() { },
  beforeDestroy() {
    if (this.isPlay) {
      this.playend()
    }
  },
  methods: {
    strToFileExtention(str) {
      /*只获取后缀*/
      var suffix = str.substring(str.lastIndexOf('.') + 1) //txt
      if (this.fileArray.includes(suffix)) {
        return '查看'
      } else {
        return '下载'
      }
    },
    viewmaterial(url) {
      window.open(url)
    },
    collect(num) {
      this.collectionFlag = num

      this.$message({
        type: 'success',
        message: this.collectionFlag == 0 ? '收藏成功!' : '取消成功!',
      })
    },
    time(value) {
      var offset = parseInt(value) // 单位为秒
      var secLeft = offset % 60
      var minLeft = Math.floor(offset / 60) % 60
      var hourLeft = Math.floor(offset / 60 / 60) % 24
      var min = minLeft
      var hour = hourLeft
      // 补0操作
      secLeft = (secLeft < 10 ? '0' : '') + secLeft
      minLeft = (minLeft < 10 ? '0' : '') + minLeft
      hourLeft = (hourLeft < 10 ? '0' : '') + hourLeft

      // ）拼接时间格式，返回值
      let result
      result = hourLeft + ':' + minLeft + ':' + secLeft
      return result
    },
    getcourseDetail() {
      courseDetail(this.id).then((res) => {
        this.Info = res.data
        if (res.data.coursePublicityImagesUrl) {
          this.$nextTick(() => {
            this.playerOptionsDiv.sources[0].src =
              res.data.coursePublicityImagesUrl
          })
        }
      })
    },
    viewDetai(id, name) {
      sessionStorage.setItem('courseId', this.$route.query.id)
      this.$router.push({
        path: '/viewTest',
        query: {
          id: id,
          courseId: this.$route.query.id,
          kaoName: name,
        },
      })
    },
    getfindCourseTestPaperLst() {
      let param = {
        resourceId: this.id,
        userId: localStorage.getItem('userId'),
      }
      findCourseTestPaperLst(param).then((res) => {
        this.testData = res.data
      })
    },
    getevaluateList() {
      this.pingjiaParam.resourceId = this.id
      evaluateList(this.pingjiaParam).then((res) => {
        this.commentData = res.data
        this.total = res.page.total
      })
    },
    getlistSection(courseType) {
      let param = { resourceId: this.id, courseType: courseType }
      listSection(param).then((res) => {
        if (courseType == 2) {
          this.MicroCourseList = res.data
        } else if (courseType == 5) {
          this.materialData = res.data
        }
      })
    },
    postaddComment() {
      this.addbannerlList.themeId = this.id
      addComment(this.addbannerlList).then((res) => {
        if (res.status == 0) {
          this.$message.success('评论成功')
          this.dialogVisibleCommont = false
          this.getevaluateList()
        }
      })
    },
    handleCurrentChangeClass(val) {
      this.pingjiaParam.pageNum = val
      this.getevaluateList()
    },
    pingjia() {
      this.addbannerlList.score = 0
      this.addbannerlList.commentContent = ''
      this.dialogVisibleCommont = true
    },
    MicroCourse(fileName, filePath, item, sitem) {
      console.log("filePath",fileName);
      if (filePath.lastIndexOf('.mp4') >= 0) {
        this.playEndParam.sectionId = item.id
        this.playEndParam.courseSectionId = sitem.id
        this.dialogVisible = true
        this.microTitle = fileName
        this.playerOptions.sources[0].src = filePath
      }else{
        var fileUrl = "https://view.officeapps.live.com/op/view.aspx?src=" + filePath
        // window.open(fileUrl)
        this.$refs.fileview.show(fileUrl,fileName)
        // this.$refs.fileview.show(`${this.fileUrl}?url=${encodeURIComponent(btoa(filePath))}`,fileName)
      }
    },
    download(item){
      this.downLoadUrl = item.knobbleVideoUrl
      this.isDownLoad = true;
      downloadByUrl(item.knobbleVideoUrl,item.detailsName,()=>{
        this.isDownLoad = false;
      })
      // 
    },
    geticon(str){
      console.log('dsssss',str);
      if (str.lastIndexOf('.mp4') >= 0) {
        return require('@/assets/img/video.png');
      }
      return require('@/assets/file.png');
    },
    studyVideo() {
      let param = { resourceId: this.id }
      listSection(param).then((res) => {
        var list = res.data
        var newArr = []
        var Id = ''
        list.forEach((item, index, array) => {
          if (item.resourceDetailsLst.length != 0) {
            Id = item.id
          }
          newArr = newArr.concat(item.resourceDetailsLst)
        })
        if (newArr.length == 0) {
          this.$message.success('暂无可播放视频')
        } else {
          this.dialogVisible = true
          this.playEndParam.sectionId = Id
          this.playEndParam.courseSectionId = newArr[0].id
          this.playerOptions.sources[0].src = newArr[0].knobbleVideoUrl
        }
      })
    },
    // 开始测试
    test(id, name) {
      sessionStorage.setItem('courseId', this.$route.query.id)
      sessionStorage.setItem('kaoName', name)
      this.$router.push({
        // path: '/viewTest',
        path: '/teachingmat/chaptertest',
        query: {
          id: id,
          courseId: this.$route.query.id,
          kaoName: name,
        },
      })
    },
    handleClick(tab, event) {
      if (this.activeName == 'second') {
        this.getfindCourseTestPaperLst()
      } else if (this.activeName == 'first') {
      } else if (this.activeName == 'seven') {
        this.getlistSection(2)
      } else if (this.activeName == 'three') {
        this.getevaluateList()
      } else if (this.activeName == 'last') {
        this.getlistSection(5)
      }
    },
    handleClose(done) {
      this.demodialogVisible = false
      this.matdialogVisible = false
    },
    // 视频方法
    fullScreen() {
      const player = this.$refs.videoPlayer.player
      player.requestFullscreen() //调用全屏api方法
      player.isFullscreen(true)
      player.play()
    },
    onPlayerPlay(player) {
      this.isPlay = true
      player.play()
    },
    onPlayerPlayDiv() { },
    onPlayerPause(player) { },
    onPlayerPauseTip(player) {
      this.playEndParam.videoTotalRecordTime = player.cache_.currentTime
      this.playend()
      console.log(player.cache_.currentTime)
    },
    playend() {
      if (!localStorage.getItem('token')) {
        this.isPlay = false
        return
      }
      saveTime(this.playEndParam).then((res) => {
        this.isPlay = false
      })
    },
    onPlayerPlaying() {
      console.log(666)
    },
    //当前播放位置发生变化时触发。
    onPlayerTimeupdate(player) {
      this.playEndParam.videoTotalRecordTime = player.cache_.currentTime
    },
    // 表单验证
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },
}
</script>
<style lang="scss">
.rightf.is-loading{
  position: absolute;
  right:16px;
}
.rightf{
  position: absolute;
  right:16px;
  color: #0b3450 !important;
}
.colldiv {
  line-height: 32px;
}

.collectIcon {
  font-size: 20px;
  margin-top: 5px;
  margin-right: 5px;
}

.videoDiv {
  height: 100%;
}

.xuanchuanvideo {
  height: 100%;
}

.xuanchuanvideo .video-js {
  height: 214px !important;
  padding-top: 0;
}

.xuanchuanvideo .video-js .vjs-big-play-button {
  width: 46px;
  height: 46px !important;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
}

.textarea {
  resize: none;
  height: 132px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  margin-top: 10px;
  padding: 10px 20px;
  width: 458px;
  line-height: 20px;
}

.ratediv {
  width: 480px;
  height: 86px;
  background: #f8f8f8;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.commontDialog .el-dialog__body {
  padding: 0 40px;
}

.commontDialog .el-dialog__footer {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 60px;
}

.commontDialog .el-dialog {
  width: 580px;
  height: 434px;
}

.confirmButton {
  height: 40px;
  background: #0b3450 !important;
  width: 500px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;

  span {
    color: #fefefe;
  }
}

.commontDialog .el-dialog__headerbtn {
  width: 40px;
  height: 40px;
  background: #0b3450;
  right: 0;
  top: 0;
}

.commontDialog .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}

.detMain .el-tabs__item.is-active {
  background: #0b3450 !important;
}

.cou-infor {
  .el-tabs__header {
    margin: 0 0 20px 0;

    .el-tabs__nav-scroll {
      background-color: #f8f8f8 !important;
      border: 1px solid #e8e8e8;
      height: 38px !important;

      .el-tabs__item {
        width: 118px;
        height: 38px;
        line-height: 38px;
        background: none;
        border-right: 1px solid #e8e8e8;
      }
    }
  }

  .catalog {
    margin: 0px 0 !important;
    padding: 0 0 10px 0 !important;
  }

  .catalog-infor {
    margin-top: 20px;
  }
}
</style>

<style lang="scss" scoped>
.timecolor {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
}

.playDiv {
  width: 46px;
  height: 46px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
}

.detBg {
  background-color: #f8f8f8;
}

.pingContont {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  margin-top: 9px;
  margin-bottom: 9px;
}

.pingjiaRight {
  width: 827px;
}

.pingjiali {
  border-bottom: 1px solid #f8f8f8;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.pingjiaLeft {
  width: 41px;
  margin-right: 19px;

  p {
    text-align: center;
  }
}

.userAvatar {
  width: 41px;
  height: 41px;
  border-radius: 50%;
}

.pingjia {
  position: relative;
  padding-left: 25px;
  height: 16px;
  font-size: 14px;
  cursor: pointer;

  img {
    position: absolute;
    top: -1px;
    left: 0;
  }
}

.getScore {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #eb8309;
  margin-left: 7px;
}

.VerticaLine {
  margin: 0 10px;
}

.subjectTitle {
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 10px;
}

.contont {
  padding-top: 20px;
  min-height: 580px;
}

.commonText {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.studyNum {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  line-height: 32px;
  margin-left: 20px;
}

.nav {
  width: 960px;
  margin: 0 auto;
  height: 42px;
}

.bgmain {
  background: #fff;
  margin-top: 4px;
}

.detMain {
  margin: 0 60px 0;
  position: relative;
}

.courseCover {
  height: 214px;
  width: 380px;
  margin-right: 20px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
}

.star {
  margin-top: 10px;
  height: 38px;
  border: 1px solid rgba(224, 224, 224, 1);
  width: 100%;
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  line-height: 40px;
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.info {
  overflow: hidden;

  .left {
    float: left;
    width: 505px;
    max-height: 88px;
    font-size: 12px;
    line-height: 18px;
    margin-top: 9px;
    color: #666666;
    margin-bottom: 13px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
  }

  .right {
    float: left;
    font-size: 14px;
    color: rgba(102, 102, 102, 1);
  }
}

.iconimg {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  width: 18px;
}

.link {
  width: 100%;
  min-height: 40px;
  background: rgba(245, 245, 245, 1);
  margin-top: 20px;
  line-height: 40px;
  padding-left: 10px;
  overflow: hidden;

  div:nth-child(1) {
    font-size: 14px;
    color: rgba(153, 153, 153, 1);
    float: left;
  }

  div:nth-child(2) {
    // width: 100px;
    font-size: 14px;
    color: rgba(211, 111, 22, 1);
    float: left;
  }
}

.tip {
  margin: 10px 0 25px;
  font-size: 13px;
  color: rgba(204, 204, 204, 1);
}

.operation {
  overflow: hidden;
  position: absolute;
  bottom: 0px;

  .sudtyButton {
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    width: 96px;
    height: 32px;
    background: #ffbc1c;
    border-radius: 4px;
  }
}

.catalog {
  overflow: hidden;
  margin: 20px 0;

  .line {
    width: 6px;
    height: 14px;
    background: #0b3450;
    float: left;
  }

  .catalogTit {
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
    color: #000;
  }
}

.catalogText {
  margin-top: 20px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 24px;
  min-height: 470px;
}

.contentsTitmulu {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  line-height: 24px;
  margin: 10px 0;
}

.contents {
  overflow: hidden;
  height: 40px;
  background: #fbfbfb;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 15px 20px;

  .contentsTit {
    float: left;
    font-size: 16px;
    font-family: SourceHanSansCN;
    font-weight: 700;
    color: rgba(102, 102, 102, 1);
  }

  .btn {
    float: right;
    color: rgba(102, 102, 102, 1);
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    position: relative;
    padding-left: 20px;
    margin-left: 16px;

    img {
      position: absolute;
      top: 8px;
      left: 0;
    }
  }
}

.contentsInfo {
  div {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    line-height: 35px;
  }
}

.convideo {
  height: 34px;
  background: #fbfbfb;
  border-radius: 4px;
  line-height: 34px;
  margin-bottom: 5px;
  padding: 0 20px;
  position: relative;
  cursor: pointer;

  div:nth-child(2) {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    float: left;
  }

  div:nth-child(3) {
    float: right;
  }
}

.iconimg {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}

.matNav {
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  display: float;
  margin-top: 20px;
  overflow: hidden;
  height: 70px;
  line-height: 40px;

  div {
    float: left;
  }

  .signUp {
    position: relative;
  }

  .iconTop {
    position: absolute;
    top: 9px;
    right: -15px;

    &:hover {
      color: #0b3450;
    }
  }

  .iconBot {
    position: absolute;
    bottom: 9px;
    right: -15px;

    &:hover {
      color: #0b3450;
    }
  }

  .dataTime {
    position: relative;
    margin-right: 30px;
  }

  .search {
    width: 190px;
    height: 34px;
    margin-right: 20px;

    input {
      border: none;
      background: rgba(0, 0, 0, 0.1);
      width: 190px;
      height: 34px;
      padding-left: 10px;
    }
  }

  .searchBut {
    width: 77px;
    height: 34px;
    background: rgba(181, 14, 5, 1);
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
    line-height: 34px;
    text-align: center;
    float: right;
    margin-top: 4px;
  }
}

.uploadMat {
  overflow: hidden;

  :nth-child(1) {
    float: left;
    width: 180px;
    height: 42px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(224, 224, 224, 1);
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    line-height: 42px;
    text-align: center;
  }

  :nth-child(2) {
    float: left;
    height: 42px;
    line-height: 42px;
    color: rgba(153, 153, 153, 1);
    font-size: 12px;
    margin-left: 20px;
  }
}

.matItem {
  margin-top: 20px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;

  .itemImg {
    width: 100%;
    height: 86px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .itemTit {
    overflow: hidden;
    margin: 10px 0 20px;

    :nth-child(1) {
      float: left;
      font-size: 14px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
    }

    :nth-child(2) {
      float: right;
      font-size: 14px;
      color: rgba(102, 102, 102, 1);
      cursor: pointer;
    }
  }

  .itemInfo {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    line-height: 24px;
  }
}

.sample {
  :nth-child(1) {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 60px;
  }

  :nth-child(2) {
    margin: 0 auto;
    width: 360px;
    height: 36px;
    background: rgba(181, 14, 5, 1);
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 36px;
    text-align: center;
    margin-bottom: 40px;
  }
}

.matbox {
  margin: 0 30px;

  .matTip {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    // margin-bottom: 20px;
    margin: 0 0 20px 30px;
  }

  .userInfo {
    overflow: hidden;
    font-size: 14px;
    color: rgba(102, 102, 102, 1);

    .InfotTit {
      float: left;
      line-height: 40px;
    }

    input {
      float: left;
      width: 496px;
      height: 40px;
      border: 1px solid rgba(237, 237, 237, 1);
      padding-left: 15px;
      margin-bottom: 10px;

      &::placeholder {
        font-size: 14px;
        color: rgba(204, 204, 204, 1);
      }
    }
  }

  .message {
    overflow: 'hidden';
    height: 160px;

    :nth-child(1) {
      float: left;
      font-size: 14px;
      color: rgba(102, 102, 102, 1);
    }

    :nth-child(2) {
      float: left;
    }
  }

  .subbut {
    width: 560px;
    height: 36px;
    background: rgba(181, 14, 5, 1);
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    line-height: 36px;
    margin: 20px 0 20px;
  }
}

.message {
  font-size: 18px;
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
  line-height: 24px;
  margin: 40px 40px 200px;
}

.bgmain {
  .listNav {
    .main {
      padding-left: 60px;
      width: 1020;
    }
  }
}
</style>
<style lang="scss">
@import '../../assets/css/mattabs.scss';
</style>
