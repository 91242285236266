var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"main bgmain"},[_vm._m(0),_c('div',{staticClass:"detMain clearfix"},[_c('div',{staticClass:"grid-content bg-purple left"},[_c('div',{staticClass:"courseCover"},[_c('img',{attrs:{"src":_vm.Info.courseCoverUrl,"alt":""}})])]),_c('div',{staticClass:"grid-content bg-purple left"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.Info.name))]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_vm._v("上传人: "+_vm._s(_vm.Info.createByName))])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_vm._v("上传时间: "+_vm._s(_vm.Info.createTime))])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_vm._v(" 文件大小: "+_vm._s(_vm.Info.coursePublicityImagesFileSize)+"KB ")])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_vm._v("所属专业: "+_vm._s(_vm.Info.professionalName))])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_vm._v("资源类型: "+_vm._s(_vm.Info.resourceTypeName))])]),_c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_vm._v("资源分类: "+_vm._s(_vm.Info.classifyName))])]),_c('div',{staticClass:"operation"},[_c('div',{staticClass:"left sudtyButton",on:{"click":function($event){return _vm.viewFile(
                  _vm.Info.coursePublicityImagesUrl,
                  _vm.Info.coursePublicityImagesFileName
                )}}},[_vm._v(" 查看 ")]),_c('div',{staticClass:"left sudtyButton download",on:{"click":function($event){return _vm.down(
                  _vm.Info.coursePublicityImagesUrl,
                  _vm.Info.coursePublicityImagesFileName
                )}}},[_vm._v(" 下载 ")])])])]),_c('div',{staticClass:"detMain contont"},[_c('el-tabs',{staticClass:"cou-infor",model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"资源详情","name":"first"}},[_c('div',{staticClass:"catalog"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"catalogTit"},[_vm._v("资源详情")])]),_c('div',{staticClass:"catalogText",domProps:{"innerHTML":_vm._s(_vm.Info.content)}})])],1),_c('el-dialog',{attrs:{"title":_vm.microTitle,"visible":_vm.dialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"player"},[_c('video-player',{ref:"videoPlayer",staticClass:"video-player vjs-custom-skin",staticStyle:{"object-fit":"fill"},attrs:{"playsinline":true,"options":_vm.playerOptions,"x5-video-player-fullscreen":true,"id":"video1"},on:{"pause":function($event){return _vm.onPlayerPause($event)},"play":function($event){return _vm.onPlayerPlay($event)},"fullscreenchange":function($event){return _vm.onFullscreenChange($event)},"click":_vm.fullScreen}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("确 定")])],1)])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listNav"},[_c('div',{staticClass:"main"},[_c('i',{staticClass:"iconfont icon-shouye"}),_c('span',[_vm._v("您的位置：")]),_c('span',[_vm._v("首页")]),_c('span',[_vm._v(" > ")]),_c('span',[_vm._v("资源库")]),_c('span',[_vm._v(" > ")]),_c('span',[_vm._v("素材资源详情")])])])
}]

export { render, staticRenderFns }