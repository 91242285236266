<template>
  <div class="maincen">
    <div class="catalog">
      <div class="line"></div>
      <div class="catalogTit left">专业库建设</div>
      <div class="adddiv right" @click="addMajor">
        <img class="imgicon" src="../../assets/img/add.png" alt="" />
        新增
      </div>
    </div>
    <div class="center content">
      <el-table class="banjitable" :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="专业名称"> </el-table-column>
        <el-table-column prop="createByName" label="创建老师">
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-button
              @click="deleteInfo(scope.row)"
              class="delectebutton"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        :current-page="1"
        @current-change="handleCurrentChange"
        :page-size="par.pageSize"
        background
        layout="prev, pager, next,total"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible.sync="dialogVisibleCommont"
      class="majorDialogFirst commonDialog"
    >
      <p class="addtext">{{ edit ? '编辑专业' : '新增专业' }}</p>
      <div class="majordiv">
        <span class="majorName">专业名称:</span>
        <input class="majorInput" v-model="param.name" type="text" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-dbClick class="saveButton" @click="save">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../assets/api/axios'
import {
  majorList,
  addMajor,
  updateMajor,
  deleteMajor,
} from '@/api/center/index.js'
export default {
  data() {
    return {
      total: 0,
      par: {
        pageNum: 1,
        pageSize: 8,
        specialtyType: 0,
      },
      dialogVisibleCommont: false,
      edit: false,
      tableData: [],
      param: {
        name: '',
        specialtyType: 0,
      },
      editId: '',
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.par.pageNum = val
      this.getMajorList()
    },
    save() {
      if (this.edit) {
        this.param.id = this.editId
        updateMajor(this.param)
          .then((res) => {
            if (res.status == 0) {
              this.$message({
                type: 'success',
                message: '修改成功!',
              })
              this.dialogVisibleCommont = false
              this.getMajorList()
            }
          })
          .catch(() => {})
      } else {
        addMajor(this.param)
          .then((res) => {
            if (res.status == 0) {
              this.$message({
                type: 'success',
                message: '添加成功!',
              })
              this.dialogVisibleCommont = false
              this.getMajorList()
            }
          })
          .catch(() => {})
      }
    },
    getMajorList() {
      majorList(this.par)
        .then((res) => {
          this.total = res.page.total
          this.tableData = res.data
        })
        .catch(() => {})
    },
    deleteInfo(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteMajor([row.id]).then((res) => {
            if (res.status == 0) {
              this.getMajorList()
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    addMajor() {
      this.dialogVisibleCommont = true
      this.edit = false
      this.param.name = ''
    },
    handleClick(row) {
      this.dialogVisibleCommont = true
      this.editId = row.id
      this.param.name = row.name
      this.edit = true
    },
  },
  created() {
    this.getMajorList()
  },
}
</script>
<style lang="scss">
.majorDialogFirst .el-dialog {
  width: 480px;
  height: 228px;
  padding: 0 40px;
}
.majorDialogFirst .el-dialog__body {
  padding-left: 0;
  padding: 20px 0;
}
.majorDialogFirst .el-dialog__footer {
  padding: 0;
}
.majorDialogFirst .el-dialog__footer {
  text-align: center;
}
.saveButton {
  width: 400px;
  height: 40px;
  background: #0b3450 !important;
  color: #fff !important;
}
.banjitable {
  margin-top: 20px;
}
.banjitable .el-button--text {
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #0b3450;
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
}
.banjitable .el-table__header th,
.banjitable .el-table__header tr {
  background: #fbfbfb !important;
}
.banjitable .el-table__header thead {
  border: 1px solid #e6e6e6;
}
.banjitable .el-table__header {
  border-collapse: inherit !important;
}
.classdiv .el-table td,
.el-table th.is-leaf {
  border-bottom: 1px dashed #ebeef5;
}
.delectebutton span {
  color: #ff7500;
}
.catalog {
  overflow: hidden;
  padding: 10px 0 10px !important;
  border-bottom: 1px solid #e6e6e6;
  .line {
    width: 8px;
    height: 18px;
    background: #0b3450;
    float: left;
    margin-right: 8px;
  }
  .catalogTit {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
  }
}
.maincen {
  margin: 0 0px 0 25px !important;
  min-height: 680px !important;
  width: 810px;
}
</style>
<style lang="scss" scoped>
.majorName {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-right: 5px;
}
.majorInput {
  border: none;
  outline: none;
  width: 290px;
}
.majordiv {
  width: 360px;
  padding: 0 20px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  line-height: 42px;
  margin-top: 13px;
}
.addtext {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}
.adddiv {
  cursor: pointer;
  line-height: 18px;
}
.imgicon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.metTit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  margin: 10px 0;
}
.metuser {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: rgba(102, 102, 102, 1);
}
.imgBox {
  width: 100%;
  height: 181px;
  img {
    width: 100%;
    height: 100%;
  }
}
.contents {
  overflow: hidden;
  .contentsTit {
    margin-bottom: 20px;
    float: left;
    font-size: 14px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
  }
}
.convideo {
  height: 35px;
  overflow: hidden;
  div:nth-child(1) {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    float: left;
  }
  div:nth-child(2) {
    float: right;
  }
}
.maincen .center {
  margin-top: 5px;
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
