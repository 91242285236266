<template>
  <div class="maincen">
    <div class="coursetoptab clearfix">
      <ul class="left clearfix">
        <li
          class="left tabli commontext"
          v-for="(item, index) in tabArr"
          :key="index"
          :class="{ activeli: activeIndex == index }"
          @click="tabliClick(index)"
        >
          {{ item.text }}
        </li>
      </ul>
    </div>
    <div class="center">
      <div class="coursediv" v-show="type == 'A'">
        <div class="content">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="90px"
            label-position="left"
            class="demo-ruleForm base-ruleForm"
          >
            <el-form-item label="姓名：" prop="userName">
              <el-input
                v-model="ruleForm.userName"
                placeholder="请输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item prop="imagesUrl" label="头像：">
              <div class="head">
                <div>
                  <el-upload
                    class="avatar-uploader"
                    :action="getUrl"
                    :show-file-list="false"
                    name="file"
                    :rules="rules"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :on-progress="progress"
                    :headers="headerUrl"
                    :data="picUpload"
                    accept=".jpg, .png, .JPG, .jpeg, .JPEG, .PNG"
                  >
                    <img
                      v-if="imageUrl"
                      :src="ruleForm.imagesUrl"
                      class="avatar"
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="院系：" prop="faculty">
              <el-input
                v-model="ruleForm.faculty"
                placeholder="请输入院系"
              ></el-input>
            </el-form-item>
            <el-form-item label="专业：" prop="specialtyName">
              <el-input
                v-model="ruleForm.specialtyName"
                placeholder="请输入专业"
              ></el-input>
            </el-form-item>
            <el-form-item label="职称：" prop="jobTitle">
              <el-input
                v-model="ruleForm.jobTitle"
                placeholder="请输入职称"
              ></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱：" prop="mailbox">
              <el-input
                v-model="ruleForm.mailbox"
                placeholder="请输入电子邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item label="出生年月:">
              <el-col :span="13">
                <el-form-item prop="dateOfBirth">
                  <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    v-model="ruleForm.dateOfBirth"
                    style="width: 100%"
                    :picker-options="pickerOptions0"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
              <el-radio-group v-model="ruleForm.sex">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form>
          <div v-dbClick class="preserve" @click="submitForm('ruleForm')">
            保存
          </div>
        </div>
      </div>
      <div class="classdiv" v-show="type == 'B'">
        <div class="content">
          <el-form
            :model="changePasswordForm"
            :rules="pswRule"
            ref="changePasswordForm"
            label-width="90px"
            class="demo-ruleForm"
            label-position="left"
          >
            <el-form-item label="手机号：" prop="loginAccount">
              <el-input
                v-model="changePasswordForm.loginAccount"
                placeholder="请输入您的手机号"
              ></el-input>
              <div v-if="isShowCodeBtn" class="phonesend" @click="sendeVercode">
                发送验证码
              </div>
              <div v-else class="phonesend">{{ second }}s后重新获取</div>
            </el-form-item>
            <el-form-item label="验证码：" prop="captcha">
              <el-input
                v-model="changePasswordForm.captcha"
                placeholder="请输入验证码"
              ></el-input>
            </el-form-item>
            <el-form-item label="新密码：" prop="password">
              <el-input
                v-model="changePasswordForm.password"
                autocomplete="off"
                type="password"
                placeholder="请输入新密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="confirmPassword">
              <el-input
                v-model="changePasswordForm.confirmPassword"
                autocomplete="off"
                type="password"
                placeholder="请确认新密码"
              ></el-input>
            </el-form-item>
          </el-form>
          <div
            v-dbClick
            class="preserve"
            @click="changePasswordAction('changePasswordForm')"
          >
            保存
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { api } from '../../assets/api/axios'
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
import { mapMutations, mapState, mapGetters } from 'vuex'
import { baseUrl } from '@/config.js'
import { updateInfo, updatePassword, info } from '@/api/center/index.js'
import { sendMessage } from '@/api/common/index.js'
export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        this.isPhone = false
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        console.log(reg.test(value))
        if (reg.test(value)) {
          this.isPhone = true
          callback()
        } else {
          this.isPhone = false
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    return {
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6 //如果没有后面的-8.64e6就是不可以选择今天的
        },
      },
      timer: null,
      second: 60,
      isShowCodeBtn: true,
      getUrl: `${baseUrl}goldcourse/common/aliyun/oss/uploadFiles`,
      activeName: 'first',
      ruleForm: {
        userName: '',
        imagesUrl: '',
        imagesFileName: '',
        imagesFileSize: '',
        sex: '',
      },
      changePasswordForm: {},
      isPhone: false,
      tabArr: [{ text: '完善个人资料' }, { text: '修改密码' }],
      activeIndex: 0,
      type: 'A',
      rules: {
        userName: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
        ],
        mailbox: [
          { required: false, message: '请输入邮箱地址', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur'],
          },
        ],
        resource: [
          { required: false, message: '请选择性别', trigger: 'change' },
        ],
      },
      pswRule: {
        loginAccount: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            min: 11,
            validator: checkPhone,
            max: 11,
            message: '请输入正确到手机号',
            trigger: 'blur',
          },
        ],
        captcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
        ],
        confirmPassword: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
        ],
      },
      imageUrl: '',
      schList: [],
      options: regionData,
      headerUrl: { Authorization: window.sessionStorage.getItem('token') },
      picUpload: { serviceName: 'web' },
    }
  },
  computed: {
    ...mapGetters(['resnickname', 'resPicUrl']),
  },
  methods: {
    getinfo() {
      info().then((res) => {
        this.ruleForm = res.data
        this.$store.commit('getPicUrl', res.data.imagesUrl)
        this.$store.commit('getnickname', res.data.userName)
        if (res.data.imagesUrl) {
          this.imageUrl = true
        }
      })
    },
    handleClick(tab, event) {
      console.log(tab, event)
    },
    tabliClick(index) {
      this.activeIndex = index
      if (index == 0) {
        this.type = 'A'
      } else {
        this.type = 'B'
      }
    },
    //上传
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
      this.ruleForm.imagesUrl = res.data.url
      this.ruleForm.imagesFileName = res.data.fileName
      this.ruleForm.imagesFileSize = res.data.size
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    progress(event, file, fileList) {
      console.log(file)
    },
    saveUpdateInfo() {
      if (this.ruleForm.dateOfBirth == null) {
        this.ruleForm.dateOfBirth = ''
      }
      updateInfo(this.ruleForm).then((res) => {
        if (res.status == 0) {
          this.$message.success('更新成功')
          this.getinfo()
        }
      })
    },
    //ruleForm表单提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveUpdateInfo()
        } else {
          return false
        }
      })
    },
    //手机验证码
    async sendeVercode() {
      if (this.isPhone == true) {
        console.log('手机号=====', this.changePasswordForm.loginAccount)
        sendMessage({
          mobilephone: this.changePasswordForm.loginAccount,
          type: 2,
        }).then((res) => {
          if (res.status == 0) {
            this.$message.success(res.msg)
            this.timer = setInterval(() => {
              this.second--
              if (this.second < 0) {
                clearInterval(this.timer)
                this.timer = null
                this.second = 60
                this.isShowCodeBtn = true
              } else {
                this.isShowCodeBtn = false
              }
            }, 1000)
          }
        })
      } else {
        this.$message.error('请输入手机号')
      }
    },
    //保存确认密码
    changePasswordAction(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (
            this.changePasswordForm.password ===
            this.changePasswordForm.confirmPassword
          ) {
            let param = Object.assign({}, this.changePasswordForm)
            param.password = this.$md5(this.changePasswordForm.password)
            updatePassword(param).then((res) => {
              if (res.status == 0) {
                this.$message.success(`修改成功`)
              } else {
                this.$message.error(res.msg)
              }
            })
          } else {
            this.$message.error('两次密码不一致，请再次确认')
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
  created() {
    this.getinfo()
  },
}
</script>
<style lang="scss">
@import '../../assets/css/centertabs.scss';

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-left: 10px;
  // border-radius: 50%;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px !important;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px !important;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
.base-ruleForm .el-input__inner {
  width: 420px !important;
  height: 42px !important;
  background-color: #fff !important;
  border-radius: 0 !important;
  border: 1px solid #e6e6e6 !important;
}
.content .el-input {
  width: 420px !important;
  height: 42px !important;
}
.content .el-form-item {
  margin-top: 0 !important;
}
.el-form-item {
  margin-bottom: 10px !important;
}
.avatar-uploader .el-upload {
  margin-left: 0;
}
.el-col-13 {
  padding: 0px !important;
}
.el-form-item__label {
  padding: 0 !important;
}
.phonesend {
  right: 300px !important;
}
</style>
<style lang="scss" scoped>
.catalog {
  overflow: hidden;
  padding: 20px 0 10px;
  border-bottom: 1px solid #e6e6e6;
  .line {
    width: 4px;
    height: 20px;
    background: rgba(181, 14, 5, 1);
    float: left;
    margin-right: 8px;
  }
  .catalogTit {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
  }
}
.itemnewImg {
  margin-top: 10px;
  width: 100%;
  height: 117px;
  img {
    height: 100%;
    width: 100%;
  }
}
.addImg {
  margin-top: 10px;
  width: 185px;
  height: 117px;
  margin-bottom: 60px;
  img {
    height: 100%;
    width: 100%;
  }
}
.itemTit {
  font-size: 16px;
  color: rgba(181, 14, 5, 1);
  margin: 17px 0 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user {
  font-size: 12px;
  color: rgba(102, 102, 102, 1);
  margin-bottom: 30px;
  cursor: pointer;
  &:hover {
    color: rgba(181, 14, 5, 1);
  }
}
.preserve {
  width: 120px;
  height: 36px;
  background: #0b3450;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  line-height: 36px;
  margin: 30px 0 100px 100px;
  cursor: pointer;
}
.head {
  overflow: hidden;
  padding-left: 17px;
  :nth-child(1) {
    float: left;
    font-size: 14px;
    color: #606266;
    // margin-left: 17px;
    line-height: 80px;
    // height: 80px;
  }
  :nth-child(2) {
    float: left;
  }
}
.phonesend {
  width: 150px;
  height: 37px;
  background: rgba(252, 252, 252, 1);
  border-left: 1px solid rgba(230, 230, 230, 1);
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  text-align: center;
  line-height: 38px;
  position: absolute;
  top: 3px;
  right: 188px;
  cursor: pointer;
}
.activeli {
  color: #0b3450;
  border-bottom: 2px solid#0b3450;
}
.head {
  padding-left: 0px;
}
</style>
