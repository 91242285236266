<template>
  <div class="maincen">
    <div class="catalog">
      <div class="line"></div>
      <div class="catalogTit left">章节目录设置</div>
    </div>
    <div class="clearfix">
      <div class="adddiv right" @click="addChapter">
        <img class="imgicon" src="../../assets/img/add.png" alt="" />
        新增章节
      </div>
    </div>
    <div class="center content">
      <template>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="name" label="章节名称"> </el-table-column>
          <el-table-column prop="sort" label="排序"> </el-table-column>
          <el-table-column prop="createTime" label="创建时间">
          </el-table-column>
          <el-table-column label="操作" width="210">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >编辑</el-button
              >
              <el-button @click="addTip(scope.row)" type="text" size="small"
                >添加小节</el-button
              >
              <el-button
                @click="delectClick(scope.row)"
                class="delectebutton"
                type="text"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div class="block">
      <el-pagination
        :current-page="1"
        @current-change="handleCurrentChangeClass"
        :page-size="param.pageSize"
        background
        layout="prev, pager, next,total"
        :total="totalClass"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible.sync="dialogVisibleChapter"
      class="majorDialogFirst commonDialog dialogChapter"
    >
      <p class="addtext">{{ editChapter ? '编辑章节' : '新增章节' }}</p>
      <div class="majordiv">
        <span class="majorName">章节名称:</span>
        <input
          class="majorInput"
          v-model="addbannerlList.name"
          type="text"
          placeholder="请输入章节名称"
        />
      </div>
      <div class="majordiv">
        <span class="majorName">排序:</span>
        <input
          class="majorInput"
          v-model="addbannerlList.sort"
          type="text"
          placeholder="请输入数字"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="saveButton" @click="saveOrUpdate">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../assets/api/axios'
import {
  videoInfo,
  myTeachList,
  myTeachListSave,
  updateClass,
  deleteClass,
} from '@/api/center/teach.js'
export default {
  data() {
    return {
      dialogVisibleChapter: false,
      editChapter: false,
      questionBankId: window.sessionStorage.getItem('questionBankId'),
      dialogVisible: false,
      total: 0,
      param: {
        pageNum: 1,
        pageSize: 8,
        courseType: 2,
        objectType: 1,
        resourceId: '',
      },
      addbannerlList: {
        name: '',
        resourceId: '',
        objectType: 1,
        courseType: 2,
        sort: '',
      },
      tableData: [],
      id: '',
      totalClass: 0,
      editId: '',
    }
  },
  watch: {},
  computed: {},
  methods: {
    delectClick(row) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteClass([row.id]).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getClassData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    saveOrUpdate() {
      this.addbannerlList.resourceId = this.id
      if (!this.editChapter) {
        myTeachListSave(this.addbannerlList).then((res) => {
          this.$message({
            type: 'success',
            message: '添加成功!',
          })
          this.dialogVisibleChapter = false
          this.getClassData()
        })
      } else {
        updateClass(this.addbannerlList).then((res) => {
          this.$message({
            type: 'success',
            message: '修改成功!',
          })
          this.dialogVisibleChapter = false
          this.getClassData()
        })
      }
    },
    getClassData() {
      myTeachList(this.param).then((res) => {
        this.tableData = res.data ? res.data : []
        this.totalClass = res.page.total
      })
    },
    getvideoInfo() {
      videoInfo(this.editId).then((res) => {
        this.addbannerlList = res.data
      })
    },
    addChapter() {
      this.addbannerlList.name = ''
      this.addbannerlList.sort = ''
      this.editChapter = false
      this.dialogVisibleChapter = true
    },
    addTip(row) {
      this.$router.push({
        path: '/course/section',
        query: {
          id: row.id,
        },
      })
    },
    handleSizeChange(newSize) {},
    handleCurrentChangeClass(val) {
      this.param.pageNum = val
      this.getClassData()
    },
    handleClick(row) {
      this.dialogVisibleChapter = true
      this.editChapter = true
      this.editId = row.id
      this.getvideoInfo()
    },
  },
  created() {
    this.id = sessionStorage.getItem('courseId')
    this.param.resourceId = this.id
    this.getClassData()
  },
}
</script>
<style lang="scss">
.dialogChapter .el-dialog {
  height: 300px;
}
.saveButton {
  width: 400px;
  height: 40px;
  background: #0b3450 !important;
  color: #fff !important;
}
.banjitable {
  margin-top: 20px;
}
.banjitable .el-button--text {
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #0b3450;
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
}
.banjitable .el-table__header th,
.banjitable .el-table__header tr {
  background: #fbfbfb !important;
}
.banjitable .el-table__header thead {
  border: 1px solid #e6e6e6;
}
.banjitable .el-table__header {
  border-collapse: inherit !important;
}
.classdiv .el-table td,
.el-table th.is-leaf {
  border-bottom: 1px dashed #ebeef5;
}
.delectebutton span {
  color: #ff7500;
}
.imgicon {
  display: inline-block;
  vertical-align: middle;
  width: 18px !important;
  height: 18px !important;
  margin-right: 4px;
  margin-top: -2px;
}
.maincen {
  margin: 0 30px;
  min-height: 712px;
}
.catalog {
  overflow: hidden;
  padding: 20px 0 10px;
  border-bottom: 1px solid #e6e6e6;
  .line {
    width: 8px;
    height: 18px;
    background: #0b3450;
    float: left;
    margin-right: 8px;
  }
  .catalogTit {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
  }
}
</style>
<style lang="scss" scoped>
.majorName {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-right: 5px;
}
.majorInput {
  border: none;
  outline: none;
  width: 290px;
}
.majordiv {
  width: 360px;
  padding: 0 20px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  line-height: 42px;
  margin-top: 13px;
}
.addtext {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}
.adddiv {
  cursor: pointer;
  margin-top: 10px;
}

.metTit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  margin: 10px 0;
}
.metuser {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: rgba(102, 102, 102, 1);
}
.imgBox {
  width: 100%;
  height: 181px;
  img {
    width: 100%;
    height: 100%;
  }
}
.contents {
  overflow: hidden;
  .contentsTit {
    margin-bottom: 20px;
    float: left;
    font-size: 14px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
  }
}
.convideo {
  height: 35px;
  overflow: hidden;
  div:nth-child(1) {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    float: left;
  }
  div:nth-child(2) {
    float: right;
  }
}
</style>
