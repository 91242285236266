<template>
  <div class="maincen">
    <div class="catalog">
      <div class="line"></div>
      <div class="catalogTit left">小节目录设置</div>
      <div class="adddiv right" @click="gotoBack">返回上一页>></div>
    </div>
    <div class="clearfix">
      <div class="adddiv right" @click="addChapter">
        <img class="imgicon" src="../../assets/img/add.png" alt="" />
        新增小节
      </div>
    </div>
    <div class="center content">
      <el-table class="banjitable" :data="tableData" style="width: 100%">
        <el-table-column prop="detailsName" label="小节名称"> </el-table-column>
        <el-table-column prop="sort" label="排序"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column label="操作" width="210">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-button
              @click="delectClick(scope.row)"
              class="delectebutton"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        :current-page="1"
        @current-change="handleCurrentChangeClass"
        :page-size="param.pageSize"
        background
        layout="prev, pager, next,total"
        :total="totalClass"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible.sync="dialogVisibleChapter"
      class="majorDialogSection commonDialog"
    >
      <p class="addtext">{{ editChapter ? '编辑小节' : '新增小节' }}</p>
      <div class="majordiv">
        <span class="majorName">小节名称:</span>
        <input
          v-model="addbannerlList.detailsName"
          class="majorInput"
          type="text"
          placeholder="请输入小节名称"
        />
      </div>
      <div class="majordiv">
        <span class="majorName">排序:</span>
        <input
          v-model="addbannerlList.sort"
          class="majorInput"
          type="text"
          placeholder="请输入数字"
        />
      </div>
      <div class="majordiv">
        <span class="majorName">资源分类:</span>
        <el-select v-model="value" placeholder="请选择资源分类">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-upload
        class="upload-demo banUpload"
        :action="getUrl"
        name="file"
        :limit="1"
        accept=".mp4"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :file-list="fileListedit"
        :headers="headerUrl"
        :on-success="handleAvatarSuccessClass"
        :data="imgUploadData"
      >
        <!-- <el-button size="small" type="primary">上传课程视频</el-button> -->
        <el-button size="small" type="primary">上传</el-button>
        <div class="uploadtext">
          <p class="tiptext">点击选择一个文件到这里上传</p>
          <p class="tiptext">支持mp4,ppt,pdf...等格式，体积在500M以下</p>
        </div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="showButton" class="saveButton" @click="saveOrUpdate()"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../assets/api/axios'
import { baseUrl } from '@/config'
import {
  tipList,
  tipSave,
  tipUpdate,
  tipInfo,
  tipDelete,
} from '@/api/course/index.js'
export default {
  data() {
    return {
      dialogVisibleChapter: false,
      editChapter: false,
      dialogVisible: false,
      total: 0,
      param: {
        pageNum: 1,
        pageSize: 8,
        courseType: 1,
        resourceId: '',
        resourceSectionId: '',
        objectType: 1,
      },
      addbannerlList: {
        detailsName: '',
        knobbleVideoUrl: '',
        knobbleVideoFileName: '',
        knobbleVideoFileSize: '',
        knobbleVideoFileDuration: '',
        courseType: 1,
        objectType: 1,
        resourceId: '',
        resourceSectionId: '',
        sort: '',
      },
      fileListedit: [],
      getUrl: `${baseUrl}goldcourse/common/aliyun/oss/uploadFiles`,
      headerUrl: { Authorization: window.sessionStorage.getItem('token') },
      imgUploadData: { serviceName: 'web' },
      tableData: [],
      totalClass: 0,
      id: '',
      editId: '',
      showButton: false,
      options: [
        {
          value: '0',
          label: '教学媒体理论',
        },
        {
          value: '1',
          label: 'PPT课件',
        },
        {
          value: '2',
          label: '视频录制',
        },
      ],
      value: '',
    }
  },
  watch: {},
  computed: {},
  methods: {
    saveOrUpdate() {
      if (this.addbannerlList.knobbleVideoUrl == '') {
        this.$message({
          type: 'error',
          message: '文件还未上传,请成功后在保存',
        })
        return false
      }
      this.addbannerlList.resourceId = this.id
      this.addbannerlList.resourceSectionId = this.$route.query.id
      if (!this.editChapter) {
        tipSave(this.addbannerlList).then((res) => {
          this.$message({
            type: 'success',
            message: '添加成功!',
          })
          this.dialogVisibleChapter = false
          this.getClassData()
        })
      } else {
        tipUpdate(this.addbannerlList).then((res) => {
          this.$message({
            type: 'success',
            message: '修改成功!',
          })
          this.dialogVisibleChapter = false
          this.getClassData()
        })
      }
    },
    handleCurrentChangeClass(val) {
      this.param.pageNum = val
      this.getClassData()
    },
    getClassData() {
      tipList(this.param).then((res) => {
        this.tableData = res.data ? res.data : []
        this.totalClass = res.page.total
      })
    },
    handleRemove(file, fileList) {
      this.fileListedit = []
      this.addbannerlList.knobbleVideoUrl = ''
      this.addbannerlList.knobbleVideoFileName = ''
      this.addbannerlList.knobbleVideoFileSize = ''
      this.addbannerlList.knobbleVideoFileDuration = ''
    },
    handlePreview(file) {},
    handleAvatarSuccessClass(res, file, fileList) {
      this.showButton = true
      this.fileListedit = fileList
      this.addbannerlList.knobbleVideoUrl = res.data.url
      this.addbannerlList.knobbleVideoFileName = res.data.fileName
      this.addbannerlList.knobbleVideoFileSize = res.data.size
      this.addbannerlList.knobbleVideoFileDuration = res.data.duration
    },
    gotoBack() {
      this.$router.go(-1)
    },
    addChapter() {
      this.addbannerlList.sort = ''
      this.addbannerlList.detailsName = ''
      this.fileListedit = []
      this.editChapter = false
      this.dialogVisibleChapter = true
      this.showButton = false
    },
    delectClick(row) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          tipDelete([row.id]).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getClassData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleSizeChange(newSize) {},
    handleCurrentChangeClass(val) {
      this.param.pageNum = val
      this.getClassData()
    },
    handleClick(row) {
      this.fileListedit = []
      this.showButton = true
      this.dialogVisibleChapter = true
      this.editChapter = true
      this.editId = row.id
      this.getvideoInfo()
    },
    getvideoInfo() {
      tipInfo(this.editId).then((res) => {
        this.addbannerlList = res.data
        this.fileListedit.push({ url: res.data.knobbleVideoUrl })
      })
    },
  },
  created() {
    this.id = sessionStorage.getItem('courseId')
    this.param.resourceId = this.id
    this.param.resourceSectionId = this.$route.query.id
    this.getClassData()
  },
}
</script>
<style lang="scss">
.majorDialogSection .el-input__inner {
  border: none;
}
.banUpload {
  margin-top: 20px;
}
.tiptext {
  font-size: 12px;
  line-height: 18px;
  text-align: left;
}
.uploadtext {
  display: inline-block;
  width: 257px;
  vertical-align: top;
  margin-left: 5px;
  margin-top: 2px;
}
.majorDialogSection .el-dialog {
  width: 480px;
  height: 365px;
  padding: 0 50px;
}
.majorDialogSection .el-form-item__label {
  text-align: left;
}
.majorDialogSection .el-dialog__body {
  padding-left: 0;
  padding: 20px 0;
}
.majorDialogSection .el-dialog__footer {
  padding: 0;
}
.majorDialogSection .el-dialog__footer {
  text-align: center;
}
.saveButton {
  width: 400px;
  height: 40px;
  background: #0b3450 !important;
  color: #fff !important;
}
.banjitable {
  margin-top: 20px;
}
.banjitable .el-button--text {
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #0b3450;
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
}
.banjitable .el-table__header th,
.banjitable .el-table__header tr {
  background: #fbfbfb !important;
}
.banjitable .el-table__header thead {
  border: 1px solid #e6e6e6;
}
.banjitable .el-table__header {
  border-collapse: inherit !important;
}
.classdiv .el-table td,
.el-table th.is-leaf {
  border-bottom: 1px dashed #ebeef5;
}
.delectebutton span {
  color: #ff7500;
}
.banjitable {
  margin-top: 0px !important;
}
</style>
<style lang="scss" scoped>
.majorName {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-right: 5px;
}
.majorInput {
  border: none;
  outline: none;
  width: 290px;
}
.majordiv {
  width: 360px;
  padding: 0 20px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  line-height: 42px;
  margin-top: 13px;
}
.addtext {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}
.adddiv {
  cursor: pointer;
  margin-top: 10px;
}
.imgicon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.metTit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  margin: 10px 0;
}
.metuser {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: rgba(102, 102, 102, 1);
}
.imgBox {
  width: 100%;
  height: 181px;
  img {
    width: 100%;
    height: 100%;
  }
}
.contents {
  overflow: hidden;
  .contentsTit {
    margin-bottom: 20px;
    float: left;
    font-size: 14px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
  }
}
.convideo {
  height: 35px;
  overflow: hidden;
  div:nth-child(1) {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    float: left;
  }
  div:nth-child(2) {
    float: right;
  }
}
</style>
