import axios from 'axios'
import { Message } from "element-ui";
import { baseUrl } from "@/config";
const baseURL = process.env.NODE_ENV === "production" ? "http://118.190.159.5:9002/" : baseUrl
const service = axios.create({
  baseURL: baseURL,
  timeout: 10000,
});
// 添加请求拦截器
service.interceptors.request.use(
  //在请求之前做点什么 比如加入token
  config => {
    config.headers.Authorization = window.localStorage.getItem('token')
    return config
  },
  err => {
    return Promise.reject(err);
  }
);

// 添加响应拦截器
service.interceptors.response.use(
  response => {
    if (response.data.status != 0) {
      if (response.data.status == 100403) {
        Message({
          message: "请先登录",
          type: "error"
        });
      }
      return Promise.reject(response.data.msg);
    }
    if (response.data.status == 0) {
      return response.data;
    }
  },
  err => {
    return Promise.reject(err);
  }
);
export default service;
