import service from '@/utils/request.js'
export function clasList(data) {
  return service.request({
    method: 'get',
    url: '/goldcourse/web/grademangerinfo/list',
    params: data
  });
}
export function classSaveList(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/grademangerinfo/save',
    data
  });
}
export function updateSaveList(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/grademangerinfo/update',
    data
  });
}

export function myTeachListSave(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/onlinelearningresource/save',
    data
  });
}

export function myTeachList(data) {
  return service.request({
    method: 'get',
    url: '/goldcourse/web/onlinelearningresource/list',
    params: data
  });
}


export function banInfo(id) {
  return service.request({
    method: 'get',
    url: `/goldcourse/web/grademangerinfo/info/${id}`,
  });
}

export function videoInfo(id) {
  return service.request({
    method: 'get',
    url: `/goldcourse/web/onlinelearningresource/info/${id}`,
  });
}

export function deleteBan(data) {
  return service.request({
    method: 'post',
    url: `/goldcourse/web/grademangerinfo/delete`,
    data
  });
}

export function deleteClass(data) {
  return service.request({
    method: 'post',
    url: `/goldcourse/web/onlinelearningresource/delete`,
    data
  });
}
export function updateClass(data) {
  return service.request({
    method: 'post',
    url: `/goldcourse/web/onlinelearningresource/update`,
    data
  });
}


