<template>
  <div>
    <div class="banner">
      <img src="../../src/assets/img/conlocation.png" alt />
    </div>
    <div class="bg">
      <div class="main">
        <div class="conmain">
          <div class="name">郑州梧桐花科技有限公司</div>
          <div class="info">由梧桐花教育平台推出，让每一个有提升愿望的用户能够学到中国知名高校的课程，并获得专业机构认证。 如果您有任何需求，欢迎与我们联系。</div>
          <div class="mode">联系方式1：156388816</div>
          <div class="mode">联系方式2：156388816</div>
          <div class="mode">邮箱地址：156388816@qq.com</div>
          <div class="mode">公司地址：郑州市高新技术产业开发区冬青街26号河南电子商务产业园</div>
          <div style="height:20px"></div>
          <div class="userInfo">
            <div>
              姓 名：
              <input type="text" placeholder="请输入您的姓名" />
            </div>
          </div>
          <div class="userInfo">
            <div>
              电 话：
              <input type="text" placeholder="请输入您的电话" />
            </div>
          </div>
          <div class="message">
            <!-- <div>
            留言：
            <input type="textarea" placeholder="请输入您的留言，我们会尽快给您联系的" />
            </div>-->
            <div>留 言：</div>
            <div>
              <el-input type="textarea" placeholder="请输入内容" v-model="textarea" class="mesInp"></el-input>
            </div>
          </div>
          <div class="sentBtu">提交</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      textarea: ''
    }
  }
}
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 182px;
  img {
    width: 100%;
    height: 100%;
  }
}
.bg {
  background-image: url('../../src/assets/img/matbg.png');
}
.main {
  background-color: #fff;
  .conmain {
    padding-top: 60px;
    margin-left: 60px;
    height: 800px;
    .name {
      font-size: 21px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: rgba(8, 1, 3, 1);
      margin-bottom: 18px;
    }
    .info {
      width: 649px;
      height: 42px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(153, 153, 153, 1);
      line-height: 28px;
      margin-bottom: 25px;
    }
    .mode {
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      line-height: 36px;
    }
  }
}
.userInfo {
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  input {
    width: 480px;
    height: 40px;
    border: 1px solid rgba(237, 237, 237, 1);
    padding-left: 15px;
    margin-bottom: 10px;
    &::placeholder {
      font-size: 14px;
      color: rgba(204, 204, 204, 1);
    }
  }
}
.message {
  overflow: 'hidden';
  height: 160px;
  :nth-child(1) {
    float: left;
    font-size: 14px;
    color: rgba(102, 102, 102, 1);
  }
  :nth-child(2) {
    float: left;
  }
}
.sentBtu {
  width: 100px;
  height: 36px;
  background: rgba(181, 14, 5, 1);
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  line-height: 36px;
  text-align: center;
  margin-left: 45px;
}
</style>
<style lang="scss">
.message {
  .el-textarea {
    width: 496px;
    border-radius: 0;
  }
  .el-textarea__inner {
    height: 126px !important;
  }
}
</style>