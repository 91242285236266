<template>
  <div class="headerBg">
    <div class="textheader">
      <div class="main">
        <div class="first">欢迎访问金课在线~</div>
        <div class="second">
          <div class="verification" @click="showFen" v-if="showFenLogin">
            身份验证
          </div>
          <div class="wxcodeimg">
            <img class="phone" src="../../assets/img/phone.png" />
            <span class="imgtext">小程序</span>
            <div class="wxcode">
              <img
                class="barcode"
                src="../../assets/img/qrcodeLogo.jpg"
                alt=""
              />
              <p class="scan">扫一扫</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mainHeader">
      <img class="first" src="../../assets/img/logo.png" />
      <el-menu
        style="margin-top: 5px"
        :default-active="this.$route.path"
        :router="true"
        active-text-color="#0B3450"
        mode="horizontal"
      >
        <el-menu-item index="/index" :style="menuStyle">首页</el-menu-item>
        <el-menu-item index="/onLine" :style="menuStyle">在线学习</el-menu-item>
        <el-menu-item index="/resource" :style="menuStyle">资源库</el-menu-item>
      </el-menu>
      <div class="user" v-show="loginBut">
        <div
          @click="logindialogVisible = true"
          class="loginButton buttonCommon"
        >
          登录
        </div>
        <div
          @click="registerdialogVisible = true"
          class="registerButton buttonCommon"
        >
          注册
        </div>
      </div>
      <div class="touxiang" v-show="userInfo">
        <div class="touxiangPic">
          <img v-if="resPicUrl" :src="resPicUrl" alt />
          <img v-else src="../../assets/touxiang1.jpg" alt="" />
        </div>
        <div class="loginName">
          <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link" v-if="resnickname">{{
              resnickname
            }}</span>
            <span v-else class="el-dropdown-link">教师职称</span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="spacePer">个人中心</el-dropdown-item>
              <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- login -->
    <el-dialog
      title
      :visible.sync="logindialogVisible"
      width="550px"
      :before-close="handleClose"
      class="commonDialog"
    >
      <div class="login">
        <div class="loginTit">
          <div></div>
          <div>账号密码登录</div>
          <div></div>
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="loginruleForm"
          label-width="100px"
          class="demo-ruleForm loginForm"
        >
          <el-form-item label="用户名:" prop="username">
            <el-input
              v-model="ruleForm.username"
              placeholder="请输入用户名"
            ></el-input>
          </el-form-item>
          <el-form-item label="密   码:" prop="password">
            <el-input
              placeholder="请输入密码"
              v-model="ruleForm.password"
              type="password"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="loginBut" @click="submitForm('loginruleForm')">
          立即登录
        </div>

        <div class="loginTip">
          <span>没有账号？</span>
          <span @click="register()">立即注册</span>
          <span
            @click=";(logindialogVisible = false), (phonedialogVisible = true)"
            >手机验证登录</span
          >
        </div>
      </div>
    </el-dialog>
    <!-- /login -->

    <!-- login -->
    <el-dialog
      title
      :visible.sync="shenfen"
      width="550px"
      :before-close="handleClose"
      class="commonDialog"
    >
      <div class="login">
        <div class="loginTit">
          <div></div>
          <div>身份验证</div>
          <div></div>
        </div>
        <el-form
          :model="ruleFormFen"
          :rules="rules"
          ref="loginruleForm"
          label-width="100px"
          class="demo-ruleForm loginForm"
        >
          <el-form-item label="选择身份:" prop="account">
            <el-select
              v-model="ruleFormFen.userIdentityType"
              placeholder="请选择身份"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="邀请码:" prop="invitationCode">
            <el-input
              placeholder="请输入邀请码"
              v-model="ruleFormFen.invitationCode"
              type="password"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="loginBut" @click="submitFormFen('loginruleForm')">登录</div>
      </div>
    </el-dialog>

    <el-dialog
      title
      :visible.sync="registerdialogVisible"
      width="550px"
      :before-close="handleClose"
      class="commonDialog"
    >
      <div class="login">
        <div class="loginTit">
          <div></div>
          <div>账号注册</div>
          <div></div>
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm registeForm"
        >
          <el-form-item label="手机号:" prop="loginAccount">
            <el-input
              v-model="ruleForm.loginAccount"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码:" prop="captcha">
            <el-input
              class="yanzhengma"
              v-model="ruleForm.captcha"
              placeholder="请输入验证码"
            ></el-input>
            <div class="phonesend" @click="getcode()" v-show="showcode">
              发送验证码
            </div>
            <div class="phonesend" @click="getcode()" v-show="!showcode">
              {{ count }}s
            </div>
          </el-form-item>
          <el-form-item label="密码:" prop="password">
            <el-input
              type="password"
              v-model="ruleForm.password"
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="loginBut" @click="registerInfo('ruleForm')">立即注册</div>
        <div class="loginTip">
          <span>已有账号，</span>
          <span
            @click="
              ;(registerdialogVisible = false), (logindialogVisible = true)
            "
            >立即登录</span
          >
        </div>
      </div>
    </el-dialog>
    <!-- 手机验证登录 -->
    <el-dialog
      title
      :visible.sync="phonedialogVisible"
      width="550px"
      :before-close="handleClose"
      class="commonDialog"
    >
      <div class="login">
        <div class="loginTit">
          <div></div>
          <div>{{ phoneTit }}</div>
          <div></div>
        </div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="手机号:" prop="account">
            <el-input
              v-model="ruleForm.loginAccount"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码:" prop="code">
            <el-input
              v-model="ruleForm.captcha"
              class="yanzhengma"
              placeholder="请输入验证码"
            ></el-input>
            <div class="phonesend" @click="getcode()" v-show="showcode">
              发送验证码
            </div>
            <div class="phonesend" v-show="!showcode">{{ count }}s</div>
          </el-form-item>
        </el-form>
        <div class="loginBut" @click="phoneInfo('ruleForm')">立即登录</div>
        <!-- <div class="loginTip">
          <span>已有账号，</span>
          <span @click="registerdialogVisible= false ,logindialogVisible = true">立即登录</span>
        </div>-->
      </div>
    </el-dialog>
    <!-- /手机验证登录 -->

    <el-dialog
      title
      :visible.sync="showSuccess"
      width="480px"
      :before-close="handleClose"
    >
      <div class="detail-news">
        <h5 class="detail-news-title"><span>验证成功</span></h5>
      </div>
      <img class="fenimg" src="../../assets/img/success.png" alt="" />
      <p class="feneText">验证成功，立即去完善信息吧~</p>
      <div class="buttonFen" @click="toCenter">完善信息</div>
    </el-dialog>
    <!-- wechat -->
    <el-dialog
      title
      :visible.sync="wechatdialogVisible"
      width="550px"
      :before-close="handleClose"
    >
      <div class="login">
        <!-- <div class="loginTit">
          <div></div>
          <div>微信登录</div>
          <div></div>
        </div>-->

        <div class="qrImg">
          <!-- <div id="qrcode"></div> -->
          <wxlogin
            appid="wx1ccaa289531eec16"
            scope="snsapi_login"
            theme="black"
            redirect_uri="https%3A%2F%2Fwthedu.51suiyixue.com"
            href
            state="STATE#wechat_redirect"
          ></wxlogin>
        </div>
        <div class="wechatTip"></div>
        <div class="line"></div>
        <div class="loginTip">
          <span>没有账号？</span>
          <span @click="register()">立即注册</span>
          <span
            @click=";(wechatdialogVisible = false), (logindialogVisible = true)"
            >账号登录>></span
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../assets/api/axios'
import { mapMutations, mapState, mapGetters } from 'vuex'
import wxlogin from 'vue-wxlogin'
const getUrlParam = (name) => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  let r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return unescape(r[2])
  } else {
    return null
  }
}
export default {
  components: { wxlogin },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1\d{10}$/
        console.log(reg.test(value))
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      shenfen: false,
      showSuccess: false,
      showFenLogin: false,
      options: [
        {
          value: '1',
          label: '老师',
        },
        {
          value: '2',
          label: '学生',
        },
      ],
      value: '',
      menuStyle: {
        // 'border-bottom-color': 'white',
      },
      logindialogVisible: false,
      ruleForm: {
        loginAccount: '',
        password: '',
        pass: '',
        checkPass: '',
        captcha: '',
        username: '',
      },
      ruleFormFen: {
        userIdentityType: '',
        invitationCode: '',
      },
      rules: {
        loginAccount: [
          { required: true, validator: checkPhone, trigger: 'blur' },
        ],
        username: [{ required: true, validator: checkPhone, trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 6, max: 6, message: '长度在 6个数字', trigger: 'blur' },
        ],
        pass: [{ required: true, validator: validatePass, trigger: 'blur' }],
        checkPass: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
      },
      registerdialogVisible: false,
      wechatdialogVisible: false,
      loginBut: true,
      userInfo: false,
      nickname: '',
      count: '',
      showcode: true,
      showcodes: false,
      timer: null,
      imgUrl: require('../../assets/img/touxiang.jpg'),
      phonedialogVisible: false,
      phoneTit: '手机号登录',
    }
  },
  computed: {
    ...mapGetters(['resnickname', 'resPicUrl']),
  },
  watch: {
    resPhone: function () {
      console.log('resPhone', this.resPhone)
    },
  },
  created() {
    this.iftoken()
    this.getwxCode()
  },
  watch: {},
  mounted() {},
  methods: {
    toCenter() {
      this.showSuccess = false
      this.$router.push({ path: '/center' })
    },
    showFen() {
      this.shenfen = true
    },
    async loginOutApi() {
      let res = await api.get('/logout')
      if (res.status === 0) {
        window.localStorage.clear()
        this.$router.push('/')
        this.$router.go(0)
      } else {
        this.$message.error(res.msg)
      }
    },
    handleCommand(command) {
      if (command == 'spacePer') {
        this.$router.push({ path: '/center' })
      } else if (command == 'loginOut') {
        this.loginOutApi()
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    wxgetCode() {
      this.registerdialogVisible = false
      this.logindialogVisible = true
    },
    //表单
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await api.post('login', {
            username: this.ruleForm.username,
            password: this.$md5(this.ruleForm.password),
            captcha: 1,
            rememberMe: false,
          })
          if (res.status == 0) {
            this.$message.success('登录成功')
            if (!res.data.schoolId) {
              this.shenfen = true
            } else {
              // location.reload()
            }
            this.user(res)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    submitFormFen(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if(this.ruleFormFen.userIdentityType&&this.ruleFormFen.invitationCode){
            let res = await api.post('authentication', this.ruleFormFen)
            this.$message.success('验证成功')
            this.showSuccess = true
            this.shenfen = false
            this.user(res)
          }else{
            this.shenfen = false
            location.reload()
          }
         
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async user(res) {
      if (res.status == 0) {
        this.nickname = res.data.accountName
        this.loginBut = false
        this.userInfo = true
        this.logindialogVisible = false
        this.$store.commit('getPicUrl', res.data.imagesUrl)
        this.$store.commit('getnickname', res.data.name)
        this.$store.commit('getPhone', res.data.accountName)
        this.$store.commit('getschType', res.data.schoolId)
        this.$store.commit('getinvitationCode', res.data.invitationCode)
        localStorage.setItem('schoolId', res.data.schoolId)
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('userId', res.data.id)
        localStorage.setItem('userIdentityType', res.data.userIdentityType)
      } else {
        this.$message.error(res.msg)
      }
    },
    iftoken() {
      let token = window.localStorage.getItem('token')
      let schoolId = window.localStorage.getItem('schoolId')
      if (token != null) {
        this.showFenLogin = true
        this.loginBut = false
        this.userInfo = true
      } else {
        this.loginBut = true
        this.userInfo = false
      }
      if (schoolId != 0 && token) {
        this.showFenLogin = false
      }
    },
    registerInfo(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await api.post('/userRegister', {
            loginAccount: this.ruleForm.loginAccount,
            captcha: this.ruleForm.captcha,
            password: this.$md5(this.ruleForm.password),
          })
          if (res.status === 0) {
            this.$message.success('注册成功')
            this.user(res)
            this.registerdialogVisible = false
            this.shenfen = true
          } else {
            this.$message.error(res.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    phoneInfo(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await api.post(
            `/messageAuthCodeLogin?mobilephone=${this.ruleForm.loginAccount}&messageAuthCode=${this.ruleForm.captcha}`
          )
          if (res.status === 0) {
            this.$message.success('注册成功')
            this.phonedialogVisible = false
            this.shenfen = true
            this.user(res)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          return false
        }
      })
    },
    async getcode() {
      const reg = /^\d{11}$/
      if (this.ruleForm.loginAccount == '') {
        this.$message({
          message: '手机号不能为空',
          center: true,
          type: 'error',
        })
        return
      }
      if (!reg.test(this.ruleForm.loginAccount)) {
        this.$message({
          message: '请输入正确的手机号',
          center: true,
          type: 'error',
        })
        return
      }
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.showcode = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.showcode = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
      let res = await api.post(
        `/goldcourse/common/aliyun/sms/sendMessage?mobilephone=${this.ruleForm.loginAccount}&type=1`
      )
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    register() {
      this.logindialogVisible = false
      this.registerdialogVisible = true
    },
    wxlogin() {
      this.logindialogVisible = false
      this.wechatdialogVisible = true
      console.log(1)
    },
    getwxCode() {
      let code = getUrlParam('code') //是否存在code
      // alert(code)
      if (code == null || code === '') {
        return
      } else {
        this.sendcode(code)
      }
    },
    async sendcode(code) {
      let res = await api.post('/front/wechatScanLoginController/callBack.do', {
        code: code,
      })
      // console.log('code', res)
      if (res.data.user.phone == null) {
        this.$message.warning('请绑定手机号码')
        setTimeout(() => {
          this.phoneTit = '绑定手机号'
          this.phonedialogVisible = true
          this.wechatdialogVisible = false
        }, 1000)
        window.sessionStorage.setItem('userId', res.data.user.fid)
      } else {
        this.user(res)
      }
    },
  },
}
</script>>
<style>
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 4px solid #0b3450 !important;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 4px solid #0b3450 !important;
}
.registeForm .el-form-item__label {
  text-align: left;
}
</style>
<style lang="scss" scoped>
.acss {
  font-size: 14px;
  text-decoration: none;
}
.buttonFen {
  margin: 0 auto;
  margin-top: 40px;
  width: 96px;
  height: 32px;
  background: #3699ff;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  line-height: 32px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}
.fenimg {
  margin: 0 auto;
  width: 48px;
  height: 48px;
  display: block;
  margin-top: 48px;
  margin-bottom: 20px;
}
.feneText {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
.detail-news-title {
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  padding-top: 10px;
  position: relative;
}
.detail-news-title::before {
  content: '';
  display: block;
  width: 40px;
  height: 1px;
  background: #ccc;
  position: absolute;
  left: 200px;
  margin-left: -80px;
  top: 18px;
}
.detail-news-title::after {
  content: '';
  display: block;
  width: 40px;
  height: 1px;
  background: #ccc;
  position: absolute;
  margin-left: 80px;
  right: 118px;
  top: 18px;
}
.detail-news-title span {
  padding: 0 10px;
  background: #fff;
  position: relative;
  z-index: 2;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
}
.verification {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #666666;
  margin-right: 40px;
  cursor: pointer;
}
.login .yanzhengma {
  width: 150px !important;
  height: 40px;
}
.loginButton {
  background: #0b3450;
  color: #ffffff;
}
.buttonCommon {
  border-radius: 4px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  width: 70px;
  height: 30px;
  text-align: center;
}
.registerButton {
  border: 1px solid #0b3450;
  margin-left: 10px;
  color: #0b3450;
  width: 68px;
  height: 28px;
}
.headerBg .mainHeader .user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 13px;
  float: right;
  color: #333;
}
.phone {
  width: 10px;
  height: 17px;
  vertical-align: middle;
  display: inline-block;
  margin-right: 5px;
}
.imgtext,
.joinadmin {
  display: inline-block;
  color: #999;
  font-size: 12px;
  line-height: 26px;
  font-family: Source Han Sans CN;
  font-weight: 400;
}
.mainHeader {
  width: 1080px;
  margin: 0 auto;
  color: #111111;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wxcode {
  position: absolute;
  left: -55px;
  top: 45px;
  width: 122px;
  height: 143px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.21);
  display: none;
  z-index: 100;
  .barcode {
    width: 106px;
    height: 106px;
    margin: 8px;
  }
}
.scan {
  text-align: center;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.wxcodeimg:hover .wxcode {
  display: block;
}
.wxcodeimg {
  position: relative;
}
.active {
  background: rgba(0, 0, 0, 0.2);
}
// .el-menu--horizontal > .el-menu-item.is-active {
//   border-bottom: 2px solid #ffffff;
// }
// .el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
//   border-bottom: 2px solid #ffffff !important;
// }
// .el-submenu.is-active .el-submenu__title {
//   border-bottom-color: white;
// }
.headerBg {
  width: 100%;
  background: #fff;
  filter: progid:DXImageTransform.Microsoft.Shadow(color=#909090,direction=120,strength=4);
  -moz-box-shadow: 2px 2px 10px #f1f1f1;
  -webkit-box-shadow: 2px 2px 10px #f1f1f1;
  box-shadow: 1px 1px 10px #f1f1f1;
  .main {
    color: #111111;
    font-size: 13px;
    .first {
    }
    .user {
      margin-top: 5px;
      margin-left: 550px;
      display: flex;
      width: 73px;
      font-size: 13px;
      color: rgba(51, 51, 51, 1);
      :nth-child(1) {
        margin-right: 8px;
      }
      :nth-child(2) {
        margin-right: 8px;
      }
    }
  }
}
.textheader {
  width: 100%;
  height: 26px;
  color: #2f3a4c;
  border-top: 4px solid #0b3450;
  background: -moz-linear-gradient(top, #e6e6e6 0%, #ffffff 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #e6e6e6),
    color-stop(100%, #ffffff)
  );
  background: -webkit-linear-gradient(top, #e6e6e6 0%, #ffffff 100%);
  background: -o-linear-gradient(top, #e6e6e6 0%, #ffffff 100%);
  background: -ms-linear-gradient(top, #e6e6e6 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #e6e6e6 0%, #ffffff 100%);
}
.textheader .first {
  color: #2f3a4c;
  float: left;
  margin-top: 7px;
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
}
.textheader .second {
  float: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.touxiang {
  float: right;
  overflow: hidden;
  margin-top: 20px;
  height: 38px;

  .touxiangPic {
    float: left;
    margin-right: 10px;
    height: 38px;
    width: 38px;
    overflow: hidden;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .loginName {
    float: left;
    height: 38px;
    line-height: 42px;
    color: #222222;
    font-size: 14px;
    max-width: 80px;
    overflow: hidden;
    cursor: pointer;
  }
}
.login {
  margin: 0 70px 0px;
  width: 365px;
  .loginTit {
    overflow: hidden;
    margin: 0 68px 40px;
    text-align: center;
    :nth-child(1) {
      width: 40px;
      height: 1px;
      background: rgba(204, 204, 204, 1);
      float: left;
      margin-top: 10px;
    }
    :nth-child(2) {
      font-size: 18px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      float: left;
      margin: 0 20px;
    }
    :nth-child(3) {
      width: 40px;
      height: 1px;
      background: rgba(204, 204, 204, 1);
      float: left;
      margin-top: 10px;
    }
  }
  .loginBut {
    width: 360px;
    height: 42px;
    background: #0b3450;
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    line-height: 42px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
    cursor: pointer;
  }
  .loginTip {
    margin: 20px 0 0;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    span:nth-child(2) {
      font-size: 14px;
      color: rgba(181, 14, 5, 1);
      cursor: pointer;
    }
    span:nth-child(3) {
      margin-left: 160px;
      font-size: 14px;
      color: rgba(153, 153, 153, 1);
    }
  }
  .line {
    width: 360px;
    height: 1px;
    background: rgba(238, 238, 238, 1);
    margin: 70px 0 30px;
  }
  .wechat {
    margin: 0 auto;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    text-align: center;
    div {
      margin-bottom: 30px;
    }
    img {
      width: 26px;
      height: 26px;
      margin-bottom: 30px;
    }
  }
  .phonesend {
    width: 130px;
    height: 40px;
    background: rgba(246, 246, 246, 1);
    // border-left: 1px solid rgba(230, 230, 230, 1);
    font-size: 14px;
    color: rgba(102, 102, 102, 1);
    text-align: center;
    line-height: 38px;
    position: absolute;
    top: 0;
    right: 0 !important;
    cursor: pointer;
  }
}
.qrImg {
  width: 282px;
  height: 339px;
  // background-color: pink;
  margin-left: 40px;
}
.wechatTip {
  text-align: center;
  margin: 20px 0 30px;
  // width: 50px;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
}
</style>
<style lang="scss">
.login {
  .el-input {
    width: 290px !important;
  }
  .el-form-item__label {
    width: 73px !important;
    text-align: left;
  }
  .el-form-item__content {
    margin-left: 0 !important;
  }
}
</style>
<style>
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 4px solid #00427f !important;
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 4px solid #00427f !important;
}
.loginForm .el-input__inner {
}
</style>
