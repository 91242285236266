<template>
  <div class="home">
    <Header></Header>
    <!-- <navbar></navbar> -->
    <router-view class="detBg"></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Header from '@/components/home/header.vue'
import Navbar from '@/components/home/navbar.vue'
import Footer from '@/components/home/footer.vue'

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
  },
}
</script>
<style lang="scss" scoped>
.detBg {
  background-size: 100% 100%;
  // background-color: rgb(245, 245, 245);
}
</style>