<template>
  <div>
      <div class="banner">
        <div class="item" >
            <img class="coverLogo" src="../../../assets/img/cover-logo.png">
            <div class="content">
            <div class="majorTitle">护理专业</div>
            <div class="intro">工作是整个医疗卫生工作的重要组成部分，但它又是有其自身的相对独立性和特殊性，护理人员的道德水平如何，关系到能否协调医生、护士、病人三者的关系，直接影响着医疗质量。护理工作的质量直接关系到患者的医疗安全、治疗效果和身体康复；护士的职业素质、服务态度、言谈举止也直接影响着患者的心理感受和医患关系的和谐、融洽。工作是整个医疗卫生工作的重要组成部分，但它又是有其自身的相对独立性和特殊性，护理人员的道德水平如何，关系到能否协调医生、护士、病人三者的关系，直接影响着医疗质量。护理工作的质量直接关系到患者的医疗安全、治疗效果和身体康复；护士的职业素质、服务态度、言谈举止也直接影响着患者的心理感受和医患关系的和谐、融洽。工作是整个医疗卫生工作的重要组成部分，但它又是有其自身的相对独立性和特殊性，护理人员的道德水平如何，关系到能否协调医生、护士、病人三者的关系，直接影响着医疗质量。护理工作的质量直接关系到患者的医疗安全、治疗效果和身体康复；护士的职业素质、服务态度、言谈举止也直接影响着患者的心理感受和医患关系的和谐、融洽。</div>
        </div>
      </div>
      </div>
      <div class="main">
          <el-row class="tac">
            <el-col class="tac-nav" :span="4">
                <div class="title">教学资源</div>
                <el-menu
                :default-active="selectedActive"
                class="el-menu-vertical-demo"
                :router="true">
                <el-menu-item index="/major/detail/micCourse">
                    <div class="mune-mask">
                        <div v-if="selectedActive === '/major/detail/micCourse'?true:false" class="titleLine"></div>
                        <span slot="title">微课程</span>
                    </div>
                </el-menu-item>
                <el-menu-item index="/major/detail/material">
                    <div class="mune-mask">
                        <div v-if="selectedActive === '/major/detail/material'?true:false" class="titleLine"></div>
                        <span slot="title">配套素材资源</span>
                    </div>
                </el-menu-item>
                </el-menu>
            </el-col>
            <el-col :span="20">
                <router-view style="margin-left:30px;margin-right:30px" :style="defSty"></router-view>
            </el-col>
        </el-row>
      </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            selectedActive:this.$route.path,
            
            defSty:{
                'minHeight':'440px'
            }
        }
    },
    watch:{
        $route(to,from){
            this.selectedActive = to.path;
        }
    }
}
</script>

<style lang="scss" scoped>
.banner{
    width: 100%;
    height: 180px;
    background-color: white;
}
.main{
    margin-top: 10px;
}
.tac{
    padding-top: 0px;
    .tac-nav{
        background-color: white;
    }
}
.title{
    line-height: 56px;
    font-size: 16px;
    color: #333333;
    padding-left: 20px;
}
.item{
height: 160px;
width:1200px;
margin: 0 auto;
display: flex;
.coverLogo{
    margin-top: 22px;
    width: 188px;
    height: 116px;
}
.content{
    width: 100%;
    height: 100%;
    margin-top: 22px;
    margin-left: 24px;
    .majorTitle{
        font-size: 20px;
        color: #333333;
    }
    .intro{
    margin-top: 20px;
    margin-bottom: 22px;
    font-size: 13px;
    color: #666666;
    line-height: 24px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:4;
    overflow: hidden;
    white-space:pre-wrap;
    }
}
}
.el-menu-item{
    height: 50px;
    line-height: 50px; 
}
.mune-mask{
    display: flex;
    align-items: center;
    border-bottom: #E8E8E8 .8px solid;
    .titleLine{
        margin-left: -20px;
        height: 50px;
        width: 3px;
        background-color: #0F87FE;
        margin-right: 20px;
    }
    .title{
        line-height: 50px;
        font-size: 16px;
        color: #333333;
    }
}

</style>