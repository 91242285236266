<template>
  <div class="courseBase">
    <div class="catalog">
      <div class="line"></div>
      <div class="catalogTit">课程基础信息</div>
    </div>
    <div class="fromdiv">
      <el-form
        ref="addSchoolListref"
        :model="addbannerlList"
        label-width="80px"
      >
        <el-form-item label="课程名称:">
          <el-input
            v-model="addbannerlList.name"
            placeholder="请输入课程名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程图片:">
          <el-upload
            class="upload-demo"
            :action="getUrl"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="fileList"
            :headers="headerUrl"
            :on-success="handleAvatarSuccessClass"
            :data="imgUploadData"
            list-type="picture"
            accept=".jpg, .png, .JPG, .jpeg, .JPEG, .PNG, .gif"
            name="file"
          >
            <el-button size="small" type="primary">上传课程宣传图片</el-button>
            <div class="uploadtext">
              <p class="tiptext">点击选择一个文件到这里上传</p>
              <p class="tiptext">支持png、jpg、JPEG...等格式，体积在2M以下</p>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="所属专业:" prop="professionalId">
          <el-select
            v-model="addbannerlList.professionalId"
            placeholder="请选择所属专业"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in bannerType"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="宣传视频:">
          <el-upload
            class="upload-demo"
            :action="getUrl"
            :on-preview="handlePreview"
            :on-remove="handleRemoveFile"
            multiple
            :limit="1"
            :on-exceed="handleExceed"
            :file-list="fileListeditFile"
            :headers="headerUrl"
            :on-success="handleAvatarSuccessfile"
            :data="imgUploadData"
            accept=".mp4"
            name="file"
          >
            <el-button size="small" type="primary">上传课程宣传视频</el-button>
            <div class="uploadtext">
              <p class="tiptext">点击选择一个文件到这里上传</p>
              <p class="tiptext">支持mp4...等格式，体积在500M以下</p>
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="课程简介:" prop="content">
          <el-input
            resize="none"
            v-model="addbannerlList.content"
            placeholder="请输入课程简介"
            type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button v-dbClick class="saveButton" @click="saveMyTeach()"
          >保存</el-button
        >
      </span>
    </div>
  </div>
</template>

<script>
import { baseUrl } from '@/config'
import { videoInfo } from '@/api/center/teach.js'
import { majorList } from '@/api/center/index.js'
import { courseUpdate } from '@/api/course/index.js'
import { queryAll } from '@/api/common/index.js'
export default {
  data() {
    return {
      addBannerdialog: false,
      addbannerlList: {
        name: '',
        courseCoverUrl: '',
        courseCoverFileName: '',
        courseCoverSize: '',
        coursePublicityImagesUrl: '',
        coursePublicityImagesFileSize: '',
        coursePublicityImagesFileName: '',
        resourceTypeId: '',
        resourceTypeName: '',
        professionalId: '',
        professionalName: '',
        content: '',
        courseType: 1,
      },
      bannerType: [],
      addbannerlListRules: {
        typeFlag: [
          { required: true, message: '请输入内容', trigger: 'change' },
        ],
      },
      baseUrl: baseUrl,
      getUrl: `${baseUrl}goldcourse/common/aliyun/oss/uploadFiles`,
      headerUrl: { Authorization: window.sessionStorage.getItem('token') },
      imgUploadData: { serviceName: 'web' },
      fileList: [],
      fileListeditFile: [],
      id: '',
    }
  },
  created() {
    this.id = sessionStorage.getItem('courseId')
    this.getvideoInfo(this.id)
    this.getMajorList()
  },
  methods: {
    saveMyTeach() {
      if (this.addbannerlList.coursePublicityImagesUrl == '') {
        this.$message({
          type: 'error',
          message: '文件还未上传成功!',
        })
        return false
      }
      courseUpdate(this.addbannerlList).then((res) => {
        this.$message({
          type: 'success',
          message: '修改成功!',
        })
        this.getvideoInfo(this.id)
      })
    },
    handleAvatarSuccessClass(res, file, fileList) {
      this.fileList = fileList
      this.addbannerlList.courseCoverUrl = res.data.url
      this.addbannerlList.courseCoverFileName = res.data.fileName
      this.addbannerlList.courseCoverSize = res.data.size
    },
    handleAvatarSuccessfile(res, file, fileList) {
      this.fileListeditFile = fileList
      this.addbannerlList.coursePublicityImagesUrl = res.data.url
      this.addbannerlList.coursePublicityImagesFileName = res.data.fileName
      this.addbannerlList.coursePublicityImagesFileSize = res.data.size
    },
    handleRemove(file, fileList) {
      this.fileList = []
      this.addbannerlList.courseCoverUrl = res.data.url
      this.addbannerlList.courseCoverFileName = res.data.fileName
      this.addbannerlList.courseCoverSize = res.data.size
    },
    handleRemoveFile(file, fileList) {
      this.fileListeditFile = []
      this.addbannerlList.coursePublicityImagesUrl = ''
      this.addbannerlList.coursePublicityImagesFileName = ''
      this.addbannerlList.coursePublicityImagesFileSize = ''
    },
    getMajorList() {
      let param = {
        specialtyType: 0,
        objectType: 1,
      }
      queryAll(param).then((res) => {
        this.bannerType = res.data
      })
    },
    getvideoInfo(id) {
      this.fileList = []
      this.fileListeditFile = []
      videoInfo(id).then((res) => {
        this.addbannerlList = res.data
        this.fileList.push({ url: res.data.courseCoverUrl })
        if (res.data.coursePublicityImagesUrl) {
          this.fileListeditFile.push({ url: res.data.coursePublicityImagesUrl })
        }
      })
    },
    // 上传图片
    handleRemove(file, fileList) {
      console.log(file, fileList)
    },
    handlePreview(file) {
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
  },
}
</script>
<style lang="scss">
.fromdiv .el-form-item__label {
  text-align: left;
}
.fromdiv .el-select {
  width: 400px;
}
.fromdiv .el-input__inner {
  border: 1px solid #e6e6e6 !important;
  height: 42px !important;
  line-height: 42px !important;
  border-radius: 0px !important;
  color: #333 !important;
}
.fromdiv .el-textarea__inner {
  border-radius: 0px !important;
  height: 160px;
}
.fromdiv .el-upload__tip {
  font-size: 12px;
  color: #606266;
  margin-top: 0px;
  line-height: 24px;
}
.fromdiv .el-upload-list__item:first-child {
  margin-top: 6px;
  .el-upload-list__item-name {
    padding-left: 0;
  }
}
.fromdiv .dialog-footer {
  margin-left: 80px;
}
.contontrouter[data-v-73e7812f] {
  width: 860px;
  min-height: 600px;
  background-color: #fff;
}
</style>

<style lang="scss" scoped>
.saveButton {
  width: 120px;
  height: 36px;
  background: #0b3450;
  box-shadow: 0px 6px 20px 0px rgba(143, 126, 255, 0.17);
  padding: 0;
}
.fromdiv {
  margin-top: 20px;
  width: 480px;
}

.courseBase {
  background: #fff;
  padding: 0 30px 20px;
}
.catalog {
  overflow: hidden;
  padding: 20px 0 10px;
  border-bottom: 1px solid #e6e6e6;
  .line {
    width: 8px;
    height: 18px;
    background: #0b3450;
    float: left;
    margin-right: 8px;
  }
  .catalogTit {
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
  }
}
</style>
