<template>
  <div class="maincen">
    <div class="coursetoptab clearfix">
      <ul class="left clearfix">
        <li
          class="left tabli commontext"
          v-for="(item, index) in tabArr"
          :key="index"
          :class="{ activeli: activeIndex == index }"
          @click="tabliClick(index)"
        >
          {{ item.text }}
        </li>
      </ul>
      <div class="creatediv right" v-show="type == 'A'" @click="createClass">
        <img class="createicon" src="../../assets/img/create.png" alt="" />
        创建课程
      </div>
      <div class="creatediv right" v-show="type == 'B'" @click="createBan">
        <img class="createicon" src="../../assets/img/create.png" alt="" />
        创建班级
      </div>
    </div>
    <div class="coursebox">
      <div class="coursediv content" v-show="type == 'A'">
        <p class="noText" v-if="courseData == 0">暂无数据</p>
        <ul class="clearfix">
          <li v-for="(item, index) in courseData" :key="index">
            <a href="javascript:;" @click="gotoDetail(item)">
              <img :src="item.courseCoverUrl" class="courseCover" />
              <p>{{ item.name }}</p>
            </a>
            <template>
              <el-button
                @click="editClickClass(item)"
                type="text"
                class="left commonButton editbutton"
                ><span
                  ><i class="iconfont icon-bianji"></i>编辑</span
                ></el-button
              >
              <el-button
                @click="delectClickClass(item)"
                type="text"
                class="left commonButton"
                ><span
                  ><i class="iconfont icon-shanchu"></i>删除</span
                ></el-button
              >
              <el-button @click="down()" type="text" class="left commonButton"
                ><span
                  ><i class="el-icon-download daochuicon"></i>导出</span
                ></el-button
              >
            </template>
          </li>
        </ul>
      </div>
      <div class="classdiv content" v-show="type == 'B'">
        <el-table class="banjitable" :data="tableData" style="width: 100%">
          <el-table-column prop="name" label="班级名称"> </el-table-column>
          <el-table-column prop="imagesUrl" label="班级封面">
            <template slot-scope="scope">
              <img
                :src="scope.row.imagesUrl"
                style="width: 50px; height: 28px"
              />
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间">
          </el-table-column>
          <el-table-column label="操作" width="230">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >授课详情</el-button
              >
              <el-button
                @click="gotoStudent(scope.row)"
                type="text"
                size="small"
                >学员管理</el-button
              >
              <el-button @click="etitClick(scope.row)" type="text" size="small"
                >修改</el-button
              >
              <el-button
                @click="delectClick(scope.row)"
                class="delectebutton"
                type="text"
                size="small"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block" v-show="classPage">
        <el-pagination
          :current-page="1"
          @current-change="handleCurrentChangeClass"
          :page-size="param.pageSize"
          background
          layout="prev, pager, next,total"
          :total="totalClass"
        >
        </el-pagination>
      </div>
      <div class="block" v-show="BanPage">
        <el-pagination
          :current-page="1"
          @current-change="handleCurrentChange"
          :page-size="par.pageSize"
          background
          layout="prev, pager, next,total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisibleCommontBan"
      @close="close"
      class="classDialog commonDialog"
    >
      <p class="addtext">{{ edit ? '编辑编辑' : '创建班级' }}</p>
      <div class="majordiv">
        <span class="majorName">创建班级:</span>
        <input
          class="majorInput"
          v-model="classData.name"
          type="text"
          placeholder="请输入班级名称"
        />
      </div>
      <el-upload
        class="upload-demo banUpload"
        :action="getUrl"
        name="file"
        :limit="1"
        accept=".jpg, .png, .JPG, .jpeg, .JPEG, .PNG, .gif"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :file-list="fileList"
        :headers="headerUrl"
        :on-success="handleAvatarSuccess"
        :data="imgUploadData"
        list-type="picture"
      >
        <el-button size="small" type="primary">上传班级封面</el-button>
        <div class="uploadtext">
          <p class="tiptext">点击选择一个文件到这里上传</p>
          <p class="tiptext">支持png、jpg、JPEG...等格式，体积在2M以下</p>
        </div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
        <el-button class="saveButton" v-dbClick @click="saveClass"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisibleCommont"
      class="classDialog commonDialog"
    >
      <el-form
        ref="addSchoolListref"
        :rules="addbannerlListRules"
        :model="addbannerlList"
        label-width="0"
      >
        <p class="addtext">{{ edit ? '编辑课程信息' : '添加课程信息' }}</p>
        <div class="majordiv">
          <span class="majorName">课程名称:</span>
          <input
            class="majorInput"
            type="text"
            v-model="addbannerlList.name"
            placeholder="请输入课程名称"
          />
        </div>
        <el-form-item>
          <el-upload
            class="upload-demo"
            :action="getUrl"
            name="file"
            :limit="1"
            accept=".jpg, .png, .JPG, .jpeg, .JPEG, .PNG, .gif"
            :on-preview="handlePreview"
            :on-remove="handleRemoveClass"
            :file-list="fileListedit"
            :headers="headerUrl"
            :on-success="handleAvatarSuccessClass"
            :data="imgUploadData"
            list-type="picture"
          >
            <el-button size="small" type="primary">上传课程宣传图片</el-button>
            <div class="uploadtext">
              <p class="tiptext">点击选择一个文件到这里上传</p>
              <p class="tiptext">支持png、jpg、JPEG...等格式，体积在2M以下</p>
            </div>
          </el-upload>
        </el-form-item>
        <div class="majordiv">
          <span class="majorName">所属专业:</span>
          <el-select
            v-model="addbannerlList.professionalId"
            placeholder="请选择所属专业"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in bannerType"
              :key="index"
            ></el-option>
          </el-select>
        </div>
        <div class="textareadiv">
          <p class="majorName">课程简介:</p>
          <textarea
            class="majorText"
            type="text"
            v-model="addbannerlList.content"
            placeholder="请输入课程简介"
          >
          </textarea>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button v-dbClick class="saveButton" @click="saveMyTeach"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../assets/api/axios.js'
import { baseUrl } from '@/config'
import { queryAll } from '@/api/common/index.js'
import {
  clasList,
  classSaveList,
  banInfo,
  deleteBan,
  updateSaveList,
  myTeachListSave,
  myTeachList,
  deleteClass,
} from '@/api/center/teach.js'
import { majorList } from '@/api/center/index.js'
import { findName } from '@/utils/index.js'
export default {
  data() {
    return {
      classPage: true,
      BanPage: false,
      dialogVisibleCommont: false,
      dialogVisibleCommontBan: false,
      edit: false,
      activeName: 'first',
      par: {
        pageNum: 1,
        pageSize: 8,
      },
      param: {
        pageNum: 1,
        pageSize: 8,
        courseType: 1,
      },
      getUrl: `${baseUrl}goldcourse/common/aliyun/oss/uploadFiles`,
      bannerType: [],
      classData: {
        name: '',
        imagesUrl: '',
        imagesName: '',
        imagesSize: '',
      },
      addbannerlList: {
        name: '',
        courseCoverUrl: '',
        courseCoverFileName: '',
        courseCoverSize: '',
        resourceTypeId: '',
        resourceTypeName: '',
        professionalId: '',
        professionalName: '',
        content: '',
        courseType: 1,
      },
      fileList: [],
      fileListedit: [],
      headerUrl: { Authorization: window.sessionStorage.getItem('token') },
      imgUploadData: { serviceName: 'web' },
      addbannerlListRules: {},
      total: 0,
      totalClass: 0,
      activeIndex: 0,
      type: 'A',
      collectionList: [],
      courseData: [],
      tabArr: [{ text: '我教的课程' }, { text: '班级管理' }],
      tableData: [],
      banEdit: false,
      editId: '',
    }
  },
  created() {
    this.loadCollectionList()
    this.getClassData()
    this.getMajorList()
  },
  methods: {
    down() {
      getFileAndDownload(
        'https://wutonghua-oss.oss-cn-qingdao.aliyuncs.com/jinke_web/course.xlsx'
      )
      function getFileAndDownload(url) {
        var x = new XMLHttpRequest()
        x.open('GET', url, true)
        x.responseType = 'blob'
        x.onload = function (e) {
          var blob = x.response
          if ('msSaveOrOpenBlob' in navigator) {
            //IE导出
            window.navigator.msSaveOrOpenBlob(blob, '课程学习情况统计')
          } else {
            var a = document.createElement('a')
            a.download = '课程学习情况统计'
            a.href = URL.createObjectURL(blob)
            a.click()
          }
        }
        x.send()
      }
      // function downloadUrlFile(url) {
      //   url = url.replace(/\\/g, '/')
      //   const xhr = new XMLHttpRequest()
      //   xhr.open('GET', url, true)
      //   xhr.responseType = 'blob'
      //   //xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
      //   xhr.onload = () => {
      //     if (xhr.status === 200) {
      //       // 获取文件blob数据并保存
      //       saveAs(xhr.response, '统计表格')
      //     }
      //   }

      //   xhr.send()
      // }

      // /**
      //  * URL方式保存文件到本地
      //  * @param data 文件的blob数据
      //  * @param name 文件名
      //  */
      // function saveAs(data, name) {
      //   var urlObject = window.URL || window.webkitURL || window
      //   var export_blob = new Blob([data])
      //   var save_link = document.createElementNS(
      //     'http://www.w3.org/1999/xhtml',
      //     'a'
      //   )
      //   save_link.href = urlObject.createObjectURL(export_blob)
      //   save_link.download = name
      //   save_link.click()
      // }
    },
    gotoDetail(item) {
      this.$router.push({
        path: '/teachingmat/Matdetails',
        query: {
          id: item.id,
        },
      })
    },
    editClickClass(item) {
      sessionStorage.setItem('courseId', item.id)
      this.$router.push({
        path: '/course/base',
      })
    },
    getMajorList() {
      let param = {
        specialtyType: 0,
        objectType: 1,
      }
      queryAll(param).then((res) => {
        this.bannerType = res.data
      })
    },
    getClassData() {
      myTeachList(this.param).then((res) => {
        this.courseData = res.data ? res.data : []
        this.totalClass = res.page.total
      })
    },
    saveMyTeach() {
      if (this.addbannerlList.courseCoverUrl == '') {
        this.$message({
          type: 'error',
          message: '文件还未上传成功!',
        })
        return false
      }
      this.addbannerlList.professionalName = findName(
        this.bannerType,
        this.addbannerlList.professionalId
      )
      myTeachListSave(this.addbannerlList).then((res) => {
        this.$message({
          type: 'success',
          message: '添加成功!',
        })
        this.dialogVisibleCommont = false
        this.getClassData()
      })
    },
    close() {
      this.fileList = []
    },
    delectClickClass(row) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteClass([row.id]).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getClassData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    delectClick(row) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteBan([row.id]).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.loadCollectionList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    etitClick(row) {
      this.dialogVisibleCommontBan = true
      this.banEdit = true
      this.editId = row.id
      this.getbanInfo()
    },
    getbanInfo() {
      banInfo(this.editId).then((res) => {
        this.classData = res.data
        this.fileList.push({ url: res.data.imagesUrl })
      })
    },
    handleCurrentChange(val) {
      this.par.pageNum = val
      this.loadCollectionList()
    },
    handleCurrentChangeClass() {
      this.param.pageNum = val
      this.getClassData()
    },
    saveClass() {
      if (this.editId) {
        updateSaveList(this.classData).then((res) => {
          if (res.status == 0) {
            this.dialogVisibleCommontBan = false
            this.$message({
              type: 'success',
              message: '修改成功!',
            })
            this.loadCollectionList()
          }
        })
      } else {
        classSaveList(this.classData).then((res) => {
          if (res.status == 0) {
            this.dialogVisibleCommontBan = false
            this.$message({
              type: 'success',
              message: '添加成功!',
            })
            this.loadCollectionList()
          }
        })
      }
    },
    gotoStudent(row) {
      this.$router.push({
        path: '/center/student',
        query: {
          id: row.id,
        },
      })
    },
    createBan() {
      this.classData.name = ''
      this.fileList = []
      this.dialogVisibleCommontBan = true
    },
    handleRemove(file, fileList) {
      this.fileList = []
      this.classData.imagesUrl = ''
      this.classData.imagesName = ''
      this.classData.imagesSize = ''
    },
    handleRemoveClass(file, fileList) {
      this.fileListedit = []
      this.addbannerlList.courseCoverUrl = ''
      this.addbannerlList.courseCoverFileName = ''
      this.addbannerlList.courseCoverSize = ''
    },
    handlePreview(file) {},
    handleAvatarSuccess(res, file, fileList) {
      this.fileList = fileList
      this.classData.imagesUrl = res.data.url
      this.classData.imagesName = res.data.fileName
      this.classData.imagesSize = res.data.size
    },
    handleAvatarSuccessClass(res, file, fileList) {
      this.fileListedit = fileList
      this.addbannerlList.courseCoverUrl = res.data.url
      this.addbannerlList.courseCoverFileName = res.data.fileName
      this.addbannerlList.courseCoverSize = res.data.size
    },
    createClass() {
      this.addbannerlList.name = ''
      this.addbannerlList.content = ''
      this.addbannerlList.professionalId = ''
      this.fileListedit = []
      this.dialogVisibleCommont = true
    },
    handleSizeChange(newSize) {},
    tabliClick(index) {
      this.activeIndex = index
      if (index == 0) {
        this.classPage = true
        this.BanPage = false
        this.type = 'A'
      } else {
        this.type = 'B'
        this.classPage = false
        this.BanPage = true
      }
    },
    loadCollectionList() {
      clasList(this.par).then((res) => {
        this.tableData = res.data ? res.data : []
        this.total = res.page.total
      })
    },
    handleClick(row) {
      // console.log(row.id)
      let routeData = this.$router.resolve({
        path: '/grades/course',
        query: {
          id: row.id,
        },
      })
      window.open(routeData.href, '_blank')
    },
    //删除
    open() {
      this.$confirm('此操作将删除当前班级的课程资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
}
</script>
<style lang="scss">
.daochuicon {
  font-size: 16px;
}
.classDialog .el-dialog {
  width: 480px;
}
.classDialog .el-form-item {
  margin-bottom: 0px;
  margin-top: 9px;
}
.classDialog .el-input__inner {
  border: none;
}
.classDialog .el-select {
  width: 77.5%;
}
.classDialog .ziyuanselect {
  width: 70%;
}
.classDialog .el-dialog__body {
  padding: 30px 40px;
}
.banjitable {
  margin-top: 20px;
}
.banjitable .el-button--text {
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #0b3450;
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
}
.banjitable .el-table__header th,
.banjitable .el-table__header tr {
  background: #fbfbfb !important;
}
.banjitable .el-table__header thead {
  border: 1px solid #e6e6e6;
}
.banjitable .el-table__header {
  border-collapse: inherit !important;
}
.classdiv .el-table td,
.el-table th.is-leaf {
  border-bottom: 1px dashed #ebeef5;
}
.delectebutton span {
  color: #ff7500;
}
.tabli {
  width: 140px;
  height: 36px;
  background: #fff;
  border: 1px solid #e6e6e6;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  cursor: pointer;
}
.activeli {
  color: #0b3450;
  border-bottom: 2px solid#0b3450;
  background: #f8f8f8;
}

.tabli:last-child {
  border-left: 0;
}
.coursetoptab {
  width: 811px;
  height: 36px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}
</style>
<style lang="scss" scoped>
.noText {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.noText {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.banUpload {
  margin-top: 10px;
}
.uploadtext {
  display: inline-block;
  width: 257px;
  vertical-align: top;
  margin-left: 5px;
  margin-top: 2px;
}
.tiptext {
  font-size: 12px;
  line-height: 18px;
  text-align: left;
}
.majorText {
  resize: none;
  border: none;
  outline: none;
  height: 110px;
  margin-top: 10px;
  width: 337px;
}
.majorName {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-right: 5px;
}
.majorInput {
  border: none;
  outline: none;
  width: 290px;
}
.textareadiv {
  width: 360px;
  padding: 14px 20px;
  height: 140px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  margin-top: 13px;
}
.majordiv {
  width: 360px;
  padding: 0 20px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  line-height: 42px;
  margin-top: 9px;
}
.editbutton {
  margin-right: 20px;
}
.commonButton {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #cccccc;
}
.commonicon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 2px;
}
.operationdiv {
  margin-top: 8px;
}
.courseLi {
  width: 191px;
  height: 140px;
  margin-right: 14px;
}
.courseLi:nth-child(4n) {
  margin-right: 0;
}
.courseCover {
  width: 192px;
  height: 108px;
}
.creatediv {
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  cursor: pointer;
}
.createicon {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
}
.coursebox {
  .content {
    margin-top: 10px;
    height: 500px;
    ul {
      li {
        margin-right: 13px;
        float: left;
        margin-bottom: 15px;
      }
      li:nth-child(4n) {
        margin-right: 0px;
      }
    }
  }
}
</style>
