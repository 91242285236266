import service from '@/utils/request.js'
export function sendMessage(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/common/aliyun/sms/sendMessage',
    data
  });
}

export function queryAll(data) {
  // data.schoolId = localStorage.getItem('schoolId')

  if (localStorage.getItem('schoolId') != null && localStorage.getItem('schoolId') != 0 && localStorage.getItem('schoolId') != "undefined" && localStorage.getItem('schoolId') != "null") {
    data.schoolId = localStorage.getItem('schoolId')
  }
  return service.request({
    method: 'get',
    url: '/goldcourse/web/specialtyclassify/queryAll',
    params: data
  });
}

