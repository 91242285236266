import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    token: '',
    picUrl: '',
    nickname: '',
    phone: '',
    fid: '',
    status: '',
    schName: '',
    name: '',
    jobNum: '',
    typeFlag: '',
    schType: '',
    invitationCode: ''
  },
  mutations: {
    getPicUrl(state, picUrl) {
      state.picUrl = picUrl
    },
    getnickname(state, nickname) {
      state.nickname = nickname
    },
    getToken(state, token) {
      state.token = token
    },
    getPhone(state, phone) {
      state.phone = phone
    },
    getFid(state, fid) {
      state.fid = fid
    },
    getStatus(state, status) {
      state.status = status
    },
    getSchName(state, schName) {
      state.schName = schName
    },
    getName(state, name) {
      state.name = name
    },
    getjobNum(state, jobNum) {
      state.jobNum = jobNum
    },
    gettypeFlag(state, typeFlag) {
      state.typeFlag = typeFlag
    },
    getschType(state, schType) {
      state.schType = schType
    },
    getinvitationCode(state, invitationCode) {
      state.invitationCode = invitationCode
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    // couseTitle: state => state.couseTitle
    invitationCode(state) {
      return state.invitationCode
    },
    token(state) {
      return state.token
    },
    resPicUrl(state) {
      return state.picUrl
    },
    resnickname(state) {
      return state.nickname
    },
    resPhone(state) {
      return state.phone
    },
    resFid(state) {
      return state.fid
    },
    resStatus(state) {
      return state.status
    },
    resSchName(state) {
      return state.schName
    },
    resName(state) {
      return state.name
    },
    resjobNum(state) {
      return state.jobNum
    },
    restypeFlag(state) {
      return state.typeFlag
    },
    resschType(state) {
      return state.schType
    },
  }
})
