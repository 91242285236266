import service from '@/utils/request.js'




export function questionsList(data) {
  return service.request({
    method: 'get',
    url: '/goldcourse/web/onlinelearningresourcedetails/list',
    params: data
  });
}
export function addQuestionsSection(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/onlinelearningresourcedetails/save',
    data
  });
}
export function updateQuestionsSection(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/onlinelearningresourcedetails/update',
    data
  });
}
export function delLearningSection(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/onlinelearningresourcedetails/delete',
    data
  });
}
export function getQuestionsInfo(data) {
  return service.request({
    method: 'get',
    url: `/goldcourse/web/onlinelearningresourcedetails/info/${data}`,
  });
}
