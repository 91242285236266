import service from '@/utils/request.js'
export function getCollectionList(data) {
  return service.request({
    method: 'get',
    url: '/goldcourse/web/studentstudycourserecord/list',
    params: data
  });
}

export function getCollectionCord(data) {
  return service.request({
    method: 'get',
    url: '/goldcourse/web/submitthepaperrecord/list',
    params: data
  });
}
