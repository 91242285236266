<template>
  <div>
    <div class="bg">
      <div class="main">
        <div class="logo">
          <img src="../../assets/img/logo.png" alt />
        </div>
        <div class="tab">
          <!-- <router-link class="navRouter" to="/index" tag="div">首页</router-link> -->
          <router-link class="navRouter" to="/Teachingmat" tag="div"
            >立体教材</router-link
          >
          <!-- <router-link class="navRouter" to="/repositories" tag="div">资源库</router-link> -->
          <router-link class="navRouter" to="/contact" tag="div"
            >联系我们</router-link
          >
          <!-- <div>首页</div> -->
          <!-- <div>立体教材</div>
                    <div>资源库</div>
          <div>联系我们</div>-->
        </div>
        <div class="user" v-show="loginBut">
          <div @click="logindialogVisible = true">登录</div>
          <div>|</div>
          <div @click="registerdialogVisible = true">注册</div>
        </div>
        <div class="touxiang" v-show="userInfo">
          <div class="touxiangPic">
            <img :src="picUrl" alt />
          </div>
          <div class="loginName">
            <el-dropdown trigger="click" @command="handleCommand">
              <span class="el-dropdown-link">{{ resPhone }}</span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="spacePer">个人中心</el-dropdown-item>
                <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <!-- login -->
        <el-dialog
          title
          :visible.sync="logindialogVisible"
          width="550px"
          :before-close="handleClose"
        >
          <div class="login">
            <div class="loginTit">
              <div></div>
              <div>账号密码登录</div>
              <div></div>
            </div>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="loginruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="用户名:" prop="account">
                <el-input
                  v-model="ruleForm.account"
                  placeholder="请输入用户名"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="密   码:"
                prop="password"
                placeholder="请输入密码"
              >
                <el-input
                  v-model="ruleForm.password"
                  type="password"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="loginBut" @click="submitForm('loginruleForm')">
              立即登录
            </div>

            <div class="loginTip">
              <span>没有账号？</span>
              <span @click="register()">立即注册</span>
              <span
                @click="
                  ;(logindialogVisible = false), (phonedialogVisible = true)
                "
                >手机验证登录</span
              >
            </div>
            <div class="line"></div>
            <div class="wechat">
              <div>使用第三方账号</div>
              <img src="../../assets/img/wechat.png" alt @click="wxlogin()" />
            </div>
          </div>
        </el-dialog>
        <!-- /login -->
        <el-dialog
          title
          :visible.sync="registerdialogVisible"
          width="550px"
          :before-close="handleClose"
        >
          <div class="login">
            <div class="loginTit">
              <div></div>
              <div>账号注册</div>
              <div></div>
            </div>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="手机号:" prop="account">
                <el-input
                  v-model="ruleForm.account"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item label="验证码:" prop="code">
                <el-input
                  v-model="ruleForm.code"
                  placeholder="请输入验证码"
                ></el-input>
                <div class="phonesend" @click="getcode()" v-show="showcode">
                  发送验证码
                </div>
                <div class="phonesend" @click="getcode()" v-show="!showcode">
                  {{ count }}s
                </div>
              </el-form-item>
              <el-form-item label="密码:" prop="pass">
                <el-input
                  type="password"
                  v-model="ruleForm.pass"
                  placeholder="请输入密码"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码:" prop="checkPass">
                <el-input
                  type="password"
                  v-model="ruleForm.checkPass"
                  placeholder="请输入确认密码"
                ></el-input>
              </el-form-item>
              <!-- <el-form-item label="密码" prop="pass">
                <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="checkPass">
                <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
              </el-form-item>-->
            </el-form>
            <div class="loginBut" @click="registerInfo('ruleForm')">
              立即注册
            </div>
            <div class="loginTip">
              <span>已有账号，</span>
              <span
                @click="
                  ;(registerdialogVisible = false), (logindialogVisible = true)
                "
                >立即登录</span
              >
            </div>
          </div>
        </el-dialog>
        <!-- 手机验证登录 -->
        <el-dialog
          title
          :visible.sync="phonedialogVisible"
          width="550px"
          :before-close="handleClose"
        >
          <div class="login">
            <div class="loginTit">
              <div></div>
              <div>{{ phoneTit }}</div>
              <div></div>
            </div>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-form-item label="手机号:" prop="account">
                <el-input
                  v-model="ruleForm.account"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item label="验证码:" prop="code">
                <el-input
                  v-model="ruleForm.code"
                  placeholder="请输入验证码"
                ></el-input>
                <div class="phonesend" @click="getcode()" v-show="showcode">
                  发送验证码
                </div>
                <div class="phonesend" v-show="!showcode">{{ count }}s</div>
              </el-form-item>

              <!-- <el-form-item label="密码" prop="pass">
                <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="确认密码" prop="checkPass">
                <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
              </el-form-item>-->
            </el-form>
            <div class="loginBut" @click="phoneInfo('ruleForm')">立即登录</div>
            <!-- <div class="loginTip">
              <span>已有账号，</span>
              <span @click="registerdialogVisible= false ,logindialogVisible = true">立即登录</span>
            </div>-->
          </div>
        </el-dialog>
        <!-- /手机验证登录 -->
        <!-- wechat -->
        <el-dialog
          title
          :visible.sync="wechatdialogVisible"
          width="550px"
          :before-close="handleClose"
        >
          <div class="login">
            <!-- <div class="loginTit">
              <div></div>
              <div>微信登录</div>
              <div></div>
            </div>-->

            <div class="qrImg">
              <!-- <div id="qrcode"></div> -->
              <wxlogin
                appid="wx1ccaa289531eec16"
                scope="snsapi_login"
                theme="black"
                redirect_uri="https%3A%2F%2Fwthedu.51suiyixue.com"
                href
                state="STATE#wechat_redirect"
              ></wxlogin>
            </div>
            <div class="wechatTip"></div>
            <div class="line"></div>
            <div class="loginTip">
              <span>没有账号？</span>
              <span @click="register()">立即注册</span>
              <span
                @click="
                  ;(wechatdialogVisible = false), (logindialogVisible = true)
                "
                >账号登录>></span
              >
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script >
import { api } from '../../assets/api/axios'
import { mapMutations, mapState, mapGetters } from 'vuex'
import wxlogin from 'vue-wxlogin'
const getUrlParam = (name) => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  let r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return unescape(r[2])
  } else {
    return null
  }
}
export default {
  components: { wxlogin },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
        console.log(reg.test(value))
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      logindialogVisible: false,
      ruleForm: {
        loginAccount: '',
        password: '',
        pass: '',
        checkPass: '',
        captcha: '',
      },
      rules: {
        loginAccount: [
          { required: true, validator: checkPhone, trigger: 'blur' },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        captcha: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 6, max: 6, message: '长度在 6个数字', trigger: 'blur' },
        ],
        pass: [{ required: true, validator: validatePass, trigger: 'blur' }],
        checkPass: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
      },
      registerdialogVisible: false,
      wechatdialogVisible: false,
      loginBut: true,
      userInfo: false,
      nickname: '',
      picUrl: window.sessionStorage.getItem('picUrl'),
      count: '',
      showcode: true,
      showcodes: false,
      timer: null,
      imgUrl: require('../../assets/img/touxiang.jpg'),
      phonedialogVisible: false,
      phoneTit: '手机号登录',
    }
  },
  computed: {
    ...mapGetters(['resPhone']),
  },
  watch: {
    resPhone: function () {
      console.log('resPhone', this.resPhone)
    },
  },
  created() {
    this.iftoken()
    this.getwxCode()
  },
  watch: {
    // '$route.query'(newVal, oldVal) {
    //   console.log(newVal)
    //   console.log(oldVal)
    //   if (newVal.num) {
    //     let pm = new FormData()
    //     pm.append('code', this.$route.query.code)
    //     pm.append('state', this.$route.query.state)
    //     pm.append('user_id', this.user_id)
    //     WECHAT_CALLBACK(pm)
    //       .then(res => {
    //         console.log(res)
    //         if (res.unbindUserInfo) {
    //           this.personInfo.wx_headimgurl = res.unbindUserInfo.wxHeadimgurl
    //         }
    //         this.scanSuccess = true
    //       })
    //       .catch(error => {
    //         console.log(error)
    //       })
    //     setTimeout(() => {
    //       this.wechatState = false
    //       this.$router.replace({ name: 'structure' })
    //     }, 2000)
    //   }
    //   console.log(newVal, oldVal, 'watch信息变化')
    // }
  },
  mounted() {},
  methods: {
    handleCommand(command) {
      if (command == 'spacePer') {
        this.$router.push({ path: '/center' })
      } else if (command == 'loginOut') {
        window.sessionStorage.clear()
        this.$router.push('/')
        this.$router.go(0)
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    wxgetCode() {
      this.registerdialogVisible = false
      this.logindialogVisible = true
    },
    //表单
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await api.post('/front/login/doLogin.do', {
            account: this.ruleForm.account,
            password: this.ruleForm.password,
          })
          this.user(res)
        } else {
          return false
        }
      })
    },
    async user(res) {
      if (res.code == 1000) {
        this.nickname = res.data.user.phone
        // this.picUrl = res.data.user.picUrl
        this.loginBut = false
        this.userInfo = true
        this.logindialogVisible = false
        this.$store.commit('getPicUrl', res.data.user.picUrl)
        this.$store.commit('getnickname', res.data.user.phone)
        this.$store.commit('getPhone', res.data.user.phone)
        this.$store.commit('getFid', res.data.user.fid)
        this.$store.commit('getStatus', res.data.user.status)
        this.$store.commit('getSchName', res.data.user.schName)
        this.$store.commit('getName', res.data.user.name)
        this.$store.commit('getjobNum', res.data.user.jobNum)
        this.$store.commit('gettypeFlag', res.data.user.typeFlag)
        this.$store.commit('getschType', res.data.user.schType)
        window.sessionStorage.setItem('nickname', res.data.user.nickname)
        if (res.data.user.picUrl == null) {
          window.sessionStorage.setItem('picUrl', this.imgUrl)
        } else {
          window.sessionStorage.setItem('picUrl', res.data.user.picUrl)
        }
        this.picUrl = window.sessionStorage.getItem('picUrl')
        window.sessionStorage.setItem('token', res.data.token)
      } else {
        this.$message.error(res.msg)
      }
    },
    iftoken() {
      let token = window.sessionStorage.getItem('token')
      if (token !== null) {
        this.loginBut = false
        this.userInfo = true
      } else {
        this.loginBut = true
        this.userInfo = false
      }
    },
    registerInfo(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await api.post('userRegister', {
            loginAccount: this.ruleForm.loginAccount,
            captcha: this.ruleForm.captcha,
            password: this.ruleForm.password,
          })
          if (res.status === 0) {
            this.$message.success('注册成功，请登录')
            this.registerdialogVisible = false
            this.logindialogVisible = true
          } else {
            this.$message.error(res.msg)
          }
        } else {
          return false
        }
      })
    },
    phoneInfo(formName) {
      let userId = window.sessionStorage.getItem('userId')
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await api.post('/front/login/vercodeLogin.do', {
            phone: this.ruleForm.account,
            vercode: this.ruleForm.code,
            userId: userId,
          })
          if (res.status === 0) {
            this.$message.success('注册成功，请登录')
            this.phonedialogVisible = false
            this.user(res)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          return false
        }
      })
    },
    async getcode() {
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.showcode = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.showcode = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
      let res = await api.post('/goldcourse/common/aliyun/sms/sendMessage', {
        mobilephone: this.ruleForm.loginAccount,
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    register() {
      this.logindialogVisible = false
      this.registerdialogVisible = true
    },
    wxlogin() {
      this.logindialogVisible = false
      this.wechatdialogVisible = true
      console.log(1)
      // let code = getUrlParam('code') //是否存在code
      // console.log(2)
      // if (code == null || code === '') {
      //   window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=wx0de1f6d09b73314e&redirect_uri=http%3A%2F%2Fwth.wthedu.com&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect`
      // } else {
      //   // this.code = code
      //   // sendCode.sendCode(code, 0)
      //   // sessionStorage.setItem('code', code)
      // }
      // var obj = new WxLogin({
      //   id: 'qrcode',
      //   appid: 'wx0de1f6d09b73314e',
      //   scope: 'snsapi_login',
      //   redirect_uri: 'http%3A%2F%2Fwth.wthedu.com',
      //   state: '',
      //   style: 'black',
      //   href: ''
      // })
    },
    getwxCode() {
      let code = getUrlParam('code') //是否存在code
      // alert(code)
      if (code == null || code === '') {
        return
      } else {
        this.sendcode(code)
      }
    },
    async sendcode(code) {
      let res = await api.post('/front/wechatScanLoginController/callBack.do', {
        code: code,
      })
      // console.log('code', res)
      if (res.data.user.phone == null) {
        this.$message.warning('请绑定手机号码')
        setTimeout(() => {
          this.phoneTit = '绑定手机号'
          this.phonedialogVisible = true
          this.wechatdialogVisible = false
        }, 1000)
        window.sessionStorage.setItem('userId', res.data.user.fid)
      } else {
        this.user(res)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.bg {
  background-image: url('../../assets/img/navbarbg.png');
  width: 100%;
  height: 90px;
}
.main {
  overflow: hidden;
  .logo {
    float: left;
    margin-top: 10px;
    width: 304px;
    height: 64px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .tab {
    float: left;
    margin-left: 68px;
    div {
      float: left;
      margin-top: 44px;
      font-size: 16px;
      color: rgba(0, 0, 0, 1);
      text-shadow: 0px 2px 0px rgba(255, 255, 255, 1);
      margin-right: 28px;
      height: 42px;
      padding: 0 10px;
    }
    .router-link-active {
      border-bottom: 4px solid #b50e05;
      color: #b50e05;
    }
  }
  .user {
    float: right;
    width: 73px;
    height: 14px;
    background: rgba(0, 0, 0, 0.1);
    // opacity:0.1;
    border-radius: 15px;
    margin-top: 38px;
    overflow: hidden;
    padding: 8px 14px;
    cursor: pointer;
    div {
      float: left;
      font-size: 13px;
      color: rgba(51, 51, 51, 1);
    }
    :nth-child(1) {
      margin-right: 8px;
    }
    :nth-child(2) {
      margin-right: 8px;
    }
  }
}
.touxiang {
  float: right;
  overflow: hidden;
  margin-top: 20px;
  height: 38px;

  .touxiangPic {
    float: left;
    margin-right: 10px;
    height: 38px;
    width: 38px;
    overflow: hidden;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .loginName {
    float: left;
    height: 38px;
    line-height: 42px;
    color: #222222;
    font-size: 14px;
    max-width: 80px;
    overflow: hidden;
    cursor: pointer;
  }
}
.login {
  margin: 0 70px 0px;
  width: 365px;
  .loginTit {
    overflow: hidden;
    margin: 0 68px 40px;
    text-align: center;
    :nth-child(1) {
      width: 40px;
      height: 1px;
      background: rgba(204, 204, 204, 1);
      float: left;
      margin-top: 10px;
    }
    :nth-child(2) {
      font-size: 18px;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      float: left;
      margin: 0 20px;
    }
    :nth-child(3) {
      width: 40px;
      height: 1px;
      background: rgba(204, 204, 204, 1);
      float: left;
      margin-top: 10px;
    }
  }
  .loginBut {
    width: 360px;
    height: 42px;
    background: rgba(181, 14, 5, 1);
    font-size: 16px;
    color: rgba(255, 255, 255, 1);
    line-height: 42px;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .loginTip {
    margin: 20px 0 0;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    span:nth-child(2) {
      font-size: 14px;
      color: rgba(181, 14, 5, 1);
      cursor: pointer;
    }
    span:nth-child(3) {
      margin-left: 160px;
      font-size: 14px;
      color: rgba(153, 153, 153, 1);
    }
  }
  .line {
    width: 360px;
    height: 1px;
    background: rgba(238, 238, 238, 1);
    margin: 70px 0 30px;
  }
  .wechat {
    margin: 0 auto;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    text-align: center;
    div {
      margin-bottom: 30px;
    }
    img {
      width: 26px;
      height: 26px;
      margin-bottom: 30px;
    }
  }
  .phonesend {
    width: 130px;
    height: 40px;
    background: rgba(246, 246, 246, 1);
    // border-left: 1px solid rgba(230, 230, 230, 1);
    font-size: 14px;
    color: rgba(102, 102, 102, 1);
    text-align: center;
    line-height: 38px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
}
.qrImg {
  width: 282px;
  height: 339px;
  // background-color: pink;
  margin-left: 40px;
}
.wechatTip {
  text-align: center;
  margin: 20px 0 30px;
  // width: 50px;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
}
</style>
