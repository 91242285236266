<template>
    <div class="maskbg" v-if="dialogVisible">
      <div class="bg" >
          <div class="titlecss">{{name}}</div>
          <div class="filecss" v-loading="isLoading">
            <iframe  width="100%" height="100%" @load="loadAction"  id="myfile"  :src="url" alt=""></iframe>
          </div>
          <img class="closeicon" src="@/assets/white_close.png" @click="close" alt="">
      </div>
    </div>
  </template>
  
  <script>
  export default {
      data(){
          return{
              dialogVisible:false,
              url:'',
              type:'',
              name:"",
              isLoading:true,
          }
      },
      methods:{
          decodeUrl(encodeUrl){
            const url = new URL(encodeUrl);
            var par = url.searchParams;
            var fileUrl = par.get('url') 
            const decodeStr = decodeURIComponent(atob(fileUrl))
            console.log('ssssss',decodeStr);
            this.url = decodeStr;
          },
          show(url,name){
            this.url = url
            this.name = name
            this.dialogVisible = true
            this.isLoading = true;
          },
          loadAction(){
            console.log('ddddd')
            this.isLoading = false;
          },
          close(){
            this.url = ''
            this.type = ''
            this.dialogVisible = false;
          },
          getClass(){
            return 'bg'
          },
      }
  }
  </script>
  
  <style lang="scss" scoped>
  .titlecss{
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .maskbg{
      position: fixed;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: rgba(0,0,0,0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index:2000;
  }
  .bg{
      width: 50vw;
      height:90vh;
      background-color: white;
      padding: 20px;
      position: relative;
  }
  .audiobg{
    width: 500px;
    height: 150px;
    background-color: white;
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .audiocss{
    // width: calc(100% - 40px);
  }
  .videobg{
      width:940px;
      height: 640px;
      background-color: white;
      padding: 20px;
      position: relative;
  }
  .videocss{
    width: 100%;
    height: 100%;
  }
  .filecss{
    width: 100%;
    height: calc(100% - 50px);
  }
  .imgbg{
    background-color: white;
    padding: 20px;
    position: relative;
}
  .imgcss{
    min-width: 400px;
    min-height: 400px;
    max-width: 70vw;
    max-height:80vh;
  }
  .closeicon{
      width: 30px;
      height: 30px;
      position: absolute;
      top: -10px;
      right: -10px;
  }
  </style>