<template>
  <div>
    <div class="main">
      <div class="selectdivAll">
        <div class="majordiv clearfix" :class="{ autoHeight: onOffBook }">
          <div
            class="majorText secondstyle"
            :class="{ autoHeightMajor: onOffBook }"
          >
            专业分类：
          </div>
          <div class="border clearfix" :class="{ autoHeightUL: onOffBook }">
            <ul
              class="majorul clearfix posiul left"
              :class="{ autoHeightULseco: onOffBook }"
            >
              <li
                v-for="(item, index) in majorData"
                class="left"
                :key="index"
                @click="selectMajor(index)"
                :class="{ activeMajor: index == currentMajor }"
              >
                {{ item.name }}
              </li>
            </ul>
            <div class="onOfftext left" @click="openOrShutBook">
              {{ onOffBook ? '收起' : '展开' }}
              <img
                v-if="!onOffBook"
                class="arrowimg"
                src="../../assets/img/down.png"
                alt=""
              />
              <img
                v-else
                class="arrowimg"
                src="../../assets/img/up.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="majordiv clearfix">
          <div class="majorText left">课程分类:</div>
          <div class="left">
            <ul class="majorul clearfix left">
              <li
                v-for="(item, index) in bookType"
                class="left"
                :key="index"
                @click="selectBook(index)"
                :class="{ activeMajor: index == currentBook }"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <p class="noText" v-if="bookData.length == 0">暂无数据</p>
        <ul class="clearfix bookMajorul">
          <Item
            :key="index"
            v-for="(item, index) in bookData"
            :item="item"
            :index="index"
          ></Item>
        </ul>
      </div>

      <div class="block">
        <el-pagination
          :current-page="1"
          @current-change="handleCurrentChange"
          :page-size="bookParam.pageSize"
          background
          layout="prev, pager, next,total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { Loading } from 'element-ui'
import { mapMutations, mapState, mapGetters } from 'vuex'
import { api } from '../../assets/api/axios'
import { homeList } from '@/api/home/index.js'
import { queryAll } from '@/api/common/index.js'
import Item from '@/components/Item/index.vue'

export default {
  data() {
    return {
      sheetId: '',
      currentBookData: {},
      majorData: [],
      majorSort: [],
      beginDate: '',
      endDate: '',
      matItem: [],
      active: '',
      total: 0,
      currentMajor: 0,
      currentBook: 0,
      onOff: false,
      onOffBook: false,
      pressData: [],
      bookParam: {
        pageNum: 1,
        pageSize: 15,
        courseType: 1,
        objectType: 2,
        courseClassifyId: '',
        professionalId: '',
      },
      bookData: [],
      bookType: [],
    }
  },
  created() {
    this.getMajorList()
    // this.bookParam.schoolId = localStorage.getItem('schoolId')
    if (localStorage.getItem('schoolId') != null&& localStorage.getItem('schoolId') != 0 && localStorage.getItem('schoolId') != "undefined" && localStorage.getItem('schoolId') != "null") {
      this.bookParam.schoolId = localStorage.getItem('schoolId')
    }
    this.getBookList(this.bookParam)
    this.getClassList()
  },
  computed: {
    ...mapGetters(['resStatus']),
  },
  components: { Item },
  methods: {
    selectMajor(index) {
      this.currentMajor = index
      this.bookParam.professionalId = this.majorData[index].id
      this.getBookList(this.bookParam)
    },
    getdetails(fid) {
      this.$router.push({
        path: '/teachingmat/Matdetails',
        query: {
          fid: fid,
        },
      })
    },
    getBookList(params) {
      homeList(params)
        .then((res) => {
          this.bookData = res.data
          this.total = res.page.total
        })
        .catch(() => {})
    },
    getMajorList() {
      let param = {
        specialtyType: 0,
        objectType: 2,
      }
      queryAll(param)
        .then((res) => {
          if (res.status == 0) {
            this.majorData = res.data
            this.majorData.unshift({
              name: '全部',
              id: '',
            })
          }
        })
        .catch(() => {})
    },
    getClassList() {
      let param = {
        specialtyType: 1,
        objectType: 2,
      }
      queryAll(param)
        .then((res) => {
          if (res.status == 0) {
            this.bookType = res.data
            this.bookType.unshift({
              name: '全部',
              id: '',
            })
          }
        })
        .catch(() => {})
    },
    close() {
      this.showModel = false
    },
    openOrShut() {
      this.onOff = !this.onOff
    },
    openOrShutBook() {
      this.onOffBook = !this.onOffBook
    },
    selectBook(index) {
      this.bookParam.courseClassifyId = this.bookType[index].id
      this.currentBook = index
      this.getBookList(this.bookParam)
    },
    handleSizeChange(newSize) {},
    handleCurrentChange(newPage) {
      this.bookParam.pageNum = newPage
      this.getBookList(this.bookParam)
    },
  },
}
</script>

<style lang="scss" scoped>
.noText {
  text-align: center;
  margin-top: 50px;
}
.cartimg {
  width: 20px;
  height: 19px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 5px;
}
.divcard {
  width: 102px;
  height: 31px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  margin-top: 10px;
  line-height: 31px;
  text-align: center;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  span {
    font-size: 14px;
    color: #999999;
    line-height: 31px;
  }
}
.pricecolor {
  color: #b80e04;
  font-size: 13px;
}
.majorImg {
  width: 130px;
  height: 181px;
}
.majorRight {
  margin-left: 20px;
}
.commoncss {
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 27px;
}
.bookTitle {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin: 10px 0;
}
.bookMajorul{
  min-height: 420px;
}
.bookMajorul li {
  width: 386px;
  height: 181px;
  margin-bottom: 50px;
}
.input {
  margin-left: 10px;
  margin-right: 10px;
}
.jian {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
}
.pressdate {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #343434;
  margin-right: 10px;
}
.searchbutton {
  width: 77px;
  height: 36px;
  background: #b60e06;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  line-height: 39px;
  text-align: center;
  cursor: pointer;
}
.blockpicker {
  display: inline-block;
}
.searchitem {
  margin-top: 20px;
  margin-bottom: 20px;
}
.border {
  border-bottom: 1px dashed #e9e9e9;
  width: 968px;
  float: left;
}
.posiul {
}
.secondstyle {
  float: left;
}
.selectdivAll {
  margin-top: 20px;
  border: 1px solid #e9e9e9;
}
.majorText {
  width: 110px;
  height: 40px;
  background: #f3f3f3;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
}
.arrowimg {
  width: 9px;
  height: 5px;
  margin-top: -4px;
  display: inline-block;
  vertical-align: middle;
}
.onOfftext {
  margin-top: 14px;
  font-size: 12px;
  margin-right: 20px;
  display: inline-block;
  vertical-align: middle;
}
.activeMajor {
  background: #0b3450;
  color: #fff !important;
}
.majorul {
  height: 38px;
  width: 910px;
  overflow: hidden;
}
.majorul li {
  margin: 0 14px;
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #343434;
  padding: 6px 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}
.autoHeightMajor {
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}
.autoHeight {
  height: auto !important;
}
.autoHeightULseco {
  height: auto !important;
  margin-left: 0px;
  min-height: 38px;
}
.autoHeightUL {
  height: auto !important;
  margin-left: 110px;
  min-height: 38px;
}
.clearfix:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
}
.majordiv {
  width: 1080px;
  height: 40px;
  background: #ffffff;
  position: relative;
}
.majorList {
  width: 930px;
}
.majorAllButton {
  margin-right: 40px;
}
.majorall {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  border-bottom: 1px solid #fff8f8f8;
  margin-top: 20px;
}
.banner {
  width: 100%;
  height: 180px;
  img {
    width: 100%;
    height: 180px;
  }
}
.sort {
  overflow: hidden;
  margin-top: 35px;
  margin-bottom: 25px;
  background: #fff;
  height: 165px;
  padding: 0 31px;
  .major {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    width: 80px;
  }

  .majorItem {
    float: left;
    width: 130px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    margin-bottom: 20px;
  }
}
.itemMar {
  margin: 30px 0;
}
.active {
  float: left;
  width: 80px;
  height: 23px;
  background: rgba(181, 14, 5, 1);
  border-radius: 2px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1) !important;
  line-height: 23px;
  text-align: center;
  margin-right: 30px;
}
.item {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  // height: 482px;
  .itemImg {
    width: 100%;
    height: 180px;
  }
  .itemInfo {
    width: 100%;
    height: 180px;
    overflow: hidden;
    .itemTit {
      font-size: 18px;
      color: rgba(51, 51, 51, 1);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 18px;
    }
    .userInfo {
      text-align: left;
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
      margin-bottom: 10px;
    }
    .textInfo {
      text-align: left;
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
      line-height: 20px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
}
.pageHeader {
  font-size: 14px;
  font-family: 'Source Han Sans CN';
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  display: float;
  margin-top: 20px;
  overflow: hidden;
  height: 70px;
  line-height: 20px;
  border-top: 1px solid #ccccccff;
  .search {
    width: 230px;
    height: 34px;
    margin: 20px 20px 0 755px;
    input {
      border: none;
      background: rgba(0, 0, 0, 0.1);
      width: 230px;
      height: 34px;
      padding-left: 10px;
    }
  }
}
.searchBut {
  width: 77px;
  height: 34px;
  background: rgba(181, 14, 5, 1);
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  line-height: 34px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}
</style>
<style lang="scss">
.searchinput .el-input__inner {
  height: 36px !important;
  line-height: 36px !important;
  border-radius: 0px !important;
}
.block {
  margin-top: 30px;
  padding-bottom: 30px;
}
.el-pager {
  font-size: 12px !important;
  color: rgba(102, 102, 102, 1);
  li {
    width: 32px;
    height: 27px;
    border: 1px solid rgba(153, 153, 153, 1);
    margin-right: 15px !important;
    font-weight: 400;
  }
}
.el-pager li.active {
  color: #fff !important;
  background: #00427f;
  cursor: pointer;
}
.el-pager li.active + li {
  border-left: 1px solid #999;
}
</style>
