import axios from 'axios'
const api = {
    async get(url) {
        try {
            let res = await axios.get(url)
            res = res.data
            return new Promise((resolve) => {
                if (res.status == 0) {
                    resolve(res)
                } else if (res.status == 100403) {
                    window.wm.$message.error('请先登录')
                } else {
                }
            })
        } catch (err) {

        }
    },
    async post(url, data) {
        try {
            let res = await axios.post(url, data)
            res = res.data
            return new Promise((resolve, reject) => {
                if (res.status == 0) {
                    resolve(res)
                } else if (res.status == 100403) {
                    window.wm.$message.error('请先登录')
                } else {
                    resolve(res)
                }
            })
        } catch (err) {

        }
    },
}
export { api }