<template>
  <div class="maincen">
    <div class="catalog">
      <div class="line"></div>
      <div class="catalogTit left">题库管理</div>
      <div class="adddiv right" @click="addMajor">
        <img class="imgicon" src="../../assets/img/add.png" alt="" />
        新增题目
      </div>
    </div>
    <div class="center content">
      <el-table class="banjitable" :data="rescouresList" style="width: 100%">
        <el-table-column
          prop="detailsName"
          label="考题名称"
          width="220"
        ></el-table-column>
        <el-table-column
          prop="examinationId"
          label="题型"
          :formatter="formatter"
          width="100"
        ></el-table-column>
        <el-table-column prop="score" label="分值" width="80"></el-table-column>
        <el-table-column prop="sort" label="排序" width="80"></el-table-column>
        <el-table-column
          prop="createTime"
          label="添加时间"
          width="150"
        ></el-table-column>
        <el-table-column prop="address" label="操作" width="150">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              plain
              @click="editRescourseId(scope.row.id)"
              >修改</el-button
            >
            <el-button
              size="small"
              type="danger"
              plain
              @click="deleteInfo(scope.row.id)"
              icon="el-icon-delete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        :current-page="1"
        @current-change="handleCurrentChangeClass"
        :page-size="params.pageSize"
        background
        layout="prev, pager, next,total"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible.sync="dialogVisibleCommont"
      class="majorDialog1 commonDialog"
    >
      <el-form ref="form" :model="addHseList" label-width="80px">
        <h1 style="font-size: 16px; color: #333; margin-bottom: 20px">
          {{ titChanging ? '新增考试题' : '编辑考试题' }}
        </h1>
        <el-form-item label="题目类型:" prop="type">
          <el-radio-group
            v-model="addHseList.examinationId"
            @change="changeType(addHseList.examinationId)"
          >
            <el-radio label="0">单选题</el-radio>
            <el-radio label="1">多选题</el-radio>
            <el-radio label="2">判断题</el-radio>
            <el-radio label="3">填空题</el-radio>
            <el-radio label="4">简答题</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="题目标题:" prop="title">
          <el-input
            v-model="addHseList.detailsName"
            placeholder="请输入考试测试题目标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-input
            v-model="addHseList.sort"
            placeholder="请设置考试测试题目排序"
          ></el-input>
        </el-form-item>
        <el-form-item label="分值:" prop="score">
          <el-input
            v-model="addHseList.score"
            placeholder="请设置考试测试题分值"
          ></el-input>
        </el-form-item>
        <el-form-item label="答案:" prop="answer">
          <el-input
            v-model="addHseList.answer"
            placeholder="请输入考试测试题正确答案"
          ></el-input>
        </el-form-item>
        <el-form-item label="选项:" prop="tryOptionsArr" v-show="disOption">
          <el-tag
            :key="tag"
            v-for="tag in addHseList.tryOptionsArr"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
          >
            {{ tag }}
          </el-tag>
          <el-button
            v-show="danOrDuo"
            class="button-new-tag"
            size="small"
            @click="addDomain"
            >+ 新增</el-button
          >
        </el-form-item>
        <el-form-item
          v-show="danOrDuo"
          v-for="(domain, index) in dynamicValidateForm.domains"
          :label="domain.id"
          :key="domain.id"
          :rules="{
            required: true,
            message: '答题选项不能为空',
            trigger: 'blur',
          }"
        >
          <el-input
            v-model="domain.value"
            v-if="keyword != 'show'"
            class="widthInput"
          ></el-input>
          <span v-if="keyword == 'show'">{{ domain.value }}</span>
          <span
            class="linkDel"
            v-if="keyword != 'show'"
            @click.prevent="removeDomain(domain, index)"
            >删除</span
          >
        </el-form-item>
        <el-form-item label="解析:" prop="shortAnswerId">
          <el-input
            type="textarea"
            v-model="addHseList.shortAnswerId"
            placeholder="请输入考试测试题解析答案"
          ></el-input>
        </el-form-item>
        <el-button v-dbClick size="medium" type="primary" @click="addQuestions"
          >保存</el-button
        >
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../assets/api/axios'
import {
  questionsList,
  addQuestionsSection,
  updateQuestionsSection,
  delLearningSection,
  getQuestionsInfo,
} from '@/api/center/Question.js'
import { confirm } from '@/utils/confirm.js'
export default {
  data() {
    return {
      rescouresList: [],
      total: 0,
      dialogVisibleCommont: false,
      addHseList: {
        tryOptionsArr: [],
        tryOptions: '',
      },
      titChanging: true,
      disOption: true,
      inputVisible: false,
      params: {
        courseType: '',
        pageNum: 1,
        pageSize: 8,
      },
      renderObj: {
        0: '单选题',
        1: '多选题',
        2: '判断题',
        3: '填空题',
        4: '简答题',
      },
      inputValue: '',
      questionId: '',
      rowNum: '',
      danOrDuo: true,
      dynamicValidateForm: {
        domains: [
          {
            value: '',
            id: 'A',
          },
        ],
      },
      keyword: 'add',
      domainsSum: 0,
      removeList: [],
      alpha: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
      ],
    }
  },
  watch: {},
  computed: {},
  created() {
    this.getExamQuestionData()
  },
  methods: {
    addDomain() {
      let temAlpha = []
      if (this.dynamicValidateForm.domains.length > 8) {
        this.$message({
          type: 'error',
          message: '抱歉，答题选项已经上限了!',
        })
        return
      }
      let domainObj = [] //添加的答题选项的abc值保存
      let domainLen = this.dynamicValidateForm.domains.length //答题选项长度获取
      let alphaObj = this.alpha.slice(0, domainLen) //截取alpha字符串长度作为数组
      this.dynamicValidateForm.domains.map((res) => {
        domainObj.push(res.id)
      })
      let unqualList = this.getArrDifference(alphaObj, domainObj) //获取字符串中不相等的内容
      if (unqualList.length > 0) {
        let interList = this.array_intersection(unqualList, alphaObj) //交集的数组
        let alphaIndex = alphaObj.indexOf(interList[0])
        this.dynamicValidateForm.domains.splice(alphaIndex, 0, {
          value: '',
          id: this.alpha[alphaIndex],
        })
      } else {
        this.dynamicValidateForm.domains.push({
          value: '',
          id: this.alpha[this.dynamicValidateForm.domains.length],
        })
      }
    },
    removeDomain(item, index) {
      var index = this.dynamicValidateForm.domains.indexOf(item)
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1)
      }
    },
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v)
      })
    },
    getArrayIndex(arr, obj) {
      var i = arr.length
      while (i--) {
        if (arr[i] === obj) {
          return i
        }
      }
      return -1
    },
    array_intersection(a, b) {
      // 交集
      var result = []
      for (var i = 0; i < b.length; i++) {
        var temp = b[i]
        for (var j = 0; j < a.length; j++) {
          if (temp === a[j]) {
            result.push(temp)
            break
          }
        }
      }
      return result
    },
    getExamQuestionData() {
      this.loadingData = true
      this.params.courseType = 3
      this.params.objectType = 1
      questionsList(this.params).then((res) => {
        if (res.status == 0) {
          this.rescouresList = res.data
          this.loadingData = false
          this.total = res.page.total
        }
      })
    },
    clearList() {
      for (let key in this.addHseList) {
        if (key == 'tryOptionsArr') {
          this.addHseList[key] = []
        } else {
          this.addHseList[key] = ''
        }
      }
      this.addHseList.examinationId = this.rowNum
    },
    // 新增题目
    addMajor() {
      this.dialogVisibleCommont = true
      this.questionId = ''
      this.titChanging = true
      this.disOption = true
      this.danOrDuo = true
      this.dynamicValidateForm.domains = [
        {
          value: '',
          id: 'A',
        },
      ]
      this.clearList()
      this.addHseList.examinationId = '0'
    },
    changeType(type) {
      this.rowNum = type
      switch (this.addHseList.examinationId) {
        case '0':
          this.disOption = true
          this.danOrDuo = true
          this.clearList()
          break
        case '1':
          this.disOption = true
          this.danOrDuo = true
          this.clearList()
          break
        case '2':
          this.optionDisable = true
          this.disOption = true
          this.danOrDuo = false
          this.clearList()
          this.addHseList.tryOptionsArr = ['A：对', 'B：错']
          break
        case '3':
          this.disOption = false
          this.danOrDuo = false
          this.clearList()
          break
        case '4':
          this.disOption = false
          this.danOrDuo = false
          this.clearList()
          break
      }
    },

    questionsInfo() {
      getQuestionsInfo(this.questionId).then((res) => {
        this.addHseList = res.data
        if (res.data.examinationId == 2) {
          this.addHseList.tryOptionsArr = JSON.parse(this.addHseList.tryOptions)
          this.disOption = true
        } else if (res.data.examinationId == 0 || res.data.examinationId == 1) {
          var tryOptions = res.data.tryOptions
          this.danOrDuo = true
          this.disOption = true
          this.dynamicValidateForm.domains = JSON.parse(tryOptions)
        } else if (res.data.examinationId == 3 || res.data.examinationId == 4) {
          this.danOrDuo = false
          this.disOption = false
        }
        this.addHseList.examinationId = this.addHseList.examinationId.toString()
      })
    },
    //编辑列表
    editRescourseId(id) {
      this.titChanging = false
      this.dialogVisibleCommont = true
      this.questionId = id
      this.questionsInfo()
    },
    message() {
      this.$message({
        type: 'success',
        message: this.titChanging ? '添加成功!' : '修改成功!',
      })
      this.dialogVisibleCommont = false
      this.getExamQuestionData()
    },
    addQuestions() {
      this.addHseList.courseType = 3
      if (this.addHseList.examinationId == 2) {
        this.addHseList.tryOptions = JSON.stringify(
          this.addHseList.tryOptionsArr
        )
      }
      if (
        this.addHseList.examinationId == 0 ||
        this.addHseList.examinationId == 1
      ) {
        this.addHseList.tryOptions = JSON.stringify(
          this.dynamicValidateForm.domains
        )
      }
      this.addHseList.objectType = 1
      if (this.questionId) {
        updateQuestionsSection(this.addHseList).then((res) => {
          if (res.status == 0) {
            this.message()
          }
        })
      } else {
        addQuestionsSection(this.addHseList).then((res) => {
          if (res.status == 0) {
            this.message()
          }
        })
      }
    },
    //删除列表
    deleteInfo(id) {
      confirm({
        content: `此操作将永久删除该数据, 是否继续?`,
      }).then(() => {
        delLearningSection([id])
          .then((res) => {
            if (res.status == 0) {
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
              this.getExamQuestionData()
            }
          })
          .catch((error) => {
            this.$message({
              type: 'success',
              message: '删除失败!',
            })
          })
      })
    },
    formatter(row, column) {
      return this.renderObj[row.examinationId]
    },
    handleClose(tag) {
      this.addHseList.tryOptionsArr.splice(
        this.addHseList.tryOptionsArr.indexOf(tag),
        1
      )
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        this.addHseList.tryOptionsArr.push(inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    handleCurrentChangeClass(val) {
      this.params.pageNum = val
      this.getExamQuestionData()
    },
  },
}
</script>
<style lang="scss">
.linkDel {
  margin-left: 10px;
}
.widthInput {
  width: 92% !important;
}
.majorDialog1 .el-dialog {
  width: 922px;
  padding: 0 50px;
}
.majorDialog1 .el-form-item__label {
  text-align: left;
}
.majorDialog1 .el-dialog__body {
  padding-left: 0;
  padding: 20px 0;
}
.majorDialog1 .el-dialog__footer {
  padding: 0;
}
.majorDialog1 .el-dialog__footer {
  text-align: center;
}
.saveButton {
  width: 400px;
  height: 40px;
  background: #0b3450 !important;
  color: #fff !important;
}
.banjitable {
  margin-top: 20px;
}
.banjitable .el-button--text {
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #0b3450;
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
}
.banjitable .el-table__header th,
.banjitable .el-table__header tr {
  background: #fbfbfb !important;
}
.banjitable .el-table__header thead {
  border: 1px solid #e6e6e6;
}
.banjitable .el-table__header {
  border-collapse: inherit !important;
}
.classdiv .el-table td,
.el-table th.is-leaf {
  border-bottom: 1px dashed #ebeef5;
}
.delectebutton span {
  color: #ff7500;
}
.majorDialog1 .el-dialog {
  min-height: 540px !important;
}
</style>
<style lang="scss" scoped>
.majorName {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-right: 5px;
}
.majorInput {
  border: none;
  outline: none;
  width: 290px;
}
.majordiv {
  width: 360px;
  padding: 0 20px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  line-height: 42px;
  margin-top: 13px;
}
.addtext {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}
.adddiv {
  cursor: pointer;
}
.imgicon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
.metTit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  margin: 10px 0;
}
.metuser {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: rgba(102, 102, 102, 1);
}
.imgBox {
  width: 100%;
  height: 181px;
  img {
    width: 100%;
    height: 100%;
  }
}
.contents {
  overflow: hidden;
  .contentsTit {
    margin-bottom: 20px;
    float: left;
    font-size: 14px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
  }
}
.convideo {
  height: 35px;
  overflow: hidden;
  div:nth-child(1) {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    float: left;
  }
  div:nth-child(2) {
    float: right;
  }
}
.center {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.el-tag {
  margin-right: 10px;
}
.el-button {
  padding: 8px 20px;
}
.content .el-table__body-wrapper .el-button--mini,
.content .el-table__body-wrapper .el-button--small {
  padding: 8px 12px !important;
}
</style>
