<template>
  <div class="maincen">
    <div class="coursetoptab clearfix">
      <ul class="left clearfix">
        <li
          class="left tabli commontext"
          v-for="(item, index) in tabArr"
          :key="index"
          :class="{ activeli: activeIndex == index }"
          @click="tabliClick(index)"
        >
          {{ item.text }}
        </li>
      </ul>
    </div>
    <div>
      <div class="coursediv content" v-show="type == 'A'">
        <p class="noText" v-if="courseData.length == 0">暂无数据</p>
        <ul class="clearfix">
          <li
            class="left courseLi"
            v-for="(item, index) in courseData"
            :key="index"
            @click="showDetail(item.id)"
          >
            <img class="courseCover" :src="item.courseImageUrl" alt="" />
            <p class="courseTitle">{{ item.name }}</p>
            <p class="teachname">发布人:{{ item.createByName }}</p>
            <div class="progress">
              <span
                class="red"
                :style="{ width: widthNum(item.learningProgress) }"
                ><span></span
              ></span>
            </div>
            <p class="alStudy">
              已学习{{ item.learningProgress.toFixed(2) + '%' }}
            </p>
          </li>
        </ul>
      </div>
      <div class="classdiv content" v-show="type == 'B'">
        <el-table
          class="banjitable"
          :data="tableData"
          style="width: 100%"
          :cell-style="setCellColor"
        >
          <el-table-column prop="courseName" label="课程名称">
          </el-table-column>
          <el-table-column prop="examName" label="考试名称"> </el-table-column>
          <el-table-column prop="totalScore" label="测试总成绩">
          </el-table-column>
          <el-table-column prop="createTime" label="成绩提交时间">
          </el-table-column>
          <el-table-column prop="totalValue" label="得分"> </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >考试详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          :current-page="1"
          @current-change="handleCurrentChange"
          :page-size="par.pageSize"
          background
          layout="prev, pager, next,total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { api } from '../../assets/api/axios.js'
import { baseUrl } from '@/config'
import { getCollectionList, getCollectionCord } from '@/api/center/mycourse.js'
export default {
  data() {
    return {
      dialogVisibleCommont: false,
      dialogVisibleCommontBan: false,
      edit: false,
      activeName: 'first',
      par: {
        pageNum: 1,
        pageSize: 8,
      },
      getUrl: `${baseUrl}upload/uploadImg.do`,
      bannerType: [
        { id: 0, name: 'pc' },
        { id: 1, name: '小程序' },
      ],
      addbannerlList: {
        banTitle: '',
        typeFlag: '',
        banPicurl: '',
        banLink: '',
        banSort: '',
      },
      fileList: [],
      fileListedit: [{ name: '', url: '' }],
      headerUrl: { Authorization: window.sessionStorage.getItem('token') },
      imgUploadData: { commonName: 'banner' },
      addbannerlListRules: {
        typeFlag: [
          { required: true, message: '请输入内容', trigger: 'change' },
        ],
      },
      total: 0,
      activeIndex: 0,
      type: 'A',
      courseData: [],
      tableData: [],
      tabArr: [{ text: '学习的课程' }, { text: '测试成绩统计' }],
    }
  },
  created() {
    this.loadCollectionList()
  },
  methods: {
    widthNum(num) {
      console.log(num.toFixed(2))
      return num.toFixed(2) + '%'
    },
    tabliClick(index) {
      this.activeIndex = index
      if (index == 0) {
        this.type = 'A'
      } else {
        this.type = 'B'
      }
    },
    async loadCollectionList() {
      getCollectionList(this.par).then((res) => {
        if (res.status == 0) {
          this.courseData = res.data
          this.total = res.page.total
        }
      })
      getCollectionCord(this.par).then((res) => {
        if (res.status == 0) {
          this.tableData = res.data
          this.total = res.page.total
        }
      })
    },
    handleSizeChange(newSize) {},
    handleCurrentChange(newPage) {
      this.pageBean.pageNum = newPage
    },
    setCellColor({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 3) {
        return 'color:#ff7500'
      }
    },
    showDetail(fid) {
      this.$router.push({
        path: '/teachingmat/Matdetails',
        query: {
          id: fid,
        },
      })
    },
    handleClick(row) {
      sessionStorage.setItem('courseId', row.id)
      sessionStorage.setItem('kaoName', row.examName)
      this.$router.push({
        path: '/viewTest',
        query: {
          id: row.sectionId,
        },
      })
    },
  },
}
</script>
<style lang="scss">
.courseTitle {
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  margin-top: 10px;
}
.classDialog .el-dialog {
  width: 480px;
}
.classDialog .el-form-item {
  margin-bottom: 0px;
  margin-top: 9px;
}
.classDialog .el-input__inner {
  border: none;
}
.classDialog .el-select {
  width: 77.5%;
}
.classDialog .ziyuanselect {
  width: 70%;
}
.classDialog .el-dialog__body {
  padding: 30px 40px;
}
.banjitable {
  margin-top: 20px;
}
.banjitable .el-button--text {
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #0b3450;
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
}
.banjitable .el-table__header th,
.banjitable .el-table__header tr {
  background: #fbfbfb !important;
}
.banjitable .el-table__header thead {
  border: 1px solid #e6e6e6;
}
.banjitable .el-table__header {
  border-collapse: inherit !important;
}
.classdiv .el-table td,
.el-table th.is-leaf {
  border-bottom: 1px dashed #ebeef5;
}
.delectebutton span {
  color: #ff7500;
}
</style>
<style lang="scss" scoped>
.noText {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.alStudy {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin-top: 7px;
}
.progress {
  height: 6px;
  background: #ebebeb;
}
.progress > span {
  position: relative;
  height: 6px;
  background: #ffbc1c;
  display: block;
}
.teachname {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin-bottom: 10px;
  margin-top: 10px;
}
.banUpload {
  margin-top: 10px;
}
.uploadtext {
  display: inline-block;
  width: 257px;
  vertical-align: top;
  margin-left: 5px;
  margin-top: 2px;
}
.tiptext {
  font-size: 12px;
  line-height: 18px;
  text-align: left;
}
.majorText {
  resize: none;
  border: none;
  outline: none;
  height: 110px;
  margin-top: 10px;
  width: 337px;
}
.majorName {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-right: 5px;
}
.majorInput {
  border: none;
  outline: none;
  width: 290px;
}
.textareadiv {
  width: 360px;
  padding: 14px 20px;
  height: 140px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  margin-top: 13px;
}
.majordiv {
  width: 360px;
  padding: 0 20px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  line-height: 42px;
  margin-top: 9px;
}
.editbutton {
  margin-right: 20px;
}
.commonButton {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #cccccc;
}
.commonicon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -2px;
  margin-right: 2px;
}
.operationdiv {
  margin-top: 8px;
}
.courseLi {
  width: 191px;
  height: 197px;
  margin-right: 14px;
}
.courseLi:nth-child(4n) {
  margin-right: 0;
}
.courseCover {
  width: 192px;
  height: 108px;
}
.creatediv {
  height: 36px;
  line-height: 36px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  cursor: pointer;
}
.createicon {
  width: 14px;
  height: 14px;
  display: inline-block;
  vertical-align: middle;
}
.tabli {
  width: 140px;
  height: 36px;
  background: #f8f8f8;
  border: 1px solid #e6e6e6;
  text-align: center;
  line-height: 36px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  cursor: pointer;
}
.activeli {
  color: #0b3450;
  border-bottom: 2px solid#0b3450;
}

.tabli:last-child {
  border-left: 0;
}
.coursetoptab {
  width: 811px;
  height: 36px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 10px;
}
.content {
  height: 500px;
}
</style>
