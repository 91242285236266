<template>
  <div class="itemdiv left">
    <li class="linecourseli" @click="getdetails(item.id)">
      <img class="cover" :src="item.courseCoverUrl" alt />
      <p class="courseName">{{ item.name }}</p>
      <span class="teachName">{{ item.schoolName }}</span>
      <span class="people">
        <img class="avatar" src="../../assets/img/tu.png" alt="" />
        <span class="peopleNum">{{ item.studyNumber }}人</span>
      </span>
    </li>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getdetails(id) {
      this.$router.push({
        path: '/teachingmat/Matdetails',
        query: {
          id: id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.people {
  float: right;
  margin-left: 0px;
  margin-top: 0px;
}
.avatar {
  width: 12px;
  height: 15px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
}
.peopleNum {
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin-top: 5px;
}
.linecourseli {
  width: 190px;
  height: 175px;
  background: #ffffff;
  border: 1px solid #f1f1f0;
  margin-top: 20px;
  padding: 5px 6px;
  cursor: pointer;
}
.itemdiv:nth-child(5n) {
  margin-right: 0;
}
.courseName {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-left: 2px;
  margin-top: 10px;
  margin-bottom: 15px;
}
.teachName {
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin-left: 2px;
}
.itemdiv {
  margin-right: 15px;
  height: 210px;
}
.itemdiv:last-child {
  margin-right: 0;
}
.cover {
  width: 192px;
  height: 109px;
}
</style>
