<template>
  <div>
    <div class="main">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>在线学习</el-breadcrumb-item>
        <el-breadcrumb-item>在线学习详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="testTitlediv clearfix">
        <p class="left titletext">{{ kaoName }}</p>
        <p class="right scoretext">
          得分:<span class="scoreNum">{{ Info.totalValue }}分</span>
        </p>
      </div>
      <p class="tip">
        本次测试通过单选、多选题、判断、填空和简答题对学习效果进行测试。
      </p>
      <ul>
        <li v-for="(item, index) in list" :key="index">
          <p class="questionstitle">
            1 {{ returnObjValue(item.examinationId) }}({{ item.score }}分)
            {{ item.detailsName }}
          </p>
          <div>
            <div v-if="item.examinationId == 0 || item.examinationId == 1">
              <div
                class="liItem"
                v-for="(selectItem, index) in jP(item.tryOptions)"
              >
                <img
                  class="imgIcon"
                  src="../../assets/img/redioSelect.png"
                  alt=""
                  v-if="item.submitAnswer.indexOf(selectItem.id) != -1"
                />
                <img
                  v-else
                  class="imgIcon"
                  src="../../assets/img/defauleRadio.png"
                  alt=""
                />
                <span class="answer"
                  >{{ selectItem.id }}.{{ selectItem.value }}</span
                >
              </div>
              <!-- <div class="liItem">
                <img
                  class="imgIcon"
                  src="../../assets/img/defauleRadio.png"
                  alt=""
                />
                <span class="answer">D.创造</span>
              </div> -->
            </div>
            <div class="judje" v-if="item.examinationId == 2">
              <div>
                <span class="icoText">A.</span>
                <img src="../../assets/img/right.png" alt class="icoLogo" />
              </div>
              <div>
                <span class="icoText">B.</span>
                <img src="../../assets/img/false.png" alt class="icoLogo" />
              </div>
            </div>
            <div class="answerdivBorder" v-if="item.examinationId == 4">
              {{ item.submitAnswer }}
            </div>
            <div class="answerdivTian" v-if="item.examinationId == 3">
              {{ item.submitAnswer }}
            </div>
            <div class="answerdiv">
              <p class="jiexianswer">
                正确答案:<span>{{ item.answer }}</span>
              </p>
              <p class="jiexianswer">解析：{{ item.shortAnswerId }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getTopicInfo } from '@/api/detail/index.js'
export default {
  data() {
    return {
      params: {
        resourceId: '',
        resourceSectionId: '',
      },
      Info: {},
      list: [],
      former: {
        0: '单选题',
        1: '多选题',
        2: '判断题',
        3: '填空题',
        4: '简答题',
      },
      selectArr: [],
      kaoName: '',
    }
  },
  created() {
    this.params.resourceId = sessionStorage.getItem('courseId')
    this.params.resourceSectionId = this.$route.query.id
    this.kaoName = sessionStorage.getItem('kaoName')
    this.getTopicInfoDetail()
  },
  methods: {
    jP(value) {
      return JSON.parse(value)
    },
    returnObjValue(key) {
      return this.former[key]
    },
    getTopicInfoDetail() {
      getTopicInfo(this.params).then((res) => {
        this.Info = res.data
        this.list = res.data.topicInfo
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.judje {
  margin-bottom: 20px;
}
.icoText {
  font-size: 16px;
  color: rgba(51, 51, 51, 1);
}
.answerdivTian {
  width: 460px;
  height: 20px;
  padding: 10px;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
}
.answerdivBorder {
  width: 460px;
  height: 120px;
  padding: 10px;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
}
.jiexianswer {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666;
  line-height: 24px;
}
.answerdiv {
  width: 440px;
  height: 100px;
  padding: 20px;
  background: rgba(181, 14, 5, 0.1);
  margin-bottom: 20px;
}
.liItem {
  margin-bottom: 20px;
}
.answer {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
}
.imgIcon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -7px;
  margin-right: 10px;
}
.questionstitle {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  line-height: 36px;
  margin-bottom: 10px;
}
.tip {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  line-height: 48px;
}
.scoreNum {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #eb8309;
}
.scoretext {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}
.titletext {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #0b3450;
}
.testTitlediv {
  height: 16px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #e6e6e6;
}
</style>