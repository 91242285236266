// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../src/assets/img/matbg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".banner[data-v-9aa8f290]{width:100%;height:182px}.banner img[data-v-9aa8f290]{width:100%;height:100%}.bg[data-v-9aa8f290]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.main[data-v-9aa8f290]{background-color:#fff}.main .conmain[data-v-9aa8f290]{padding-top:60px;margin-left:60px;height:800px}.main .conmain .name[data-v-9aa8f290]{font-size:21px;font-family:Source Han Sans CN;font-weight:700;color:#080103;margin-bottom:18px}.main .conmain .info[data-v-9aa8f290]{width:649px;height:42px;font-size:14px;font-family:Source Han Sans CN;font-weight:400;color:#999;line-height:28px;margin-bottom:25px}.main .conmain .mode[data-v-9aa8f290]{font-size:14px;color:#333;line-height:36px}.userInfo[data-v-9aa8f290]{font-size:14px;color:#666}.userInfo input[data-v-9aa8f290]{width:480px;height:40px;border:1px solid #ededed;padding-left:15px;margin-bottom:10px}.userInfo input[data-v-9aa8f290]::-webkit-input-placeholder{font-size:14px;color:#ccc}.userInfo input[data-v-9aa8f290]::-moz-placeholder{font-size:14px;color:#ccc}.userInfo input[data-v-9aa8f290]:-ms-input-placeholder{font-size:14px;color:#ccc}.userInfo input[data-v-9aa8f290]::-ms-input-placeholder{font-size:14px;color:#ccc}.userInfo input[data-v-9aa8f290]::placeholder{font-size:14px;color:#ccc}.message[data-v-9aa8f290]{overflow:\"hidden\";height:160px}.message[data-v-9aa8f290] :first-child{float:left;font-size:14px;color:#666}.message[data-v-9aa8f290] :nth-child(2){float:left}.sentBtu[data-v-9aa8f290]{width:100px;height:36px;background:#b50e05;font-size:14px;font-weight:500;color:#fff;line-height:36px;text-align:center;margin-left:45px}", ""]);
// Exports
module.exports = exports;
