<template>
  <div class="maincen">
    <div class="catalog">
      <div class="line"></div>
      <div class="catalogTit left">考试测试列表</div>
    </div>
    <div class="clearfix">
      <div class="adddiv right" @click="addChapter">
        <img class="imgicon" src="../../assets/img/add.png" alt="" />
        新增
      </div>
    </div>
    <div class="center content">
      <el-table class="banjitable" :data="tableData" style="width: 100%">
        <el-table-column prop="name" label="考试测试名称" width="280">
        </el-table-column>
        <el-table-column prop="totalScore" label="总分值"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column label="操作" width="210">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-button @click="addTestClick(scope.row)" type="text" size="small"
              >添加测试题</el-button
            >
            <el-button
              @click="delectClick(scope.row)"
              class="delectebutton"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        :current-page="1"
        @current-change="handleCurrentChangeClass"
        :page-size="param.pageSize"
        background
        layout="prev, pager, next,total"
        :total="totalClass"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible.sync="dialogVisibleChapter"
      class="majorDialogFirst commonDialog"
    >
      <p class="addtext">{{ editChapter ? '编辑考试测试' : '新增考试测试' }}</p>
      <div class="majordiv">
        <span class="majorName">考试测试名称:</span>
        <input
          class="majorInput"
          type="text"
          v-model="addbannerlList.name"
          placeholder="请输入考试测试名称"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="saveButton" @click="saveOrUpdate">保 存</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisibleTest"
      class="majorDialogTest commonDialog"
    >
      <p class="addtext">
        {{ editChapter ? '编辑考试测试' : '选择添加考试测试题' }}
      </p>
      <div class="selectdiv">
        <el-select
          @change="changeSelect"
          v-model="valueSelect"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" style="display: inline-block"
        >批量导入测试题</el-button
      >
      <div class="center content">
        <el-table class="banjitable" :data="tableDataKu" style="width: 100%">
          <el-table-column prop="detailsName" label="考试测试名称" width="250">
          </el-table-column>
          <el-table-column
            :formatter="formatter"
            prop="examinationId"
            label="题型"
          >
          </el-table-column>
          <el-table-column prop="score" label="分值"> </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="150">
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button
                @click="addClick(scope.row)"
                v-if="scope.row.isType == 1"
                type="primary"
                plain
                size="mini"
                >选择添加</el-button
              >
              <el-button
                v-if="scope.row.isType == 2"
                @click="cancleClick(scope.row)"
                type="warning"
                plain
                size="mini"
                >取消选择</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          :current-page="1"
          @current-change="handleCurrentChangeClassKu"
          :page-size="kuParam.pageSize"
          background
          layout="prev, pager, next,total"
          :total="totalClassKu"
        >
        </el-pagination>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  videoInfo,
  myTeachList,
  myTeachListSave,
  updateClass,
  deleteClass,
} from '@/api/center/teach.js'
import {
  queryRollList,
  getQuestionBankList,
  tikuSave,
  tikuDelete,
} from '@/api/course/index.js'
export default {
  data() {
    return {
      activeName: '1',
      dialogVisibleChapter: false,
      editChapter: false,
      examName: sessionStorage.getItem('examName'),
      teachaName: '',
      inputVisible: false,
      inputValue: '',
      questionBankId: window.sessionStorage.getItem('questionBankId'),
      addbannerlList: {
        name: '',
        resourceId: '',
        objectType: 1,
        courseType: 3,
      },
      renderObj: {
        0: '单选题',
        1: '多选题',
        2: '判断题',
        3: '填空题',
        4: '简答题',
      },
      options: [
        {
          value: '0',
          label: '单选题',
        },
        {
          value: '1',
          label: '多选题',
        },
        {
          value: '3',
          label: '填空题',
        },
        {
          value: '2',
          label: '判断题',
        },
        {
          value: '4',
          label: '简答题',
        },
      ],
      valueSelect: '',
      dialogVisible: false,
      dialogVisibleTest: false,
      param: {
        pageNum: 1,
        pageSize: 8,
        courseType: 3,
        objectType: 1,
        resourceId: '',
      },
      kuParam: {
        pageNum: 1,
        pageSize: 6,
        courseType: 3,
        objectType: 1,
        examinationId: '',
        resourceSectionId: '',
      },
      tikyAddParam: {
        courseId: '',
        questionsId: '',
        sectionId: '',
      },
      teamaterialList: [],
      microCourseList: [],
      tableData: [],
      tableDataKu: [],
      id: '',
      totalClass: 0,
      totalClassKu: 0,
      editId: '',
    }
  },
  watch: {},
  computed: {},
  methods: {
    addClick(row) {
      this.tikyAddParam.questionsId = row.id
      tikuSave(this.tikyAddParam).then((res) => {
        this.$message({
          type: 'success',
          message: '添加成功!',
        })
        row.isType = 2
        this.getClassData()
      })
    },
    changeSelect() {
      this.kuParam.examinationId = this.valueSelect
      this.kuParam.pageNum = 1
      this.getQuestionBankListData()
    },
    cancleClick(row) {
      tikuDelete([row.id]).then((res) => {
        this.$message({
          type: 'success',
          message: '取消成功!',
        })
        row.isType = 1
        this.getClassData()
      })
    },
    formatter(row, column) {
      return this.renderObj[row.examinationId]
    },
    addTestClick(row) {
      this.tikyAddParam.sectionId = row.id
      this.kuParam.resourceSectionId = row.id
      this.getQuestionBankListData()
      this.dialogVisibleTest = true
    },
    delectClick(row) {
      this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteClass([row.id]).then((res) => {
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
            this.getClassData()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    saveOrUpdate() {
      this.addbannerlList.resourceId = this.id
      if (!this.editChapter) {
        myTeachListSave(this.addbannerlList).then((res) => {
          this.$message({
            type: 'success',
            message: '添加成功!',
          })
          this.dialogVisibleChapter = false
          this.getClassData()
        })
      } else {
        updateClass(this.addbannerlList).then((res) => {
          this.$message({
            type: 'success',
            message: '修改成功!',
          })
          this.dialogVisibleChapter = false
          this.getClassData()
        })
      }
    },
    getClassData() {
      queryRollList(this.param).then((res) => {
        this.tableData = res.data ? res.data : []
        this.totalClass = res.page.total
      })
    },
    getQuestionBankListData() {
      getQuestionBankList(this.kuParam).then((res) => {
        this.tableDataKu = res.data ? res.data : []
        this.totalClassKu = res.page.total
      })
    },
    getvideoInfo() {
      videoInfo(this.editId).then((res) => {
        this.addbannerlList = res.data
      })
    },
    addChapter() {
      this.editChapter = false
      this.dialogVisibleChapter = true
    },
    addTip() {
      this.$router.push({
        path: '/course/section',
      })
    },
    deleteInfo() {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          this.newmultipleSelection = this.multipleSelection.map((item) => {
            return item.fid
          })
          let res = await api.post(
            '/faculty/deleteIdFaculty',
            this.newmultipleSelection
          )
          this.getRescoures()
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    handleSizeChange(newSize) {},
    handleCurrentChangeClass(val) {
      this.param.pageNum = val
      this.getClassData()
    },
    handleCurrentChangeClassKu(val) {
      this.kuParam.pageNum = val
      this.getQuestionBankListData()
    },
    handleClick(row) {
      this.dialogVisibleChapter = true
      this.editChapter = true
      this.editId = row.id
      this.getvideoInfo()
    },
    showDetail(fid) {
      this.$router.push({
        path: '/teachingmat/Matdetails',
        query: {
          fid: fid,
        },
      })
    },
  },
  created() {
    this.id = sessionStorage.getItem('courseId')
    this.param.resourceId = this.id
    this.tikyAddParam.courseId = sessionStorage.getItem('courseId')
    this.getClassData()
  },
}
</script>
<style lang="scss">
.majorDialog .el-dialog {
  width: 922px;
  height: 700px;
  padding: 0 50px;
}
.majorDialogTest .el-dialog {
  width: 922px;
  height: 725px;
  padding: 0 50px;
}
.majorDialog .el-form-item__label {
  text-align: left;
}
.majorDialog .el-dialog__body {
  padding-left: 0;
  padding: 20px 0;
}
.majorDialog .el-dialog__footer {
  padding: 0;
}
.majorDialog .el-dialog__footer {
  text-align: center;
}
.saveButton {
  width: 400px;
  height: 40px;
  background: #0b3450 !important;
  color: #fff !important;
}
.banjitable {
  margin-top: 20px;
}
.banjitable .el-button--text {
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #0b3450;
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
}
.banjitable .el-table__header th,
.banjitable .el-table__header tr {
  background: #fbfbfb !important;
}
.banjitable .el-table__header thead {
  border: 1px solid #e6e6e6;
}
.banjitable .el-table__header {
  border-collapse: inherit !important;
}
.classdiv .el-table td,
.el-table th.is-leaf {
  border-bottom: 1px dashed #ebeef5;
}
.delectebutton span {
  color: #ff7500;
}
</style>
<style lang="scss" scoped>
.center {
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
.selectdiv {
  margin-top: 17px;
  margin-bottom: 10px;
  display: inline-block;
  margin-right: 20px;
}
.majorName {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-right: 5px;
}
.majorInput {
  border: none;
  outline: none;
  width: 253px;
}
.majordiv {
  width: 360px;
  padding: 0 20px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  line-height: 42px;
  margin-top: 13px;
}
.addtext {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}
.adddiv {
  cursor: pointer;
  margin-top: 10px;
}
.imgicon {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}
.metTit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  margin: 10px 0;
}
.metuser {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  color: rgba(102, 102, 102, 1);
}
.imgBox {
  width: 100%;
  height: 181px;
  img {
    width: 100%;
    height: 100%;
  }
}
.contents {
  overflow: hidden;
  .contentsTit {
    margin-bottom: 20px;
    float: left;
    font-size: 14px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
  }
}
.convideo {
  height: 35px;
  overflow: hidden;
  div:nth-child(1) {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    float: left;
  }
  div:nth-child(2) {
    float: right;
  }
}
.el-dialog__body .content .el-table__body-wrapper .el-button--mini,
.el-dialog__body .content .el-table__body-wrapper .el-button--small {
  padding: 7px 15px !important;
}
</style>
