import service from '@/utils/request.js'
export function homeList(data) {
  return service.request({
    method: 'get',
    url: '/goldcourse/web/onlinelearningresource/geCourseList',
    params: data
  });
}







