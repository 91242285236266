// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/classbg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".joinStudent[data-v-ad2f8770]{cursor:pointer}.majordiv[data-v-ad2f8770]{width:360px;padding:0 20px;height:42px;background:#fff;border:1px solid #e6e6e6;line-height:42px;margin-top:9px}.majorName[data-v-ad2f8770]{font-size:14px;font-family:Source Han Sans CN;font-weight:400;color:#666;margin-right:5px}.majorInput[data-v-ad2f8770]{border:none;outline:none;width:290px}.adddiv[data-v-ad2f8770]{cursor:pointer}.classtop[data-v-ad2f8770]{width:1080px;height:92px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0;margin:0 auto 10px auto}.classtop .left[data-v-ad2f8770]{margin:20px 0 20px 20px}.classtop .left img[data-v-ad2f8770]{width:100px;height:56px;display:inline-block;margin-right:20px}.classtop .left div[data-v-ad2f8770]{display:inline-block;vertical-align:top;font-size:0}.classtop .left div p[data-v-ad2f8770]{font-size:16px;font-weight:500;color:#fff;line-height:28px}.classtop .left div span[data-v-ad2f8770]{font-size:12px;font-weight:400;color:#fff;line-height:18px;opacity:.6}.classtop .right[data-v-ad2f8770]{float:right;margin:30px}.classtop .right button[data-v-ad2f8770]{width:100px;height:35px;background:#3699ff;border-radius:2px;color:#fff;border:0}", ""]);
// Exports
module.exports = exports;
