import { render, staticRenderFns } from "./numteaching.vue?vue&type=template&id=1b07422e&scoped=true"
import script from "./numteaching.vue?vue&type=script&lang=js"
export * from "./numteaching.vue?vue&type=script&lang=js"
import style0 from "./numteaching.vue?vue&type=style&index=0&id=1b07422e&prod&lang=scss"
import style1 from "./numteaching.vue?vue&type=style&index=1&id=1b07422e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b07422e",
  null
  
)

export default component.exports