import Vue from 'vue'
// import { Button } from 'element-ui'
// import { Message } from 'element-ui'
// 格式化
import { Menu, Submenu, MenuItem, Form, Input, FormItem, Button, Message, Container, Header, Aside, Main, Breadcrumb, BreadcrumbItem, Card, Row, Col, Table, TableColumn, Switch, Tooltip, Pagination, Dialog, Tag, MessageBox, Tree, Select, Option, Cascader, Alert, Tabs, TabPane, Steps, Step, Checkbox, CheckboxGroup, Upload, DatePicker, Radio, RadioGroup, TimePicker, Carousel, CarouselItem, CollapseItem, Collapse, DropdownMenu, DropdownItem, Dropdown, Rate,Loading } from 'element-ui'

Vue.use(Button)
Vue.use(Form)
Vue.use(Input)
Vue.use(FormItem)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Card)
Vue.use(Row)
Vue.use(Col)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)
Vue.use(Tooltip)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Tag)
Vue.use(Tree)
Vue.use(Select)
Vue.use(Option)
Vue.use(Cascader)
Vue.use(Alert)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Upload)
Vue.use(DatePicker)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(TimePicker)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(CollapseItem)
Vue.use(Collapse)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Rate)
Vue.use(Loading)

Vue.prototype.$message = Message
// Vue.prototype.$loading = Loading
Vue.prototype.$confirm = MessageBox.confirm
    // 挂载到原型对上，可以用this.直接使用