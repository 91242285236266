<template>
  <div class="courseCard">  
    <el-row>
      <el-col :style="index/4 >=1 ? {marginTop:'10px'} : {}" :span="5" v-for="(o, index) in 1" :key="o" :offset="index%4 > 0 ? 1 : 0">
        <el-card :body-style="{ padding: '0px'}" shadow="hover">
          <img src="../../../assets/img/cover-logo.png" class="image">
          <div style="padding-left: 14px;padding-top: 14px;">
            <div class="name">教学课件制作</div>
            <div class="teacher" style="padding-bottom:14px;margin-top:10px">主讲人：张军教授</div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-pagination
      background
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      :total="1000">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data(){
    return{
      currentDate: new Date()
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-pagination{
    margin-top: 40px;
    margin-left: -20px;
    margin-bottom: 30px;
  }
  .name{
    font-size: 12px;
    color: #333333;
  }
  .teacher{
    font-size: 12px;
    color: #999999;
  }
  .time {
    font-size: 13px;
    color: #999;
  }
  
  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {
    width: 100%;
    height: 128px;
    display: block;
  }

  .clearfix:before,
  .clearfix:after {
      display: table;
      content: "";
  }
  
  .clearfix:after {
      clear: both
  }
</style>