<template>
  <div>
    <div class="bg">
      <div class="main">
        <div class="companyInfo">
          <el-row :gutter="84">
            <el-col :span="17">
              <div class="grid-content bg-purple">
                <div class="parentdiv">
                  <img
                    class="logo"
                    src="../../assets/img/footerlogo.png"
                    alt=""
                  />
                  <div class="footnav">
                    <span class="spantext" @click="gotoIndex">首页</span>
                    <span class="spanColor">|</span>
                    <span class="spantext" @click="gotoStudy">在线学习</span>
                    <span class="spanColor">|</span>
                    <span class="spantext" @click="gotoResource">资源库</span>
                  </div>
                </div>
                <div class="infoText mt">
                  由梧桐花教育平台推出，让每一个有提升愿望的用户能够学到 中国知
                  名高校的课程，
                </div>
                <p class="infoText">并获得专业机构认证。</p>
              </div>
            </el-col>
            <!-- <el-col :span="9">
                            <div class="grid-content bg-purple adr">
                                <img src="../../assets/img/location.png" alt="">
                                <div>
                                    <div class="addr">郑州市高新技术产业开发区冬青街26号河南电子商务产业园</div>
                                    <div class="phone">15638881604</div>
                                </div>
                            </div>
                        </el-col> -->
            <el-col :span="7">
              <div class="grid-content bg-purple">
                <div class="qrCode">
                  <img class="qrcImg" src="../../assets/img/qrcodeLogo.jpg" />
                  <span>扫一扫体验小程序</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="Website">
          <div>Copyright ©2020 梧桐花教育集团 版权所有 <a href="https://beian.miit.gov.cn" class="cursor">豫ICP备16014068-3号</a></div>
          <div>技术支持：梧桐花教育信息技术有限公司</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  methods: {
    gotoIndex() {
      this.$router.push('/')
    },
    gotoStudy() {
      this.$router.push('/onLine')
    },
    gotoResource() {
      this.$router.push({
        path: '/resource',
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.cursor{
  cursor: pointer;
  color: rgba(255, 255, 255, 0.3);
  text-decoration: none;
}
.parentdiv {
  position: relative;
}
.footnav {
  position: absolute;
  left: 174px;
  top: 80px;
}
.spantext {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ababab;
  margin: 0 20px;
  margin-top: -5px;
  cursor: pointer;
}
.spanColor {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ababab;
  margin-top: -5px;
}
.bg {
  width: 100%;
  background: #1c2025;
  // position:absolute;
  height: 259px;
  // bottom: 0px;
}
.mt {
  margin-top: 16px;
}
.companyInfo {
  height: 200px;
  .logo {
    margin-top: 58px;
    width: 166px;
  }
  .infoText {
    color: #999999;
    line-height: 16px;
  }
}
.adr {
  margin-top: 93px;
  display: flex;
  img {
    vertical-align: middle;
    width: 25px;
    height: 30px;
  }
  .addr {
    font-size: 12px;
    margin-top: 2px;
    color: rgba(153, 153, 153, 1);
    margin-left: 15px;
  }
  .phone {
    font-size: 28px;
    color: #bcbcbc;
    margin-top: 20px;
    margin-left: 15px;
  }
}
.qrCode {
  margin-top: 30px;
  float: right;
  width: 120px;
  height: 140px;
  background: rgba(249, 249, 249, 1);
  .qrcImg {
    width: 100px;
    height: 100px;
    background: pink;
    margin: 10px;
  }
  span {
    font-size: 12px;
    font-weight: 500;
    color: rgba(102, 102, 102, 1);
    line-height: 18px;
    text-align: center;
    display: block;
  }
}
.Website {
  font-size: 12px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.3);
  padding-top: 10px;
  text-align: center;
}
</style>
