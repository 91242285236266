<template>
  <div>
    <div>
      <div class="main bgmain">
        <div class="listNav">
          <div class="main">
            <i class="iconfont icon-shouye"></i>
            <span>您的位置：</span>
            <span>首页</span>
            <span> > </span>
            <span>资源库</span>
            <span> > </span>
            <span>素材资源详情</span>
          </div>
        </div>
        <div class="detMain clearfix">
          <div class="grid-content bg-purple left">
            <div class="courseCover">
              <img :src="Info.courseCoverUrl" alt />
            </div>
          </div>
          <div class="grid-content bg-purple left">
            <div class="title">{{ Info.name }}</div>
            <div class="info">
              <div class="left">上传人: {{ Info.createByName }}</div>
            </div>
            <div class="info">
              <div class="left">上传时间: {{ Info.createTime }}</div>
            </div>
            <div class="info">
              <div class="left">
                文件大小: {{ Info.coursePublicityImagesFileSize }}KB
              </div>
            </div>
            <div class="info">
              <div class="left">所属专业: {{ Info.professionalName }}</div>
            </div>
            <div class="info">
              <div class="left">资源类型: {{ Info.resourceTypeName }}</div>
            </div>
            <div class="info">
              <div class="left">资源分类: {{ Info.classifyName }}</div>
            </div>
            <div class="operation">
              <div
                @click="
                  viewFile(
                    Info.coursePublicityImagesUrl,
                    Info.coursePublicityImagesFileName
                  )
                "
                class="left sudtyButton"
              >
                查看
              </div>
              <div
                @click="
                  down(
                    Info.coursePublicityImagesUrl,
                    Info.coursePublicityImagesFileName
                  )
                "
                class="left sudtyButton download"
              >
                下载
              </div>
            </div>
          </div>
        </div>
        <div class="detMain contont">
          <el-tabs v-model="activeName" class="cou-infor">
            <el-tab-pane label="资源详情" name="first">
              <div class="catalog">
                <div class="line"></div>
                <div class="catalogTit">资源详情</div>
              </div>
              <div class="catalogText" v-html="Info.content"></div>
            </el-tab-pane>
          </el-tabs>
          <!-- //// -->
          <!-- 微课播放器 -->
          <el-dialog
            :title="microTitle"
            :visible.sync="dialogVisible"
            width="30%"
          >
            <div class="player">
              <video-player
                class="video-player vjs-custom-skin"
                ref="videoPlayer"
                :playsinline="true"
                style="object-fit: fill"
                :options="playerOptions"
                :x5-video-player-fullscreen="true"
                @pause="onPlayerPause($event)"
                @play="onPlayerPlay($event)"
                @fullscreenchange="onFullscreenChange($event)"
                @click="fullScreen"
                id="video1"
              ></video-player>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible = false"
                >确 定</el-button
              >
            </span>
          </el-dialog>
          <!-- /微课播放器 -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { api } from '../../assets/api/axios'
import { mapMutations, mapState, mapGetters } from 'vuex'
import { courseDetail } from '@/api/detail/index.js'
export default {
  data() {
    return {
      activeName: 'first',
      dialogVisibleCommont: false,
      value: 4,
      radio: 3,
      matitem: [{ id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }],
      demodialogVisible: false,
      matdialogVisible: false,
      textarea: '',
      dialogVisible: false,
      playerOptions: {
        //playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '4:3', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4',
            src:
              'http://wutonghua-oss.oss-cn-qingdao.aliyuncs.com/jiaofa/onlinechapter/03墙下带形基础.mp4',
          },
        ],
        poster: '',
        width: '',
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          // timeDivider: true,
          // durationDisplay: true,
          // remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      ruleForm: {
        teamaterialId: '', //教材ID
        phone: '',
        schName: '',
        department: '',
        post: '',
        email: '',
        teaCourse: '',
        senDarea: '',
        jobNum: '',
      },
      rules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
      },
      Info: {},
      fid: '',
      catalog: [
        {
          fid: '123456',
          title: '考试测试题目名称',
          num: 10,
          allScore: 100,
          peopleNum: 200,
        },
      ],
      typeList: [],
      total: 0,
      pageBean: {
        pageNum: 1,
        pageSize: 5,
        typeId: '',
        teamatId: '',
      },
      resPackage: [],
      message: '',
      showmsg: false,
      showItem: true,
      microTitle: '',
      enableApply: '',
      sampleStatus: '',
      collectionFlag: 0,
      statusText: '抱歉，由于权限问题，样书申请只限老师账号,请进行教师认证~',
      resbtu: false,
      res: true,
      catalogText: '',
      id: '',
      Info: {},
      fileArr: [
        'pdf',
        'jpg',
        'png',
        'JPG',
        'jpeg',
        'JPEG',
        'PNG',
        'gif',
        'mp4',
      ],
    }
  },
  watch: {
    dialogVisible: function (val) {
      console.log(val)
      if (val == false) {
        this.$refs.videoPlayer.player.pause()
      }
    },
  },
  created() {
    let token = window.sessionStorage.getItem('token')
    if (token == '' || token == null) {
    } else {
      // this.isEnableApply()
    }
    this.id = this.$route.query.id
    this.getcourseDetail()
  },
  computed: {
    ...mapGetters([
      'resPhone',
      'resFid',
      'resStatus',
      'resSchName',
      'resName',
      'resjobNum',
      'restypeFlag',
      'resschType',
    ]),
  },
  methods: {
    getExtension(name) {
      return name.substring(name.lastIndexOf('.') + 1)
    },
    viewFile(filePath, name) {
      if (this.fileArr.indexOf(this.getExtension(name)) > -1) {
        if (this.getExtension(name) == 'mp4') {
          this.MicroCourse(name, filePath)
        } else {
          window.open(filePath)
        }
      } else {
        this.$message.warning('此文件暂不支持在线查看,请下载后查看')
      }
    },
    down(filePath, fileName) {
      downloadUrlFile(filePath)
      function downloadUrlFile(url) {
        url = url.replace(/\\/g, '/')
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url, true)
        xhr.responseType = 'blob'
        //xhr.setRequestHeader('Authorization', 'Basic a2VybWl0Omtlcm1pdA==');
        xhr.onload = () => {
          if (xhr.status === 200) {
            // 获取文件blob数据并保存
            saveAs(xhr.response, fileName)
          }
        }

        xhr.send()
      }

      /**
       * URL方式保存文件到本地
       * @param data 文件的blob数据
       * @param name 文件名
       */
      function saveAs(data, name) {
        var urlObject = window.URL || window.webkitURL || window
        var export_blob = new Blob([data])
        var save_link = document.createElementNS(
          'http://www.w3.org/1999/xhtml',
          'a'
        )
        save_link.href = urlObject.createObjectURL(export_blob)
        save_link.download = name
        save_link.click()
      }
    },
    getcourseDetail() {
      courseDetail(this.id).then((res) => {
        this.Info = res.data
      })
    },
    MicroCourse(fileName, filePath) {
      this.dialogVisible = true
      // this.microTitle = fileName
      this.playerOptions.sources[0].src = filePath
    },
    // 视频方法
    fullScreen() {
      const player = this.$refs.videoPlayer.player
      player.requestFullscreen() //调用全屏api方法
      player.isFullscreen(true)
      player.play()
    },
    onPlayerPlay(player) {
      player.play()
    },
    onPlayerPause(player) {
      // alert("pause");
    },
  },
}
</script>
<style lang="scss">
.download {
  margin-left: 10px;
}
.textarea {
  resize: none;
  height: 132px;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  margin-top: 10px;
  padding: 10px 20px;
  width: 458px;
  line-height: 20px;
}
.ratediv {
  width: 480px;
  height: 86px;
  background: #f8f8f8;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.commontDialog .el-dialog__body {
  padding: 0 40px;
}
.commontDialog .el-dialog__footer {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 60px;
}
.commontDialog .el-dialog {
  width: 580px;
  height: 434px;
}
.confirmButton {
  height: 40px;
  background: #0b3450 !important;
  width: 500px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  span {
    color: #fefefe;
  }
}
.commontDialog .el-dialog__headerbtn {
  width: 40px;
  height: 40px;
  background: #0b3450;
  right: 0;
  top: 0;
}
.commontDialog .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}
</style>

<style lang="scss" scoped>
.pingContont {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  margin-top: 9px;
  margin-bottom: 9px;
}
.pingjiaRight {
  width: 827px;
}
.pingjiali {
  border-bottom: 1px solid #f8f8f8;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.pingjiaLeft {
  width: 41px;
  margin-right: 19px;
  p {
    text-align: center;
  }
}
.userAvatar {
  width: 41px;
  height: 41px;
  border-radius: 50%;
}
.pingjia {
  position: relative;
  padding-left: 25px;
  height: 16px;
  font-size: 14px;
  cursor: pointer;
  img {
    position: absolute;
    top: -1px;
    left: 0;
  }
}
.getScore {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #eb8309;
  margin-left: 7px;
}
.VerticaLine {
  margin: 0 10px;
}
.subjectTitle {
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-bottom: 10px;
}
.contont {
  padding-top: 20px;
}
.commonText {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}
.studyNum {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  line-height: 32px;
  margin-left: 20px;
}
.nav {
  width: 960px;
  margin: 0 auto;
  height: 42px;
}
.bgmain {
  background: #fff;
}
.detMain {
  margin: 0 60px 0;
  position: relative;
}
.courseCover {
  height: 347px;
  width: 600px;
  margin-right: 20px;
  img {
    width: 100%;
    height: 100%;
  }
}
.star {
  margin-top: 10px;
  height: 38px;
  border: 1px solid rgba(224, 224, 224, 1);
  width: 100%;
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  line-height: 40px;
  text-align: center;
}
.title {
  width: 340px;
  line-height: 36px;
  font-size: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 10px;
}
.info {
  overflow: hidden;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  .left {
    float: left;
    width: 330px;
    font-size: 12px;
    color: rgba(102, 102, 102, 1);
  }
  .right {
    float: left;
    font-size: 14px;
    color: rgba(102, 102, 102, 1);
  }
}
.link {
  width: 100%;
  min-height: 40px;
  background: rgba(245, 245, 245, 1);
  margin-top: 20px;
  line-height: 40px;
  padding-left: 10px;
  overflow: hidden;
  div:nth-child(1) {
    font-size: 14px;
    color: rgba(153, 153, 153, 1);
    float: left;
  }
  div:nth-child(2) {
    // width: 100px;
    font-size: 14px;
    color: rgba(211, 111, 22, 1);
    float: left;
  }
}
.tip {
  margin: 10px 0 25px;
  font-size: 13px;
  color: rgba(204, 204, 204, 1);
}
.operation {
  overflow: hidden;
  margin-top: 0px;
  position: absolute;
  bottom: 0px;
  .sudtyButton {
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    width: 96px;
    height: 32px;
    background: #ffbc1c;
    border-radius: 4px;
  }
}
.catalog {
  overflow: hidden;
  margin: 20px 0;
  .line {
    width: 6px;
    height: 14px;
    background: #00427f;
    float: left;
  }
  .catalogTit {
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
    color: #000;
  }
}
.catalogText {
  margin-top: 20px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 24px;
}
.contentsTitmulu {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  line-height: 24px;
  margin: 10px 0;
}
.contents {
  overflow: hidden;
  height: 40px;
  background: #fbfbfb;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 15px 20px;
  .contentsTit {
    float: left;
    font-size: 16px;
    font-family: SourceHanSansCN;
    font-weight: 700;
    color: rgba(102, 102, 102, 1);
  }
  .btn {
    float: right;
    color: rgba(102, 102, 102, 1);
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    position: relative;
    padding-left: 20px;
    margin-left: 16px;
    img {
      position: absolute;
      top: 8px;
      left: 0;
    }
  }
}
.contentsInfo {
  div {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    line-height: 35px;
  }
}
.convideo {
  height: 34px;
  background: #fbfbfb;
  border-radius: 4px;
  line-height: 34px;
  margin-bottom: 5px;
  padding: 0 20px;

  div:nth-child(2) {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    float: left;
  }
  div:nth-child(3) {
    float: right;
  }
}
.iconimg {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}
.matNav {
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  display: float;
  margin-top: 20px;
  overflow: hidden;
  height: 70px;
  line-height: 40px;
  div {
    float: left;
  }
  .signUp {
    position: relative;
  }
  .iconTop {
    position: absolute;
    top: 9px;
    right: -15px;
    &:hover {
      color: #0b3450;
    }
  }
  .iconBot {
    position: absolute;
    bottom: 9px;
    right: -15px;
    &:hover {
      color: #0b3450;
    }
  }
  .dataTime {
    position: relative;
    margin-right: 30px;
  }
  .search {
    width: 190px;
    height: 34px;
    margin-right: 20px;
    input {
      border: none;
      background: rgba(0, 0, 0, 0.1);
      width: 190px;
      height: 34px;
      padding-left: 10px;
    }
  }
  .searchBut {
    width: 77px;
    height: 34px;
    background: rgba(181, 14, 5, 1);
    font-size: 14px;
    color: rgba(255, 255, 255, 1);
    line-height: 34px;
    text-align: center;
    float: right;
    margin-top: 4px;
  }
}
.uploadMat {
  overflow: hidden;
  :nth-child(1) {
    float: left;
    width: 180px;
    height: 42px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(224, 224, 224, 1);
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    line-height: 42px;
    text-align: center;
  }
  :nth-child(2) {
    float: left;
    height: 42px;
    line-height: 42px;
    color: rgba(153, 153, 153, 1);
    font-size: 12px;
    margin-left: 20px;
  }
}
.matItem {
  margin-top: 20px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
  .itemImg {
    width: 100%;
    height: 86px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .itemTit {
    overflow: hidden;
    margin: 10px 0 20px;
    :nth-child(1) {
      float: left;
      font-size: 14px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
    }
    :nth-child(2) {
      float: right;
      font-size: 14px;
      color: rgba(102, 102, 102, 1);
      cursor: pointer;
    }
  }
  .itemInfo {
    font-size: 13px;
    color: rgba(102, 102, 102, 1);
    line-height: 24px;
  }
}
.sample {
  :nth-child(1) {
    margin: 0 auto;
    text-align: center;
    margin-bottom: 60px;
  }
  :nth-child(2) {
    margin: 0 auto;
    width: 360px;
    height: 36px;
    background: rgba(181, 14, 5, 1);
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 36px;
    text-align: center;
    margin-bottom: 40px;
  }
}
.matbox {
  margin: 0 30px;
  .matTip {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    // margin-bottom: 20px;
    margin: 0 0 20px 30px;
  }
  .userInfo {
    overflow: hidden;
    font-size: 14px;
    color: rgba(102, 102, 102, 1);
    .InfotTit {
      float: left;
      line-height: 40px;
    }
    input {
      float: left;
      width: 496px;
      height: 40px;
      border: 1px solid rgba(237, 237, 237, 1);
      padding-left: 15px;
      margin-bottom: 10px;
      &::placeholder {
        font-size: 14px;
        color: rgba(204, 204, 204, 1);
      }
    }
  }
  .message {
    overflow: 'hidden';
    height: 160px;
    :nth-child(1) {
      float: left;
      font-size: 14px;
      color: rgba(102, 102, 102, 1);
    }
    :nth-child(2) {
      float: left;
    }
  }
  .subbut {
    width: 560px;
    height: 36px;
    background: rgba(181, 14, 5, 1);
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    line-height: 36px;
    margin: 20px 0 20px;
  }
}
.message {
  font-size: 18px;
  font-weight: 500;
  color: rgba(102, 102, 102, 1);
  line-height: 24px;
  margin: 40px 40px 200px;
}
.detBg {
  margin-top: 4px;
  background-color: #f8f8f8;
  .listNav {
    .main {
      padding-left: 60px;
      width: 1020px;
    }
  }
}
</style>
<style lang="scss">
@import '../../assets/css/mattabs.scss';
</style>
