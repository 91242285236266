<template>
  <div class="maincen">
    <div class="catalog">
      <div class="line"></div>
      <div class="catalogTit left">考试测试列表</div>
    </div>
    <div class="center content">
      功能开发中...
    </div>
  </div>
  
</template>

<script>
export default {}
</script>
<style lang="scss" >
</style>
<style lang="scss" scoped>
</style>
