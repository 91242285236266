<template>
  <div>
    <div class="nav">
      <div class="main">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>在线学习</el-breadcrumb-item>
          <el-breadcrumb-item>在线学习详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="main">
      <div class="centest">
        <div class="testTit clearfix">
          <span class="left"> {{ kaoName }} </span>
          <p class="right numText">
            (<span class="colorText">{{ num }}</span
            ><span class="numText">/{{ Info.total }})</span>
          </p>
        </div>
        <div class="testTip">
          本次测试通过单选、多选题、判断、填空和简答题对学习效果进行测试。
        </div>
        <div>
          <!-- 单选题 -->
          <div v-if="singlesshow == true">
            <div class="Tit">{{ itemInfo.detailsName }}</div>
            <el-radio-group v-model="ruleForm.single">
              <el-radio :label="item.id" v-for="(item, index) in single" :key="index">
                {{ item.id }}
                .
                {{ item.value }}</el-radio
              >
            </el-radio-group>
            <div class="clearfix">
              <div
                v-show="showNext"
                class="send left"
                @click="getTestItemNext(ruleForm.single)"
              >
                下一题
              </div>
              <div class="submit left" @click="submitButton(ruleForm.single)">
                提交测试
              </div>
            </div>
          </div>
          <!-- 多选题 -->
          <div v-if="multipleshow == true">
            <div class="Tit">{{ itemInfo.detailsName }}</div>
            <div v-for="(item, index) in multiple" :key="index">
              <el-checkbox-group v-model="ruleForm.type">
                <el-checkbox :label="item.id" name="type">
                  {{ item.id }}
                  .
                  {{ item.value }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
            <div class="clearfix">
              <div
                v-show="showNext"
                class="send left"
                @click="getTestItemNext(ruleForm.type)"
              >
                下一题
              </div>
              <div class="submit left" @click="submitButton(ruleForm.type)">
                提交测试
              </div>
            </div>
          </div>
          <!-- 判断题 -->
          <div v-if="judgeshow == true">
            <div class="Tit">{{ itemInfo.detailsName }}</div>
            <div v-for="(item, index) in judge" :key="index">
              <el-radio-group label v-model="ruleForm.judge">
                <el-radio label="A">
                  <span class="icoText">A.</span>
                  <img src="../../assets/img/right.png" alt class="icoLogo" />
                </el-radio>
                <el-radio label="B">
                  <span class="icoText">B.</span>
                  <img src="../../assets/img/false.png" alt class="icoLogo" />
                </el-radio>
              </el-radio-group>
              <div class="clearfix">
                <div
                  v-show="showNext"
                  class="send left"
                  @click="getTestItemNext(ruleForm.judge)"
                >
                  下一题
                </div>
                <div class="submit left" @click="submitButton(ruleForm.judge)">
                  提交测试
                </div>
              </div>
            </div>
          </div>
          <!-- 填空题 -->
          <div v-if="blanksshow == true">
            <div class="Tit">{{ itemInfo.detailsName }}</div>
            <el-input
              v-model="ruleForm.blanks"
              placeholder="请输入正确答案"
            ></el-input>
            <div class="clearfix">
              <div
                v-show="showNext"
                class="send left"
                @click="getTestItemNext(ruleForm.blanks)"
              >
                下一题
              </div>
              <div class="submit left" @click="submitButton(ruleForm.blanks)">
                提交测试
              </div>
            </div>
          </div>
          <!-- 简答题 -->
          <div v-if="shortshow == true">
            <div class="Tit">{{ itemInfo.detailsName }}</div>
            <el-input
              type="textarea"
              v-model="ruleForm.short"
              placeholder="请输入正确答案"
            ></el-input>
            <div class="clearfix">
              <div
                v-show="showNext"
                class="send left"
                @click="getTestItemNext(ruleForm.short)"
              >
                下一题
              </div>
              <div class="submit left" @click="submitButton(ruleForm.short)">
                提交测试
              </div>
            </div>
          </div>
        </div>
        <div v-show="Accuracy">
          <div class="ans">正确率：{{ AccuracyText }}</div>
          <div class="sendgo">返回立体教材详情</div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisibleCommont" class="NumDialog">
      <span>测试答案一旦提交，分数将不能更改，是否确认提交？</span>
      <span slot="footer" class="dialog-footer">
        <el-button class="testButton" @click="confirmDialog">确 定</el-button>
        <el-button class="backTest" @click="dialogVisibleCommont = false"
          >返回测试</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../assets/api/axios'
import { getExaminationPaperTopic } from '@/api/detail/index.js'
export default {
  data() {
    return {
      ruleForm: {
        single: '',
        judge: '',
        blanks: '',
        short: '',
        name: '',
        type: [],
      },
      dialogVisibleCommont: false,
      single: [],
      multiple: [],
      judge: [],
      blanks: [],
      short: [],
      singlesshow: true,
      multipleshow: false,
      judgeshow: false,
      blanksshow: false,
      shortshow: false,
      send: false,
      type: [],
      answerList: [],
      test: {
        chapterId: '',
        pageNum: 0,
        pageSize: 1,
      },
      Accuracy: false,
      AccuracyText: '',
      params: {
        pageNum: 1,
        sectionId: '',
        courseId: '',
        buttonType: '',
        questionsId: '',
        submitAnswer: '',
      },
      Info: {},
      itemInfo: {},
      isLastPage: false,
      lastAnswer: '',
      num: 1,
      showNext: true,
      kaoName: '',
    }
  },
  created() {
    this.params.sectionId = this.$route.query.id
    this.params.courseId = this.$route.query.courseId
    this.kaoName = this.$route.query.kaoName
    this.params.buttonType = 1
    this.getlistSection()
  },
  methods: {
    // 处理选项
    // changeOption(list) {
    //   var options = []
    //   list.forEach((item) => {
    //     var list = item.split(':')
    //     const key = list[0]
    //     const value = list[1]
    //     var data = {
    //       key: key,
    //       value: value,
    //     }
    //     options.push(data)
    //   })
    //   return options
    // },
    confirmDialog() {
      this.params.buttonType = 3
      this.getInfo(this.lastAnswer)
    },
    getInfo(ans) {
      if (this.itemInfo.questionsId) {
        this.params.questionsId = this.itemInfo.questionsId
      }
      if (ans) {
        if (ans instanceof Array) {
          this.params.submitAnswer = ans.join('')
        } else {
          this.params.submitAnswer = ans
        }
      }
      this.getlistSection()
    },
    getTestItemNext(ans) {
      this.num++
      this.params.pageNum++
      this.params.buttonType = 2
      this.getInfo(ans)
    },
    getlistSection() {
      getExaminationPaperTopic(this.params).then((res) => {
        if (res.data.hasOwnProperty('totalValue')) {
          this.dialogVisibleCommont = false
          this.$router.push({
            path: '/viewTest',
            query: { id: this.$route.query.id },
          })
          return
        }
        if (this.params.pageNum > 1) {
          this.clear()
        }
        this.singlesshow = false
        this.multipleshow = false
        this.judgeshow = false
        this.blanksshow = false
        this.shortshow = false
        if (res.data.list[0].examinationId == 0) {
          this.single = JSON.parse(res.data.list[0].tryOptions)
          this.singlesshow = true
        } else if (res.data.list[0].examinationId == 2) {
          this.judge[0] = JSON.parse(res.data.list[0].tryOptions)
          this.judgeshow = true
        } else if (res.data.list[0].examinationId == 1) {
          this.multiple = JSON.parse(res.data.list[0].tryOptions)
          this.multipleshow = true
        } else if (res.data.list[0].examinationId == 3) {
          this.blanksshow = true
        } else if (res.data.list[0].examinationId == 4) {
          this.shortshow = true
        }
        this.Info = res.data
        this.itemInfo = res.data.list[0]
        this.isLastPage = res.data.isLastPage
        this.sentbut(res.data.isLastPage)
      })
    },
    submitButton(ans) {
      if (!this.isLastPage) {
        this.$message.error('您还没有答完，不能提交')
        return
      }
      this.lastAnswer = ans
      this.dialogVisibleCommont = true
    },
    clear() {
      this.single = []
      this.multiple = []
      this.judge = []
      this.blanks = []
      this.short = []
      this.ruleForm.single = ''
      this.ruleForm.judge = ''
      this.ruleForm.blanks = ''
      this.ruleForm.short = ''
      this.ruleForm.type = []
    },
    sentbut(last) {
      if (last == true) {
        this.send = true
        this.showNext = false
      }
    },
  },
}
</script>
<style lang="scss">
.colorText {
  color: #13a5ec;
  font-size: 16px;
}
.NumDialog .el-dialog__headerbtn {
  width: 40px;
  height: 40px;
  background: #0b3450;
  right: 0;
  top: 0;
}
.NumDialog .el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}
.NumDialog .backTest {
  width: 94px;
  height: 32px;
  border: 1px solid #cccccc;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #13a5ec;
  padding: 0;
}
.NumDialog .el-dialog__body {
  text-align: center;
  padding: 0;
  margin-top: 50px;
}
.NumDialog .el-dialog__footer {
  text-align: center;
  margin-top: 30px;
  padding-bottom: 60px;
}
.NumDialog .testButton {
  width: 94px;
  height: 32px;
  background: #13a5ec;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  padding: 0;
  &:hover {
    background: #13a5ec;
    color: #ffffff;
  }
}
</style>
<style lang="scss" scoped>
.centest {
  margin: 0 auto;
  width: 940px;
  min-height: 600px;
  background: rgba(255, 255, 255, 1);
  padding: 30px 80px 0 80px;
  .testTit {
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    line-height: 48px;
    border-bottom: 1px solid #d2d2d2ff;
    // padding-bottom: 5px;
  }
  .testTip {
    padding-top: 20px;
    font-size: 13px;
    color: rgba(153, 153, 153, 1);
    line-height: 18px;
  }
  .send {
    color: #fff;
    text-align: center;
    line-height: 32px;
    margin: 30px 0 0 0;
    width: 94px;
    height: 32px;
    background: #13a5ec;
    cursor: pointer;
    font-size: 16px;
  }
  .submit {
    text-align: center;
    line-height: 32px;
    margin: 30px 0 0 0;
    width: 94px;
    height: 32px;
    background: #cccccc;
    cursor: pointer;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #666666;
    margin-left: 10px;
  }
  .sendgo {
    width: 160px;
    height: 36px;
    background: rgba(181, 14, 5, 1);
    color: #fff;
    text-align: center;
    line-height: 36px;
    margin: 30px 0 0 0;
  }
}
.Tit {
  font-size: 14px;
  color: rgba(51, 51, 51, 1);
  line-height: 48px;
}
.ans {
  font-size: 38px;
  color: rgba(51, 51, 51, 1);
  line-height: 48px;
  margin: 50px 0 200px;
}
</style>
<style lang="scss">
.numText {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #333333;
}
.centest {
  .el-form-item__content {
    margin-left: 0 !important;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
  }
  .el-form-item__label {
    float: none;
    padding-bottom: 10px;
  }
  .el-radio {
    display: block;
    height: 40px;
  }
  .el-radio__input.is-checked .el-radio__inner {
    // background: #666666ff;
    // border-color: #666666ff;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: rgba(51, 51, 51, 1);
  }
  .el-form-item {
    margin-bottom: 0;
  }
  .icoLogo {
    margin-left: 8px;
  }
  .icoText {
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
  }
  .el-input {
    width: 480px;
  }
  .el-input__inner {
    border-radius: 0;
  }
  .el-textarea {
    width: 480px;
  }
  .el-textarea__inner {
    height: 200px !important;
  }
  .el-checkbox {
    width: 100%;
  }
}
</style>