import service from '@/utils/request.js'
export function courseDetail(id) {
  return service.request({
    method: 'get',
    url: `/goldcourse/web/onlinelearningresource/info/${id}`,
  });
}

export function findCourseTestPaperLst(data) {
  return service.request({
    method: 'get',
    url: `/goldcourse/web/onlinelearningresource/findCourseTestPaperLst`,
    params: data
  });
}
export function evaluateList(data) {
  return service.request({
    method: 'get',
    url: `/goldcourse/web/commentinfo/list`,
    params: data
  });
}

export function addComment(data) {
  return service.request({
    method: 'post',
    url: `/goldcourse/web/commentinfo/save`,
    data
  });
}
export function saveTime(data) {
  return service.request({
    method: 'post',
    url: `/goldcourse/web/studentstudycourserecord/save`,
    data
  });
}


export function listSection(data) {
  return service.request({
    method: 'get',
    url: `/goldcourse/web/onlinelearningresource/listSection`,
    params: data
  });
}

export function getExaminationPaperTopic(data) {
  return service.request({
    method: 'get',
    url: `/goldcourse/web/onlinelearningresourcedetails/getExaminationPaperTopic`,
    params: data
  });
}
export function getTopicInfo(data) {
  return service.request({
    method: 'get',
    url: `/goldcourse/web/onlinelearningresourcedetails/getTopicInfo`,
    params: data
  });
}


