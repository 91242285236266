import service from '@/utils/request.js'

export function numSaveList(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/digitizationtextbookinfo/save',
    data
  });
}
export function numInfo(id) {
  return service.request({
    method: 'get',
    url: `/goldcourse/web/digitizationtextbookinfo/info/${id}`,
  });
}
export function numupdateList(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/digitizationtextbookinfo/update',
    data
  });
}
export function numdelete(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/digitizationtextbookinfo/delete',
    data
  });
}



export function numTeachList(data) {
  return service.request({
    method: 'get',
    url: '/goldcourse/web/digitizationtextbookinfo/list',
    params: data
  });
}






