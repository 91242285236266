import service from '@/utils/request.js'
export function majorList(data) {
  return service.request({
    method: 'get',
    url: '/goldcourse/web/specialtyclassify/list',
    params: data
  });
}
export function StudentList(data) {
  return service.request({
    method: 'get',
    url: '/goldcourse/web/studentcourserelation/list',
    params: data
  });
}
export function CoursewareList(data) {
  return service.request({
    method: 'get',
    url: '/goldcourse/web/gradecoursewareinfo/list',
    params: data
  });
}
export function updateSaveList(data) {
  return service.request({
    method: 'post',
    url: '/goldcourse/web/gradecoursewareinfo/update',
    data
  });
}
export function addMajor(data) {
  return service.request({
    method: 'post',
    url: `/goldcourse/web/specialtyclassify/save`,
    data
  });
}

export function addCourseware(data) {
  return service.request({
    method: 'post',
    url: `/goldcourse/web/gradecoursewareinfo/save`,
    data
  });
}


export function updateMajor(data) {
  return service.request({
    method: 'post',
    url: `/goldcourse/web/specialtyclassify/update`,
    data
  });
}


export function deleteMajor(data) {
  return service.request({
    method: 'post',
    url: `/goldcourse/web/specialtyclassify/delete`,
    data
  });
}


export function deleteCourseware(data) {
  return service.request({
    method: 'post',
    url: `/goldcourse/web/gradecoursewareinfo/delete`,
    data
  });
}

export function deleteStudent(data) {
  return service.request({
    method: 'post',
    url: `/goldcourse/web/studentcourserelation/delete`,
    data
  });
}


export function updateInfo(data) {
  return service.request({
    method: 'post',
    url: `/web/portaluserinfo/update`,
    data
  });
}

export function updatePassword(data) {
  return service.request({
    method: 'post',
    url: `/goldcourse/web/portaluserlogin/updatePassword`,
    data
  });
}
export function info() {
  return service.request({
    method: 'get',
    url: `/web/portaluserinfo/info`,
  });
}

export function materialList(data) {
  return service.request({
    method: 'get',
    url: `/goldcourse/web/onlinelearningresource/list`,
    params: data
  });
}

export function materialSave(data) {
  return service.request({
    method: 'get',
    url: `/goldcourse/web/onlinelearningresource/save`,
    data
  });
}
