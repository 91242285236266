<template>
  <div>课程资源库</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>