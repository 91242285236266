<template>
  <div>
    <div class="main">
      <el-carousel @click.native="linkTo" :interval="5000" arrow="hover" id="banner" height="300px" ref="carousel">
        <el-carousel-item v-for="(item, index) in banners" :key="index">
          <img :src="item.imagsUrl" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import { api } from '../../assets/api/axios'
export default {
  data() {
    return {
      banners: [{ imgUrl: require('../../assets/img/banner.png') }],
    }
  },
  created() {
    this.getBanner()
  },
  methods: {
    linkTo() {
      let activeIndex = this.$refs.carousel.activeIndex
      this.$router.push({
        path: '/onLine',
      })
    },
    formatGetParams(url, data) {
      let params = ``;
      for (let key in data) {
        if (data[key] != null && data[key] != "null" && data[key] != '' && data[key] != 0 && data[key] != 'undefined') {
          if (params.length == 0) {
            params += `?${key}=${data[key]}`;
          } else {
            params += `&${key}=${data[key]}`;
          }
        }
      }
      url += params;
      return url;
    },
    async getBanner() {
      let params = {
        schoolId: localStorage.getItem('schoolId')
      }
      let url = this.formatGetParams('/goldcourse/web/advertisingspace/showAdvertising', params);
      let res = await api.get(
        url
      )
      this.banners = res.data
    },
  },
}
</script>
<style lang="scss" scoped>
#banner {
  overflow-y: hidden;
}

.img {
  width: 100%;
  height: 100%;
}
</style>

