<template>
  <div>
    <div class="banner">
      <img src="@/assets/img/Teabanner.png" alt />
    </div>
    <div class="main">
      <div class="sort">
        <div class="major">高职高专系列：</div>
        <!-- <div class="all">全部</div> -->
        <div class="majorItem">
          <el-row :gutter="20">
            <el-col :span="3" v-for="(item, index) in majorSort1" :key="index">
              <div
                class="grid-content bg-purple item"
                @click="handleTab(item.typeName, item.fid)"
                :class="{ active: active == item.fid }"
              >
                {{ item.typeName }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="sort">
        <div class="major">公共基础系列：</div>
        <!-- <div class="all">全部</div> -->
        <div class="majorItem">
          <el-row :gutter="20">
            <el-col :span="3" v-for="(item, index) in majorSort2" :key="index">
              <div
                class="grid-content bg-purple item"
                @click="handleTab(item.typeName, item.fid)"
                :class="{ active: active == item.fid }"
              >
                {{ item.typeName }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="sort">
        <div class="major">本科系列：</div>
        <!-- <div class="all">全部</div> -->
        <div class="majorItem">
          <el-row :gutter="20">
            <el-col :span="3" v-for="(item, index) in majorSort3" :key="index">
              <div
                class="grid-content bg-purple item"
                @click="handleTab(item.typeName, item.fid)"
                :class="{ active: active == item.fid }"
              >
                {{ item.typeName }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="pageHeader">
        <!-- <div>默认</div>
        <div class="dataTime">
          <span>发布时间</span>
          <i class="el-icon-caret-top iconTop"></i>
          <i class="el-icon-caret-bottom iconBot"></i>
        </div>-->
        <div class="search">
          <input
            type="text"
            placeholder="请输入你要搜索的教材名称"
            v-model="pageBean.teamatName"
          />
        </div>
        <div class="searchBut" @click="getList()">搜索</div>
      </div>
      <div class="item">
        <el-row :gutter="48">
          <el-col
            :span="8"
            v-for="(item, index) in matItem"
            :key="index"
            class="itemMar"
          >
            <div class="grid-content bg-purple">
              <el-row :gutter="20">
                <!-- <router-link class="navRouter" to="/teachingmat/Matdetails" tag="div"> -->
                <el-col :span="10">
                  <div
                    class="grid-content bg-purple"
                    @click="getdetails(item.fid)"
                  >
                    <img :src="item.teamatCoverlogo" alt class="itemImg" />
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="grid-content bg-purple itemInfo">
                    <div class="itemTit">{{ item.teamatName }}</div>
                    <div class="userInfo">作者：{{ item.teamatAuthor }}</div>
                    <div class="userInfo">出版社：{{ item.teamatPress }}</div>
                    <div class="userInfo">定位：{{ item.teamatMoney }}</div>
                    <!-- <div class="textInfo"></div> -->
                  </div>
                </el-col>
                <!-- </router-link> -->
              </el-row>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- <div class="paging">
        <el-pagination background layout="prev, pager, next" :total="1000"></el-pagination>
      </div>-->
      <div class="block">
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :page-size="pageBean.pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
      <!-- <div class="block">
        <el-pagination
          layout="prev, pager, next"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="getFilesList.pageNo"
          :page-size="getFilesList.pageSize"
          :total="100"
        ></el-pagination>
      </div>-->
    </div>
  </div>
</template>
<script>
import { api } from '../../assets/api/axios'
export default {
  data() {
    return {
      majorSort: [],
      majorSort1: [],
      majorSort2: [],
      majorSort3: [],
      matItem: [],
      active: '',
      pageBean: {
        pageNum: 1,
        pageSize: 12,
        typeId: '',
        teamatName: '',
      },
      total: 0,
    }
  },
  created() {
    this.getcategory()
    this.getList()
  },
  methods: {
    async getcategory() {
      let res = await api.post('/front/teamaterial/searchByCategoryForPC')
      // console.log(res)
      this.majorSort = res.data[0]
      console.log(this.majorSort)
      this.majorSort1 = this.majorSort[1]
      this.majorSort2 = this.majorSort[0]
      this.majorSort3 = this.majorSort[2]
    },
    async getList() {
      let res = await api.post(
        '/front/teamaterial/searchByTeamatNameForPc',
        this.pageBean
      )
      console.log(res)
      this.matItem = res.data.list
      this.total = res.data.total

      /**
       * teamatName 教材名称 根据名字筛选 没有传""
       * typeId     类型id  根据类型筛选 没有传""
       * pageNum
       * pageSize
       */
    },
    async getListFid(fid) {
      this.pageBean.typeId = fid
      let res = await api.post(
        '/front/teamaterial/searchByTeamatNameForPc',
        this.pageBean
      )
      console.log(res)
      this.matItem = res.data.list
      this.total = res.data.total
    },
    async getdetails(fid) {
      console.log(fid)

      this.$router.push({
        path: '/teachingmat/Matdetails',
        query: {
          fid: fid,
        },
      })
    },
    handleTab(typeName, fid) {
      this.active = fid
      this.getListFid(fid)
      // this.getOnlineList.ontypeid = id
      // this.getOnline()
    },
    handleTabAll() {
      this.active = '全部'
      this.getOnlineList.ontypeid = ''
      this.getOnline()
    },
    // 监听pagesize的变化
    handleSizeChange(newSize) {},
    handleCurrentChange(newPage) {
      this.pageBean.pageNum = newPage
      this.getList()
    },
  },
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 180px;
  img {
    width: 100%;
    height: 180px;
  }
}
.sort {
  overflow: hidden;
  margin-top: 35px;
  margin-bottom: 25px;
  .major {
    float: left;
    font-size: 14px;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    margin-right: 20px;
    margin-top: 5px;
  }

  .majorItem {
    float: left;
    width: 956px;
    .item {
      height: 23px;
      font-size: 14px;
      color: rgba(102, 102, 102, 1);
      line-height: 23px;
    }
  }
}
.itemMar {
  margin: 30px 0;
}
.active {
  float: left;
  width: 80px;
  height: 23px;
  background: rgba(181, 14, 5, 1);
  border-radius: 2px;
  font-size: 14px;
  color: rgba(255, 255, 255, 1) !important;
  line-height: 23px;
  text-align: center;
  margin-right: 30px;
}
.item {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  // height: 482px;
  .itemImg {
    width: 100%;
    height: 180px;
  }
  .itemInfo {
    width: 100%;
    height: 180px;
    overflow: hidden;
    .itemTit {
      font-size: 18px;
      color: rgba(51, 51, 51, 1);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 18px;
    }
    .userInfo {
      text-align: left;
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
      margin-bottom: 10px;
    }
    .textInfo {
      text-align: left;
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
      line-height: 20px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
  }
}
.pageHeader {
  font-size: 14px;
  font-family: 'Source Han Sans CN';
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  display: float;
  margin-top: 20px;
  overflow: hidden;
  height: 70px;
  line-height: 20px;
  border-top: 1px solid #ccccccff;

  // div {
  //   float: left;
  //   margin-right: 30px;
  // }
  // .signUp {
  //   position: relative;
  // }
  // .iconTop {
  //   position: absolute;
  //   top: 0;
  //   right: -15px;
  //   &:hover {
  //     color: #0b3450;
  //   }
  // }
  // .iconBot {
  //   position: absolute;
  //   bottom: 0;
  //   right: -15px;
  //   &:hover {
  //     color: #0b3450;
  //   }
  // }
  // .dataTime {
  //   position: relative;
  // }
  .search {
    width: 230px;
    height: 34px;
    // margin-left: 755px;
    // margin-right: 20px;
    margin: 20px 20px 0 755px;
    input {
      border: none;
      background: rgba(0, 0, 0, 0.1);
      width: 230px;
      height: 34px;
      padding-left: 10px;
    }
  }
}
.searchBut {
  width: 77px;
  height: 34px;
  background: rgba(181, 14, 5, 1);
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  line-height: 34px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}
</style>
<style lang="scss">
.block {
  margin-top: 30px;
  padding-bottom: 30px;
}
.el-pager {
  font-size: 12px !important;
  color: rgba(102, 102, 102, 1);
  li {
    width: 32px;
    height: 27px;
    border: 1px solid rgba(153, 153, 153, 1);
    margin-right: 15px !important;
    font-weight: 400;
  }
}
.el-pager li.active {
  color: #fff !important;
  background: rgba(181, 14, 5, 1);
  cursor: pointer;
}
.el-pager li.active + li {
  border-left: 1px solid #999;
}
</style>
