<template>
  <div class="main">
    <Banner></Banner>
    <div class="main home_middle clearfix">
      <div class="homeLeft left">
        <div class="itemheader borderCommon">
          <div class="divtitle left">平台简介</div>
        </div>
        <div class="onLine clearfix">
          <div class="onLineRight left">
            <img :src="imgUrl" alt />
          </div>
          <div class="onLineLef right">
            <p class="linetext">
              由梧桐花教育平台推出，致力于为每一个有提升愿望的高校教师提供学习交流的平台，一键管理班级，让学生能够学到高校优质课程。
            </p>
            <ul class="clearfix iconul">
              <li
                class="iconli left"
                v-for="(item, index) in imageArr"
                :key="index"
              >
                <img class="icon" :src="item.img" alt />
                <span class="icontext">{{ item.name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="homeRight right">
        <div class="itemheader borderCommon">
          <div class="divtitle left">精选专业</div>
          <span @click="gotoResource" class="right textcolor"
            >更多专业&gt;&gt;</span
          >
        </div>
        <ul class="selectUl">
          <li
            @click="gotoResource(item)"
            class="majorLi"
            :key="index"
            v-for="(item, index) in majorArr"
          >
            <img src="../../assets/img/lefrArrow.png" alt="" />
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="coursediv">
      <div class="itemheader borderCommon">
        <div class="divtitle left">在线学习</div>
        <span class="right textcolor" @click="moreclass">更多课程&gt;&gt;</span>
      </div>
      <ul class="clearfix courseul">
        <!-- <li class="linecourseli left" :key="index" v-for="(item,index) in couseArr">
          <img class="cover" :src="imgUrl" alt />
          <p class="courseName">教学课程制作</p>
          <p class="teachName">张军教授</p>
        </li>-->
        <Item
          :key="index"
          v-for="(item, index) in couseArr"
          :item="item"
          :index="index"
        ></Item>
      </ul>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/home/banner.vue'
import Item from '@/components/Item/index.vue'
import { getMajor } from '@/api/index.js'
import { queryAll } from '@/api/common/index.js'
import { homeList } from '@/api/home/index.js'
export default {
  components: {
    Banner,
    Item,
  },
  data() {
    return {
      majorArr: [],
      couseArr: [],
      imgUrl: require('../../assets/img/scholl.png'),
      imageArr: [
        {
          name: '建立专业',
          img: require('../../assets/img/setmajor.png'),
        },
        {
          name: '开设班级',
          img: require('../../assets/img/openClass.png'),
        },
        {
          name: '上传课程及资源',
          img: require('../../assets/img/upload.png'),
        },
        {
          name: '组卷考试',
          img: require('../../assets/img/exam.png'),
        },
        {
          name: '数据统计与分析',
          img: require('../../assets/img/datastatic.png'),
        },
        {
          name: '管理平台资源及用户',
          img: require('../../assets/img/plat.png'),
        },
      ],
      params: { specialtyType: 0, objectType: 1 },
    }
  },
  created() {
    this.getmajorList()
    this.getHomeList()
  },
  methods: {
    getHomeList() {
      let param = {
        pageNum: 1,
        pageSize: 5,
        objectType: 2,
        courseType: 1,
      }
      if (localStorage.getItem('schoolId') != null && localStorage.getItem('schoolId') != 0 && localStorage.getItem('schoolId') != "undefined" && localStorage.getItem('schoolId') != "null") {
        param.schoolId = localStorage.getItem('schoolId')
      }
      homeList(param).then((res) => {
        this.couseArr = res.data
      })
    },
    getmajorList() {
      let param = {
        specialtyType: 0,
        objectType: 1,
      }
      queryAll(param).then((res) => {
        this.majorArr = res.data.slice(0, 9)
      })
    },
    gotoResource(row) {
      this.$router.push({
        path: '/resource',
      })
    },
    moreclass() {
      this.$router.push({
        path: '/onLine',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.selectUl {
  height: 141px;
  border-top: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
  margin-top: 20px;
}
.textcolor {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #999999;
  margin-right: 10px;
  cursor: pointer;
}
.homeRight {
  width: 394px;
}
.home_middle {
  margin-top: 30px;
  margin-bottom: 30px;
}
.divtitle {
  width: 86px;
  height: 30px;
  background: #0b3450;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}
.borderCommon {
  border-top: 1px solid #0b3450;
  background: #f8f8f8;
  height: 30px;
  line-height: 30px;
}
.homeLeft {
  width: 666px;
  height: 178px;
}
.teachName {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  margin-top: 11px;
  margin-left: 15px;
}
.courseName {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  margin-top: 30px;
  margin-left: 15px;
}
.cover {
  width: 228px;
  height: 130px;
}
.coursetext {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(47, 47, 57, 1);
  margin-bottom: 31px;
}
.coursediv {
  margin-bottom: 20px;
}
.onLineLef {
  width: 382px;
}
.iconul {
  width: 100%;
}
.icontext {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 25px;
  display: inline-block;
}
.iconli {
  height: 25px;
  margin-bottom: 10px;
  width: 127px;
}
.iconli:last-child {
  width: 140px;
}
.iconli:nth-child(1) {
  width: 106px;
}
.iconli:nth-child(4) {
  width: 106px;
}
.icon {
  width: 21px;
  height: 21px;
  vertical-align: middle;
  margin-right: 6px;
  display: inline-block;
}
.button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  color: #fff;
  width: 140px;
  height: 36px;
  background: linear-gradient(
    45deg,
    rgba(53, 180, 254, 1),
    rgba(15, 135, 254, 1)
  );
  border-radius: 18px;
}
.linetext {
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  margin-top: 19px;
  margin-bottom: 10px;
}
.title {
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: rgba(68, 68, 68, 1);
  margin-bottom: 30px;
}
.majorList {
  margin-bottom: 40px;
}
.majorList h1 {
  margin-top: 30px;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: rgba(68, 68, 68, 1);
}
.majorLi {
  float: left;
  height: 47px;
  line-height: 47px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(52, 52, 52, 1);
  width: 33.33%;
  border-right: 1px solid #ebebeb;
  text-align: center;
  border-bottom: 1px solid #ebebeb;
  box-sizing: border-box;
}

.majorUL {
  margin-bottom: 20px;
  margin-top: 30px;
}

.onLineRight img {
  width: 256px;
  height: 144px;
  margin-top: 18px;
}
</style>
