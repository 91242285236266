<template>
  <div>
    <div class="nav">
      <div class="main">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>首页</el-breadcrumb-item>
          <el-breadcrumb-item>教师认证</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="main">
      <div class="content">
        <div class="conTit">
          <div></div>
          <div>教师认证</div>
        </div>
        <div>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="手机号：" prop="phone">
              <el-input v-model="ruleForm.phone" disabled></el-input>
            </el-form-item>
            <el-form-item label="所属学校:" prop="schName">
              <el-input
                v-model="ruleForm.schName"
                placeholder="请输入所属学校"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属城市:" prop="address">
              <el-cascader
                size="large"
                :options="options"
                v-model="ruleForm.selectedOptions"
                @change="addressChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="姓名：" prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入你的真实姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="性别:" prop="sex">
              <el-select v-model="ruleForm.sex" placeholder="请选择性别">
                <el-option label="男" :value="1"></el-option>
                <el-option label="女" :value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="工号：" prop="jobNum">
              <el-input
                v-model="ruleForm.jobNum"
                placeholder="请输入您的正确工号"
              ></el-input>
            </el-form-item>
            <el-form-item label="班级人数：" prop="classnum">
              <el-input
                v-model="ruleForm.classnum"
                placeholder="请输入您的班级人数"
              ></el-input>
            </el-form-item>

            <!-- <el-form-item label="验证码：" prop="name">
              <el-input v-model="ruleForm.name" placeholder="请输入您的手机号"></el-input>
            </el-form-item>-->
          </el-form>
          <div
            v-dbClick
            class="preserve"
            @click="submitForm('ruleForm')"
            v-show="resStatus == 0"
          >
            保存
          </div>
          <div class="preserve" v-show="resStatus == 1">审核中</div>
          <div class="preserve" v-show="resStatus == 2">已审核</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { regionData, CodeToText } from 'element-china-area-data'
import { mapMutations, mapState, mapGetters } from 'vuex'
import { api } from '../../assets/api/axios'
export default {
  data() {
    return {
      branchList: [
        { typeName: '全部' },
        { typeName: '公共基础系列' },
        { typeName: '高职高专系列' },
        { typeName: '本科系列' },
      ],
      active: '全部',
      ruleForm: {
        phone: '',
        schName: '',
        name: '',
        sex: '',
        schName: '',
        jobNum: '',
        classnum: '',
        selectedOptions: [],
        fid: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' },
        ],
        schName: [
          { required: true, message: '请输入所属学校名称', trigger: 'blur' },
        ],
        address: [{ message: '请选择所属城市', trigger: 'blur' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        jobNum: [{ required: true, message: '请输入工号', trigger: 'blur' }],
        classnum: [
          {
            required: true,
            message: '请输入班级人数',
          },
        ],
      },
      options: regionData,
      // selectedOptions: []
    }
  },
  created() {
    this.ruleForm.phone = this.resPhone
    this.ruleForm.fid = this.resFid
    console.log(this.resFid)
  },
  computed: {
    ...mapGetters(['resPhone', 'resFid', 'resStatus']),
  },
  watch: {
    resPhone: function () {
      console.log('resPhone', this.resPhone)
    },
  },
  methods: {
    handleTab(typeName) {
      this.active = typeName
    },
    handleTabAll() {
      this.active = '全部'
      // console.log(this.active);
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await api.post(
            '/front/personal/teacherVerify',
            this.ruleForm
          )
          this.$message.success('提交成功')
          this.$store.commit('getStatus', res.data.status)
          this.ruleForm = {}
          console.log(res)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    addressChange(arr) {
      console.log(arr)
      console.log(CodeToText[arr[0]], CodeToText[arr[1]], CodeToText[arr[2]])
    },
  },
}
</script>
<style lang="scss" scoped>
.content {
  margin: 0 auto;
  width: 940px;
  height: 1068px;
  background: rgba(255, 255, 255, 1);
  padding: 30px 80px 0 80px;
  .conTit {
    overflow: hidden;
    border-bottom: 1px solid #e6e6e6ff;
    padding-bottom: 10px;
    :nth-child(1) {
      width: 4px;
      height: 20px;
      background: rgba(181, 14, 5, 1);
      float: left;
    }
    :nth-child(2) {
      font-size: 20px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      float: left;
      margin-left: 8px;
    }
  }
  .phonesend {
    width: 100px;
    height: 37px;
    background: rgba(252, 252, 252, 1);
    border-left: 1px solid rgba(230, 230, 230, 1);
    font-size: 14px;
    color: rgba(102, 102, 102, 1);
    text-align: center;
    line-height: 38px;
    position: absolute;
    top: 3px;
    right: 355px;
  }
  .preserve {
    width: 100px;
    height: 36px;
    background: rgba(181, 14, 5, 1);
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    line-height: 36px;
    margin-top: 50px;
  }
}
</style>
<style lang="scss" >
.content {
  .el-form-item {
    margin-top: 20px;
  }
  .el-form-item__content {
    margin-left: 0 !important;
    font-size: 16px;
    // color: rgba(181, 14, 5, 1);
  }
  .el-input__inner {
    border-radius: 0;
    width: 486px;
    height: 40px;
    background: rgba(252, 252, 252, 1);
  }
  .el-input {
    width: 486px;
    height: 40px;
  }
}
</style>
