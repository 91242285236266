export function findName(arr, id) {
  let obj = arr.find(function (obj) {
    if (obj.id == id) {
      return obj
    }
  })
  console.log(obj)
  return obj.name;
}
const multiInitArray = (list) => {
  var newList = [];
  list.forEach(element => {
    newList.push({
      key: element.key,
      checked: false,
      isRight: false
    })
  })
  return newList;
}

export function toSize(limit) {
  var size = "";
  if (limit < 0.1 * 1024) {                            //小于0.1KB，则转化成B
    size = limit.toFixed(2) + "B"
  } else if (limit < 0.1 * 1024 * 1024) {            //小于0.1MB，则转化成KB
    size = (limit / 1024).toFixed(2) + "KB"
  } else if (limit < 0.1 * 1024 * 1024 * 1024) {        //小于0.1GB，则转化成MB
    size = (limit / (1024 * 1024)).toFixed(2) + "MB"
  } else {                                            //其他转化成GB
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB"
  }

  var sizeStr = size + "";                        //转成字符串
  var index = sizeStr.indexOf(".");                    //获取小数点处的索引
  var dou = sizeStr.substr(index + 1, 2)            //获取小数点后两位的值
  if (dou == "00") {                                //判断后两位是否为00，如果是则删除00                
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
  }
  return size;
}


export const downloadByUrl = (url, fileName, cb) => {
  const eleLink = document.createElement("a");
  eleLink.style.display = "none";
  eleLink.setAttribute("download", fileName);
  eleLink.setAttribute("href", url);
  document.body.appendChild(eleLink);
  eleLink.click();
  document.body.removeChild(eleLink);
  cb && cb();
};