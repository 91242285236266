import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './plugins/element'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/main.scss'
import './assets/css/home.scss'
import VideoPlayer from 'vue-video-player'
import './assets/iconfont/iconfont.css';
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
import { baseUrl } from '@/config'
axios.defaults.baseURL = baseUrl

Vue.directive('dbClick', {
    inserted(el, binding) {
        el.addEventListener('click', e => {
            if (!el.disabled) {
                el.disabled = true
                el.style.cursor = 'not-allowed'
                setTimeout(() => {
                    el.style.cursor = 'pointer'
                    el.disabled = false
                }, 1500)
            }
        })
    }
})
Vue.prototype.$http = axios
// 配置请求的根路径
axios.defaults.baseURL = baseUrl
axios.interceptors.request.use(config => {
    config.headers.Authorization = window.localStorage.getItem('token')
    return config
})
Vue.use(VideoPlayer)
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
